import React, {useState, useEffect} from 'react'
import {Form} from 'react-bootstrap';

export default function PatientName({Val, setVal, setSt, label}) {

    const [Class, setClass] = useState('formControl nameInput controlNeutral')

    useEffect(() => {
        if(Val.length === 0){
            setClass('formControl nameInput controlNeutral')
        }else{
            setSt(1);
            setClass('formControl controlValid nameInput');
        }
    },[Val, Class]);

    return (
        <Form onSubmit={e => { e.preventDefault(); }}>
            <Form.Group className='inputDiv patientNameDiv'>
                    <span className='controlLabel'>{(label)?label:"Patient:"}&nbsp;&nbsp;</span>
                    <Form.Control 
                        type="text" placeholder='Enter patient name / ID'
                        className={Class} value={Val}
                        onChange={
                            (e) => 
                                {
                                setVal(e.target.value);
                            }
                        }
                    />
            </Form.Group>
        </Form>
    )
}
