import React from 'react'
import { useState, useEffect } from 'react';

import {Row, Table} from 'react-bootstrap';

import { Measurement } from '../common/Measurement';
import { getSICAStats } from '../services/ToricCalculation';

export default function SICAStatsTable({results}) {
    
    const [filterBy, setFilter] = useState("lens")
    const [tableHead, setTableHead] = useState();
    const [tableBody, setTableBody] = useState();
    const lenses = ['All', 'Standard', 'Standard Piggyback', 'STAAR Toric ICL', 'IPCL Toric', 'Artiflex Toric', 'Eyecryl Phakic IOL']
    const incisions = ['All', 'Superior', 'Temporal', 'Other']

    useEffect(() => {
        
        let rows = [];

            if(filterBy === "lens"){
                //Render table head
                setTableHead(
                    <tr>
                        <th>Lens</th>
                        <th>Minimum SIA</th>
                        <th>Average SIA</th>
                        <th>Maximum SIA</th>
                    </tr>
                )

                //Render table body
                for(let i = 0 ; i < lenses.length ; i++){
                    
                    let selectedLens = lenses[i];
                    let lensResults = results;
                    
                    if(selectedLens !== "All"){
                        if(selectedLens === 'Standard'){
                            lensResults = lensResults.filter(result => result.iolType === 'Standard');
                        }else{
                            lensResults = lensResults.filter(result => result.iolModel === selectedLens);
                        }
                    }
        
                    let resultSIAs = [];
                    for(let j = 0 ; j < lensResults.length ; j++){
                        let resultSIA = lensResults[j].sia;
                        resultSIAs[j] = new Measurement(resultSIA[0], resultSIA[1]);
                    }
                    let userStats = getSICAStats(resultSIAs);
                    
                    if(i === 0){
                        rows[0] = (
                            <tr>
                                <td>{selectedLens}</td>
                                <td>{userStats[2].magnitude.toFixed(2) + " @ " + Math.round(userStats[2].axis) + "°"}</td>
                                <td>{userStats[0].magnitude.toFixed(2) + " @ " + Math.round(userStats[0].axis) + "°"}</td>
                                <td>{userStats[1].magnitude.toFixed(2) + " @ " + Math.round(userStats[1].axis) + "°"}</td>
                            </tr>
                            )
                    }else{
                        try{
                            rows[i] = ( 
                                <tr>
                                    <td>{selectedLens}</td>
                                    <td>{userStats[2].magnitude.toFixed(2) + " @ " + Math.round(userStats[2].axis) + "°"}</td>
                                    <td>{userStats[0].magnitude.toFixed(2) + " @ " + Math.round(userStats[0].axis) + "°"}</td>
                                    <td>{userStats[1].magnitude.toFixed(2) + " @ " + Math.round(userStats[1].axis) + "°"}</td>
                                </tr>
                                )
                        }catch(error){
                            rows[i] = ( 
                                <tr>
                                    <td>{selectedLens}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                                )
                        }
                        
                    }
            }
            }else if(filterBy === 'incision'){

                    //Render table head
                    setTableHead(
                        <tr>
                            <th>Incision</th>
                            <th>Minimum SIA</th>
                            <th>Average SIA</th>
                            <th>Maximum SIA</th>
                        </tr>
                    )

                    //Render table body
                    for(let i = 0 ; i < incisions.length ; i++){
                        
                        let selectedIncision = incisions[i];
                        let incisionResults = results.filter(result => result.incision !== null);
                        
                        if(selectedIncision !== "All"){
                            incisionResults = incisionResults.filter(result => result.incision[1] === selectedIncision);
                        }
            
                        let resultSIAs = [];
                        for(let j = 0 ; j < incisionResults.length ; j++){
                            let resultSIA = incisionResults[j].sia;
                            resultSIAs[j] = new Measurement(resultSIA[0], resultSIA[1]);
                        }
                        let userStats = getSICAStats(resultSIAs);
                        
                        if(i === 0){
                            try{
                            rows[0] = (
                                <tr>
                                    <td>{selectedIncision}</td>
                                    <td>{userStats[2].magnitude.toFixed(2) + " @ " + Math.round(userStats[2].axis) + "°"}</td>
                                    <td>{userStats[0].magnitude.toFixed(2) + " @ " + Math.round(userStats[0].axis) + "°"}</td>
                                    <td>{userStats[1].magnitude.toFixed(2) + " @ " + Math.round(userStats[1].axis) + "°"}</td>
                                </tr>
                                )
                            }catch{
                                rows[i] = ( 
                                    <tr>
                                        <td>{selectedIncision}</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                )
                            }
                        }else{
                            try{
                                rows[i] = ( 
                                    <tr>
                                        <td>{selectedIncision}</td>
                                        <td>{userStats[2].magnitude.toFixed(2) + " @ " + Math.round(userStats[2].axis) + "°"}</td>
                                        <td>{userStats[0].magnitude.toFixed(2) + " @ " + Math.round(userStats[0].axis) + "°"}</td>
                                        <td>{userStats[1].magnitude.toFixed(2) + " @ " + Math.round(userStats[1].axis) + "°"}</td>
                                    </tr>
                                    )
                            }catch{
                                rows[i] = ( 
                                    <tr>
                                        <td>{selectedIncision}</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                    )
                            }
                            
                        }
            }
        }
        setTableBody(rows);
    }, [results, filterBy])

    return (
    <div className='userTableContainer'>
        <Row className='tableFilter'>
            <div>Select Filter:&nbsp;&nbsp;</div>
            <div 
            className={(filterBy === "lens")?'tableFilterOption selected':'tableFilterOption'}
            onClick={() => setFilter("lens")}>
                <span>&nbsp;• Lens&nbsp;</span>
            </div>
            <div className={(filterBy === "incision")?'tableFilterOption selected':'tableFilterOption'}
            onClick={() => setFilter("incision")}>
                <span>&nbsp;• Incision&nbsp;</span>
            </div>
            </Row>
        <Row className='tableFixed'>
            <Table striped hover className='userResultsTable statsTable'>
                <thead className='tableHead'>
                    {tableHead}
                </thead>
                <tbody>
                    {tableBody}
                </tbody>
            </Table>
        </Row>
    </div>
  )
}
