import React, { useState, useEffect } from 'react'
import BarHeader from './common/BarHeader'
import Input from './sia_calculator/Input'
import './sia_calculator/sia-calculator.css'
import SIAResult from './sia_calculator/SIAResult';

export default function SIACalculator() {

    const [showResult , setShowResult] = useState(false);

    //Loaded inputs array
    const [SIACalculations, setSIACalculations] = useState([]);

    // // States for Form // //
    // PATIENT DATA //
    //Patient Name
    const [F11VS, setF11VS] = useState(["",-1]);
    //Age
    const [F12VS, setF12VS] = useState(["",-1]);
    //Gender
    const [F13VS, setF13VS] = useState(["",-1]);
    //Implanted IOL
    const [F14VS, setF14VS] = useState(["",-1]);

    //PREOPERATIVE OCT / Scheimpflug / Biometer
        //Measure 1
          //Magnitude
          const[F21VS, setF21VS] = useState(["",-1])
          //Axis
          const[F22VS, setF22VS] = useState(["",-1])
        //Measure 2
          //Magnitude
          const[F23VS, setF23VS] = useState(["",-1])
          //Axis
          const[F24VS, setF24VS] = useState(["",-1])
        //Measure 3
          //Magnitude
          const[F25VS, setF25VS] = useState(["",-1])
          //Axis
          const[F26VS, setF26VS] = useState(["",-1])
        /*Averages
          //Magnitude
          const[F27Val, setF27Val] = useState("0")
          //Axis
          const[F28Val, setF28Val] = useState("0")
        */

    //POSTOPERATIVE OCT / Scheimpflug / Biometer
        //Measure 1
          //Magnitude
          const[F31VS, setF31VS] = useState(["",-1])
          //Axis
          const[F32VS, setF32VS] = useState(["",-1])
        //Measure 2
          //Magnitude
          const[F33VS, setF33VS] = useState(["",-1])
          //Axis
          const[F34VS, setF34VS] = useState(["",-1])
        //Measure 3
          //Magnitude
          const[F35VS, setF35VS] = useState(["",-1])
          //Axis
          const[F36VS, setF36VS] = useState(["",-1])

    //INCISION
      //Size
      const [F41VS, setF41VS] = useState(["",-1]);
      //Location
      const [F42VS, setF42VS] = useState(["",-1]);
      //Axis
      const [F43VS, setF43VS] = useState(["",-1]);


  // LOADING INPUT OF A SAVED RESULT //
  useEffect(() => {
    setTimeout(() => {
      let calculationObject = localStorage.getItem('calculationObject');
      if (calculationObject){
          calculationObject = JSON.parse(calculationObject)

          //LOAD FORM
          setF11VS([calculationObject.patient, 1]);
          setF12VS([calculationObject.age, 1]);
          setF13VS([calculationObject.gender, 1]);
          setF14VS([calculationObject.IOL, 1]);

          setF21VS([calculationObject.preopTCA[0], 1]);
          setF22VS([calculationObject.preopTCA[1], 1]);
          setF23VS([calculationObject.preopTCA[2], 1]);
          setF24VS([calculationObject.preopTCA[3], 1]);
          setF25VS([calculationObject.preopTCA[4], 1]);
          setF26VS([calculationObject.preopTCA[5], 1]);

          setF31VS([calculationObject.postopTCA[0], 1]);
          setF32VS([calculationObject.postopTCA[1], 1]);
          setF33VS([calculationObject.postopTCA[2], 1]);
          setF34VS([calculationObject.postopTCA[3], 1]);
          setF35VS([calculationObject.postopTCA[4], 1]);
          setF36VS([calculationObject.postopTCA[5], 1]);

          setF41VS([calculationObject.incSize, 1]);
          setF42VS([calculationObject.incLocation, 1]);
          setTimeout(() => {
            setF43VS([calculationObject.incAxis, 1]);
          }, 100);

          console.log("Saved Result loaded.")
      }
    }, 100);
    setTimeout(() => {
      localStorage.removeItem('calculationObject') 
    }, 1000);
  },[])

    return (
      <div>
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');
          </style>
          
          <BarHeader currentCalculator={"SIA Calculator"}/>
          <Input
            setShowResult={setShowResult}
            SIACalculations={SIACalculations} setSIACalculations={setSIACalculations}

            F11VS={F11VS} setF11VS={setF11VS}
            F12VS={F12VS} setF12VS={setF12VS}
            F13VS={F13VS} setF13VS={setF13VS}
            F14VS={F14VS} setF14VS={setF14VS}

            F21VS={F21VS} setF21VS={setF21VS}
            F22VS={F22VS} setF22VS={setF22VS}
            F23VS={F23VS} setF23VS={setF23VS}
            F24VS={F24VS} setF24VS={setF24VS}
            F25VS={F25VS} setF25VS={setF25VS}
            F26VS={F26VS} setF26VS={setF26VS}

            F31VS={F31VS} setF31VS={setF31VS}
            F32VS={F32VS} setF32VS={setF32VS}
            F33VS={F33VS} setF33VS={setF33VS}
            F34VS={F34VS} setF34VS={setF34VS}
            F35VS={F35VS} setF35VS={setF35VS}
            F36VS={F36VS} setF36VS={setF36VS}

            F41VS={F41VS} setF41VS={setF41VS}
            F42VS={F42VS} setF42VS={setF42VS}
            F43VS={F43VS} setF43VS={setF43VS}
          />

          <SIAResult
            showResult={showResult} setShowResult={setShowResult}
            SIACalculations={SIACalculations} patientName={F11VS[0]}
          />
      </div>
    )
}
