import React, {useState, useContext, useEffect} from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import './barheader.css'

import { UserContext } from '../UserContext'

import {FaUserMd} from 'react-icons/fa';
import {IoCalculator} from 'react-icons/io5'
import {RiInformationLine} from 'react-icons/ri'
import {TbVectorOff, TbArrowBigUpLines} from 'react-icons/tb'
import StandardAlignerIcon from '../img/standardaligner-icon.png';
import PhakicAlignerIcon from '../img/phakicaligner-icon.png';
import Tooltip from './Tooltip';
import EmptyIcon from '../img/empty-icon.png'
import logo1 from '../../src/img/logo1.png'

import { getViewport } from '../services/StyleUtilites';

export default function BarHeader({currentCalculator}) {

    const username = useContext(UserContext)[1];
        
    const [screenSize, setScreenSize] = useState(getViewport());
    window.onresize = function(event){
        setScreenSize(getViewport())
        console.log('resize')
    }

    const [showLoginMsg, setShowLoginMsg] = useState(false);

    const [BarheaderAux, setBarheaderAux] = useState(null)

    useEffect(() => {
        document.getElementById("xs-tooltip").style.display = "none"
        setTimeout( () => {
            var path = window.location.pathname;
            if(username==="" && (path === '/standard' || path === '/piggyback' || path === '/SIA')){
                if(screenSize[0] >= 576){
                    document.getElementById("tooltip").className = "tooltip-box fadeIn";
                }else{
                    document.getElementById("xs-tooltip").style.display = "block"
                    document.getElementById("xs-tooltip").className = "tooltip-box fadeIn";
                }
            }
        },8000)
    },[]);

    function renderBarheaderPopup(type){
        if(BarheaderAux !== null){
            setBarheaderAux(null)
        }else{
            if(type === 1){
                //Links Popup
                setBarheaderAux(
                    <div id='bar-aux-div'>
                        <div id='bar-aux-content textCenter'>
                            <a href='/standard' className='barLink'>
                                <img src={StandardAlignerIcon} alt='standard aligner icon'
                                className='barIcon2'></img>
                                Standard Toric IOL
                            </a>
                            <a href='/piggyback' className='barLink'>
                                <img src={PhakicAlignerIcon} alt='phakic aligner icon'
                                className='barIcon2'></img>
                                Phakic/Piggyback Aligner
                            </a>
                            <a href='/SIA' className='barLink'>
                                <TbVectorOff className='barIcon2'/>
                                SIA Calculator
                            </a>
                            <a href='https://ioloptimization.com/' className='barLink'>
                                <TbArrowBigUpLines className='barIcon2'/>
                                Constant Optimizator
                            </a>
                            <a href='/about'className='barLink'><RiInformationLine className='barIcon2'/>About</a>
                        </div>
                    </div>
                )
            }else if (type === 2){
                setBarheaderAux(
                    //Login / Register
                    <div id='bar-aux-div'>
                        <div id='bar-aux-content textCenter'>

                            <div style={{display: (username === "")? null : 'none'}}>
                                <a href='/register' className='registerLink barLink'>
                                    <FaUserMd/>&nbsp;Register
                                </a>
                                <a href='/login' className='loginLink barLink'>
                                    Log In
                                </a>
                            </div>

                            <div style={{display: (username !== "")? null : 'none'}}>
                                <div onClick={() => window.location.replace("/user")}>
                                    <span className='barheaderUser'>
                                        <FaUserMd style={{fontSize: '5vw'}}/>
                                        &nbsp;@{(username==="")?null:username.slice(0,22)}
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }
        }
    }

    return (
        <div className='barHeader'>
            <Container className='barContainer'>
                <Row className='barRow'>

                    <Col xs={3} sm={1} className='vCenter lightShadow' id='bar-icon-col'>
                        <img src={logo1} className='barIcon' alt='logo'></img>
                    </Col>
                    <Col xs={6} sm={2} className='vCenter barSecondCol noPadding' id='bar-current-calculator'>
                        <span className='lightShadow'>
                            <img 
                            src={(currentCalculator==="Standard Toric IOL" || currentCalculator==="SIA Calculator")?StandardAlignerIcon
                            :(currentCalculator==="Phakic/Piggyback Aligner")?PhakicAlignerIcon
                            :EmptyIcon} 
                            id='bar-current-calculator-icon'
                            alt='calculator icon'
                            className='barIcon3'></img>
                            {currentCalculator}
                        </span>
                    </Col>


                    <Col xs={7} className='linksCol noPadding d-none d-sm-flex'>
                        <div className='links'>
                            {/*<span className='barLink'><FaHistory/>&nbsp;My History</span>
                            <a href='/about'className='barLink'><RiInformationLine/>&nbsp;About Us</a>
                            <span className='barLink'><FaRegFlag/>&nbsp;Report error</span>*/}
                            <a href='/standard' className='barLink'>
                                <img src={StandardAlignerIcon} alt='standard aligner icon'
                                className='barIcon2'></img>
                                Standard Toric IOL
                            </a>
                            <a href='/piggyback' className='barLink'>
                                <img src={PhakicAlignerIcon} alt='phakic aligner icon'
                                className='barIcon2'></img>
                                Phakic/Piggyback Aligner
                            </a>
                            <a href='/SIA' className='barLink'>
                                <TbVectorOff className='barIcon2'/>
                                SIA Calculator
                            </a>
                            <a href='https://ioloptimization.com/' className='barLink'>
                                <TbArrowBigUpLines className='barIcon2'/>
                                Constant Optimizator
                            </a>
                            <a href='/about'className='barLink'><RiInformationLine className='barIcon2'/>About</a>
                        </div>
                    </Col>


                    <Col xs={2} className='userCol'
                    style={{display: (username!=="" || screenSize[0] < 576)? 'none' : null, position: 'relative'}}>
                        <div className='links'>
                            <a href='/register' className='registerLink barLink'>
                                <FaUserMd/>&nbsp;Register
                            </a>
                            <a href='/login' className='loginLink barLink'>
                                Log In
                            </a>
                        </div>
                        <Tooltip
                        id={'tooltip'}
                        show={showLoginMsg}
                        message={
                        <span>
                            <b>Log in</b> or <b>register</b> to anonymously save your cases, 
                            so you dont have to enter them every time, and also to have your 
                            SIA record.<br></br> 
                            No patient data is stored on the site, only measurements.
                        </span>
                        }/>
                    </Col>
                    <Col xs={2} className='userCol'
                    style={{display: (username==="" || screenSize[0] < 576)? 'none' : null}}>
                        <div className='links'
                        onClick={() => window.location.replace("/user")}>
                            <span className='barheaderUser'>
                                <FaUserMd style={{fontSize: '1.8vw'}}/>
                                &nbsp;&nbsp;@{(username==="")?null:username.slice(0,22)}
                            </span>
                        </div>
                    </Col>


                    <Col className='d-sm-none barButtonRow' xs={3}>
                            <IoCalculator className='barButton'
                            onClick={() => renderBarheaderPopup(1)}/>
                            <FaUserMd className='barButton'
                            onClick={() => renderBarheaderPopup(2)}
                            />
                            <Tooltip
                            id={'xs-tooltip'} 
                            show={showLoginMsg}
                            message={
                            <span id='xs-tooltip-msg'>
                                 Remember to <b>log in</b> if you want to save results!
                            </span>
                            }/>
                    </Col>
                </Row>
            </Container>
            {BarheaderAux}
        </div>
    )
}
