import axios from 'axios';
import {server_url} from '../Settings'
axios.defaults.withCredentials = true;

const fetchOptions = {
    withCredentials: true,
}

export async function Authenticate() {
    const RestrictedPaths = ['/user'];
    var currentPath = window.location.pathname;
  
    return new Promise((resolve, reject) => {
      axios.post(server_url + "/users/validateJWT", fetchOptions)
        .then(resp => {
          var result = resp.data;
          if (result) {
            axios.get(server_url + "/users/getJWTInfo", fetchOptions)
              .then(resp => {
                let jwtInfo = resp.data.split(",");
                let userID = jwtInfo[0];
                let userEmail = jwtInfo[1].split("@");
                let userName = userEmail[0];
                let userInfo = [userID, userName];
                resolve(userInfo);
              })
              .catch(error => {
                reject(error);
              });
          } else {
            if (RestrictedPaths.includes(currentPath)) {
              window.location.replace("/login");
            }
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}
  

export async function logOut(){
    axios.get(server_url + "/users/logout", fetchOptions);
}