export const types = [
    {
        name: 'Select lens',
        image: 'questionmark',
        manufacturer: 'Select',
        type: '-1',
        degrees: 0,
    },
    {
        name: 'Standard Piggyback IOL',
        image: 'Standard',
        manufacturer: 'Piggyback',
        type: '1',
        degrees: 0,
    },
    {
        name: 'STAAR Toric ICL',
        image: 'STAAR',
        manufacturer: 'Staar',
        type: '2',
        degrees: 0,
    },
    {
        name: 'IPCL Toric',
        image: 'STAAR',
        manufacturer: 'IPCL',
        type: '3',
        degrees: 0,
    },
    {
        name: 'Artiflex Toric 0°',
        image: 'Artiflex',
        manufacturer: 'Artiflex',
        type: '4',
        degrees: 0,
    },
    {
        name: 'Artiflex Toric 90°',
        image: 'Artiflex',
        manufacturer: 'Artiflex',
        type: '1',
        degrees: 0,
    },
    {
        name: 'Eyecryl Phakic IOL 0°',
        image: 'STAAR0',
        manufacturer: 'Eyecril',
        type: '1',
        degrees: 0,
    },
    {
        name: 'Eyecryl Phakic IOL 30°',
        image: 'STAAR30',
        manufacturer: 'Eyecril',
        type: '1',
        degrees: 30,
    },
    {
        name: 'Eyecryl Phakic IOL 60°',
        image: 'STAAR60',
        manufacturer: 'Eyecril',
        type: '1',
        degrees: 60,
    },
    {
        name: 'Eyecryl Phakic IOL 90°',
        image: 'STAAR90',
        manufacturer: 'Eyecril',
        type: '1',
        degrees: 90,
    },
    {
        name: 'Eyecryl Phakic IOL 120°',
        image: 'STAAR120',
        manufacturer: 'Eyecril',
        type: '1',
        degrees: 120,
    },
    {
        name: 'Eyecryl Phakic IOL 150°',
        image: 'STAAR150',
        manufacturer: 'Eyecril',
        type: '1',
        degrees: 150,
    },
];
