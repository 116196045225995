import React, { useState } from 'react'
import Popup from './common/Popup'

import {FaUserPlus} from 'react-icons/fa'
import {BiErrorCircle} from 'react-icons/bi'

import { activateUser } from './services/userServices'

export default function NewUserValidation() {

    //PopUp states
    const [showPopUp, setShowPopUp] = useState(true);

    //Stages: Loading, Activated, Error
    const[stage, setStage] = useState("Loading");
    
    const queryParams = new URLSearchParams(window.location.search);
    const activationToken = queryParams.get('token');
    const userId = queryParams.get('id');
    async function handleActivation(token, id){
        const activationResult = await activateUser(token, id);
        if(activationResult){
            setStage("Activated");
        }else{
            setStage("Error")
        }
    }
    handleActivation(activationToken, userId);

    if(stage === "Loading"){
        return (
            <div>
            <Popup 
            showPopUp={showPopUp}
            onClick={() => {
                setShowPopUp(false);
                window.location.href = "localhost:3000";
            }}
            Title={"Loading..."}
            Body={
                <span> 
                    Waiting for server to validate your link.
                </span>}
            />
            </div>
        )
    }
    else if(stage === "Activated"){
        return (
            <div>
            <Popup 
            showPopUp={showPopUp}
            onClick={() => {
                setShowPopUp(false);
                window.location.href = "/login";
            }}
            Icon={<FaUserPlus style={{marginBottom: '0.65vh'}}/>}
            Title={"Register complete!"}
            Body={
                <span> 
                    Your account has been activated successfully!
                </span>}
            />
            </div>
        )
    }else if(stage === "Error"){
        return(
            <div>
            <Popup 
            showPopUp={showPopUp}
            onClick={() => {
                setShowPopUp(false);
                window.location.href = "localhost:3000/standard";
            }}
            Icon={<BiErrorCircle style={{marginBottom: '0.65vh'}}/>}
            Title={"Error!"}
            Body={
                <span> 
                    Activation link is not valid.
                </span>}
            />
            </div>
        )
    }
}
