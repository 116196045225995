import React from 'react'
import './popup.css'

export default function Popup({showPopUp, setShowPopUp, Icon, Title, Body, onClick}) {

    if(showPopUp){
        return(
            <div className='popup'>
                <div className='overlay2'></div>
                
                <div className='popupContent styledBox'>
                    <div className='popupTitle'>
                        <span style={{display: (Icon === undefined)? 'none' : null}}>
                            {Icon}&nbsp;</span>
                            {Title}
                        </div>
                    <div className='popupMessage'>{Body}</div>
                    <div className='popupBtn'
                    onClick={onClick}>
                        Accept
                    </div>
                </div>
            </div>
        )
    }

}
