export class StandardCalculation{
    constructor(patient, surgeon, eye, AXL, k1, k2, steepMeridian, tca1, tca2,
        IOLMfact, IOLModel, IOLCyl, PRSph, PRCyl, PRAxis, tca3, tca4) {
            this.patient = patient;
            this.surgeon = surgeon;
            this.eye = eye;
            this.AXL = AXL;
            this.k1 = k1;
            this.k2 = k2;
            this.steepMeridian = steepMeridian;
            this.tca1 = tca1;
            this.tca2 = tca2;
            this.IOLMfact = IOLMfact;
            this.IOLModel = IOLModel;
            this.IOLCyl = IOLCyl;
            this.PRSph = PRSph;
            this.PRCyl = PRCyl;
            this.PRAxis = PRAxis;
            this.tca3 = tca3;
            this.tca4 = tca4;
      }
}