import { useState, useContext, useEffect } from 'react'
import { UserContext } from './UserContext'
import { Col, Row } from 'react-bootstrap'
import './userPage.css'
import BarHeader from './common/BarHeader'

import { FaUserMd } from 'react-icons/fa';
import { AiOutlineSave } from 'react-icons/ai';
import { GoGraph } from 'react-icons/go'
import { IoIosLogOut } from 'react-icons/io'

import { logOut } from './services/Authentication';
import { deleteResult, getResults } from './services/UserResults';

import ResultsTable from './user_results/ResultsTable';
import StandardResult from './aligner_standard/Result';
import PiggyBackResult from './aligner_piggyback/Result';
import OptionsPopup from './common/OptionsPopup';

import { VscOpenPreview, VscTrash } from 'react-icons/vsc'
import SIAResult from './sia_calculator/SIAResult';
import SICAStatsTable from './user_results/SICAStatsTable'

export default function UserPage() {

    const [option, setOption] = useState(1);
    const [content, setContent] = useState(<div></div>);
    const [showStandardResult, setShowStandardResult] = useState(false);
    const [showPiggybackResult, setShowPiggybackResult] = useState(false);
    const [showSIAResult, setShowSIAResult] = useState(false);
    const [deleteID, setDeleteID] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [fixedResult, setFixedResult] = useState([{}])

    const userData = useContext(UserContext);
    const [resultEntries, setResultEntries] = useState([{"tca": [0,0] , "sia": [0,0], "incision": [0]}]);
    useEffect(() =>{
        if(!Number.isNaN(userData[0])){
            async function loadResults(){
                let results = await getResults(parseInt(userData[0]));
                results = Object.values(results);
                setResultEntries(results);
            }
            loadResults();
        }
    }, [userData])

    function logOutUser(){
        logOut();
        window.location.replace("/login")
    }

    async function requestDeleteResult(resultID){
        let response = await deleteResult(resultID)
        if(response){
            window.location.reload();
        }else{
            window.alert("We are sorry, there was an error deleting your result!");
        }
    }

    useEffect(() => {
      if(option === 1){
        setContent(
            <div className='userTableContainer'>
                <ResultsTable
                resultEntries={resultEntries}
                setShowStandardResult={setShowStandardResult}
                setShowPiggybackResult={setShowPiggybackResult}
                setShowSIAResult={setShowSIAResult}
                setShowDeletePopup={setShowDeletePopup}
                setDeleteID={setDeleteID}
                setFixedResult={setFixedResult}
                />
            </div>
        )
      }else if(option === 2){
        setContent(<SICAStatsTable results={resultEntries}/>)
      }
    }, [option, resultEntries])
    

    return (
    <div className='userPage'>

        <style>
        @import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');
        </style>

        <BarHeader/>
        <div className='userPageContent vCenter'>
            <div className='styledBox userContainer'>
                <Row className='userHeader'>
                    <Col xs={12} sm className='userTitle'>
                        <FaUserMd className='userTitleIcon'/>
                        Welcome again @{userData[1]}!&nbsp;
                    </Col>
                    <Col xs={12} sm className='userResultsOptions'>
                        <div>
                            <span 
                            className={(option===1)?'userOption selected':'userOption'} id='option-1'
                            onClick={() => setOption(1)}>
                                <AiOutlineSave className='resultsIcon'/> Saved Results
                            </span>
                        </div>
                        <div>
                            <span 
                            className={(option===2)?'userOption selected':'userOption'} id='option-2'
                            onClick={() => setOption(2)}>
                                <GoGraph className='resultsIcon'/> SIA Stats
                            </span>
                        </div>
                        <div>
                            <span 
                            className={(option===3)?'userOption selected':'userOption'} id='option-3'
                            onClick={() => {
                                setOption(3);
                                logOutUser();}}>
                                <IoIosLogOut className='resultsIcon'/> Log Out
                            </span>
                        </div>
                    </Col>
                </Row>
                <div className='separator'></div>
                <div className='userResults'>
                    {content}
                </div>
            </div>
        </div>

        <StandardResult 
        showResult={showStandardResult} setShowResult={setShowStandardResult}
        fixedResult={fixedResult}
        />
        <PiggyBackResult
        showResult={showPiggybackResult} setShowResult={setShowPiggybackResult}
        fixedResult={fixedResult}
        />
        <SIAResult
        showResult={showSIAResult} setShowResult={setShowSIAResult}
        fixedResult={fixedResult} SIACalculations={[]}
        />
        <OptionsPopup 
        Title={"Delete Result"}
        Icon={<VscTrash/>}
        OnAccept={() => {
            requestDeleteResult(deleteID);
        }}
        Message={"Are you sure you want to delete this result?"}
        showPopUp={showDeletePopup}
        setShowPopup={setShowDeletePopup}/>
    </div>
    )
}
