import React, {useState, useEffect} from 'react'
import {Container, Col, Row} from 'react-bootstrap';
import {FaRegQuestionCircle} from 'react-icons/fa'

import Lenses from './forms/inputs/Lenses';
import { types } from '../helpers/enums/LensModels';
import './lensSelector.css'

import hint4 from '../img/hint4.png';
import hint5 from '../img/hint5.png';

export default function LensSelector({
    setStage,
    F11Val, setF11Val, setF11St,
    setF12VS, setLensType,
    setIOLMarks,
}) {
    
    const [options, setOptions] = useState([{name:'', manufacturer:['',''], image:'questionmark'}]);
    useEffect(() => {
        const recognizeOptions = () => {
            let tempVal = [];
            tempVal = types.filter((type) => type.manufacturer === F11Val);
            setOptions(tempVal);
        };
        recognizeOptions();

        setTimeout( () => {
            //If Standard, ICL or IPCL option is selected, automatically choose lens
            if(F11Val==='Staar'){
                selectLens("STAAR Toric ICL", "2", 0)
            }else if(F11Val==='Piggyback'){
                selectLens("Standard Piggyback IOL", "1", 0)
            }
            else if(F11Val==='IPCL'){
                selectLens("IPCL Toric", "3", 0)
            }
        }, 500)
    }, [F11Val])

    function selectLens(id, type, degrees){
        if(id !== 'Select lens'){
            document.getElementById(id).style["background-color"] = '#f5f5f538';
            setTimeout(() => {
                document.getElementById(id).style["background-color"] = '#ffffff00';
            }, 1000)
            
            setF12VS([id,1]);
            setLensType(type);
            setIOLMarks(degrees);
            setStage(1);
        }
    }

    return (
        <Container className='lensSelector w-75 vCenter'>
            <div className='styledBox lensSelectorBox'>
                <Row>
                    <Col className='lensSelectorTitle'>
                        <span>Select Lens Type:</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Lenses
                        Val={F11Val} setVal={setF11Val}
                        setSt={setF11St}
                        />
                    </Col>
                </Row>
                <Row>
                    <div className='lensSelectorFlexbox'>
                        {options.map((option) => (
                           <div key={option.name}
                           style={{opacity: (option.name === 'Select lens')? '0' : '1'}}>
                            <Row>
                                <div className='lensOption' id={option.name}
                                onClick={() => selectLens(option.name, option.type, option.degrees)}>
                                    <img className='lensImg' alt='lensImg'
                                    src={require('./lensImages/'+option.image+'.png')}
                                    style={{width: (option.manufacturer==='Eyecril')? '8vw' : '12vw'}}/>
                                </div>
                            </Row>
                            <Row>
                                <Col style={{position: 'relative'}} className='xs-nopadding'>
                                    <span className='lensLabel'
                                    style={{fontSize: (option.manufacturer==='Eyecril')? '1vw' : '1.2vw'}}>
                                        {option.name}
                                    </span>
                                    
                                    <span style={{display: (option.name === 'Artiflex Toric 0°'? null : 'none')}} 
                                    className='hint4'>
                                        &nbsp;<FaRegQuestionCircle style={{marginBottom: '1%'}}/>
                                        <span className='hintText4'>
                                            <img src={hint4} alt='hint4'
                                            className='hint4Img'></img>
                                        </span>
                                    </span>

                                    <span style={{display: (option.name === 'Artiflex Toric 90°'? null : 'none')}} 
                                    className='hint4'>
                                        &nbsp;<FaRegQuestionCircle style={{marginBottom: '1%'}}/>
                                        <span className='hintText4'>
                                            <img src={hint5} alt='hint4'
                                            className='hint5Img'></img>
                                        </span>
                                    </span>
                                </Col>
                            </Row>
                           </div>
                        ))}
                    </div>
                </Row>
                <Row>
                    <span className='lensHelpSpan'>(Click on lens image to select)</span>
                </Row>
            </div>
        </Container>
    )
}
