import React from 'react'
import './aboutUs.css'
import BarHeader from './common/BarHeader'
import toricLogo from './img/toric-logo.png'
import {RiInformationLine} from 'react-icons/ri'

export default function AboutUs() {
  return (
    <div>
        <BarHeader/>
        <div className='aboutUs'>
          <div className='aboutUsContent'>
              <span className='aboutTitle'>
                <span><RiInformationLine className='aboutTitleIcon'/>Toric Aligner</span>
              </span>
              <p className='aboutUsText'>
                Toricaligner.com is a free online tool that uses vector analysis, together with meridional analysis, to help clinicians determine the axis in which to align a misplaced or rotated toric IOL, and its predicted residual cylinder.
                <br></br>
                <br></br>
                The results are calculated out of pre and post operative measurements, so these are crucial to get a result as accurate as possible.
                <br></br>
                It doesn't use a fixed ratio for IOL toricity, shows you the predicted refraction at different axis, and also is the first online tool that helps you realign standard, phakic or piggyback toric IOLs.
                <br></br>
                <br></br>
                1) The authors suggest total corneal astigmatism (TCA) as measured by a Scheimpflug camera, an anterior segment OCT or the IOLMaster 700 (Total Keratometry).
                <br></br>
                2) Using the postoperative TCA allows surgeons to take into consideration the surgically induced corneal astigmatism (SICA) and the posterior corneal astigmatism.
                <br></br>
                3) It is recommended to enter 3 postoperative TCA measurements (the calculator provides their average).
                <br></br>
                4) Preoperative TCA is used to calculate the SICA as the difference between the preoperative and the postoperative TCA.
                <br></br>
                5) Preoperative keratometric astigmatism (KA) and axial length are used for meridional analysis, which provides the ratio between the cylinder at the corneal plane and the cylinder at the IOL plane
                <br></br>
                6) If no pre op TCA measurements are loaded, the magnitude and axis of keratometry will be used as the pre operative total corneal astigmatism. If you dont have post operative corneal measurements, the pre operative TCA will be used as the main vector for toric alignment in standard toric IOLs. If no pre or post op corneal measurements are available, the pre op keratometry will be used as the main vector in which to align the IOL.
                The more measurements, the more accurate the calculation will be.
                <br></br>
                7) In the phakic/piggyback, refraction + sica are used as the main vector to correct.
                <br></br>
                8) Its a useful tool to help surgeons re align any type of toric IOL, even off label uses like toric piggyback over toric IOL.
                <br></br>
                9) By logging in you will be able to save your SICA records and cases without storing any patient data.
                <br></br>
                <br></br>
                It was developed by Dr Savini Giacomo (Bologna, Italy) and Dr Buonsanti Dante (Buenos Aires, Argentina).
                <br></br>
                For any doubt or suggestion you can contact them at:
                <br></br>
                dantebuonsanti@gmail.com&nbsp;
                <br style={{display: 'sm-none'}}></br>giacomo.savini@startmail.com
                <br></br>
                <br></br>
                This website's interface and functionalities has been developed by Ignacio Cruz Gross. 
                You can reach him in his personal page:&nbsp;
                <a target="_blank" href='https://ignaciocruz.tech' className='about-link'>
                  www.ignaciocruz.tech
                </a>
                &nbsp;or sending an email to grossignacio01@gmail.com
              </p>
          </div>
      </div>
    </div>
  )
}
