import React, {useEffect, useState} from 'react'
import {Container, Row, Col, Form} from 'react-bootstrap';
import './login.css'
import BarHeader from './common/BarHeader'
import Popup from './common/Popup';
import ResetPopup from './ResetPopup';

import registerIcon from './img/register-user-icon.png'
import {CgLogIn} from 'react-icons/cg'
import {BiErrorCircle} from 'react-icons/bi'

import {sendLoginRequest} from './services/userServices'
import { logOut } from './services/Authentication';

export default function Login() {

    useEffect(() => {
        try{
            logOut();
        }catch(error){
            console.log("Already logged out.")
        }
    }, [])

    //Login credentials
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [badCredentials, showBadCredentials] = useState(false);
    const [resetPassword, showResetPassword] = useState(false);

    async function handleLoginBtn(){
        var loginResult = await sendLoginRequest(email, password);
        if(loginResult === "Bad Credentials"){
            showBadCredentials(true);
        }else if(loginResult === "Success"){
            window.location.replace("/user");
        }
    }

    return (
        <div>
            <BarHeader/>
            <div className='login'>
                <Container className='loginContainer styledBox'>
                    <Row className='noMargin'>
                        <Col xs={12} sm={3} className='registerIconCol vCenter'>
                            <Row style={{marginBottom: '0.7vh'}}>
                                <span className='registerIconText textCenter'>
                                    Log In
                                </span>
                            </Row>
                            <Row>
                                <img src={registerIcon} alt='registerIcon' className='vCenter registerIcon'/>
                            </Row>
                        </Col>
                        <Col xs={12} sm={9} className='loginFormCol vCenter'>
                            <Form onSubmit={e => { e.preventDefault(); }}>
                                <Form.Group className='loginFormGroup'>
                                    <Row>
                                        <span>Enter Your Credentials:</span>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <Form.Control type="email" value={email} 
                                            placeholder={'Email'} className='loginInput'
                                            onChange={(e) => 
                                                {      
                                                    setEmail(e.target.value);
                                                }}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control type="password" value={password} 
                                            placeholder={'Password'} className='loginInput'
                                            onChange={(e) => 
                                                {      
                                                    setPassword(e.target.value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Row className='registerButtonCol vCenter'>
                                    <div className='registerBtn' id='registerBtn'
                                    onClick={() => handleLoginBtn()}>
                                        <span className='hCenterFlex'>
                                            <div className='vCenter'>
                                                <CgLogIn className='registerBtnIcon'/>
                                            </div>
                                            <span style={{marginTop: '0.2vh'}}>Log In</span>
                                        </span>
                                    </div>
                                </Row>
                                <Row className='loginMessage'>
                                    <Col>
                                        Log In to anonymously save your cases, 
                                        so you dont have to enter them every time, and also to have your 
                                        SIA record.
                                        No patient data is stored on the site, only measurements.
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>

                    <div className='suggestionSpan'>
                        Can't remember your password?&nbsp;
                        <span className='link'
                        onClick={() => showResetPassword(true)}>
                            Reset password
                        </span>
                    </div>
                </Container>
            </div>

            <Popup 
            showPopUp={badCredentials}
            onClick={() => {
                showBadCredentials(false);
            }}
            Icon={<BiErrorCircle style={{marginBottom: '0.65vh'}}/>}
            Title={"Bad Credentials"}
            Body={
                <span> 
                    It seems that you entered a wrong email or password. Please try again.
                </span>
            }
            />
            
            <ResetPopup
            showPopUp={resetPassword}
            setShowPopup={showResetPassword}
            />
        </div>
    )
}
