export class PiggybackCalculation{
    constructor(lensType, lens, patient, surgeon, eye, orderedAxis, k1, k2, steepMeridian, 
        preopRefSph, preopRefCyl, preopRefAxis, preopRefVertex, tca1,
        postopRefSph, postopRefCyl, postopRefAxis, postopRefACD, IOLCyl, tca2,) {
            this.lensType = lensType;
            this.lens = lens;

            this.patient = patient;
            this.surgeon = surgeon;
            this.eye = eye;
            this.orderedAxis = orderedAxis;
            this.k1 = k1;
            this.k2 = k2;
            this.steepMeridian = steepMeridian;
            this.preopRefSph = preopRefSph;
            this.preopRefCyl = preopRefCyl;
            this.preopRefAxis = preopRefAxis;
            this.preopRefVertex = preopRefVertex;
            this.tca1 = tca1;

            this.postopRefSph = postopRefSph;
            this.postopRefCyl = postopRefCyl;
            this.postopRefAxis = postopRefAxis;
            this.postopRefACD = postopRefACD;
            this.IOLCyl = IOLCyl;
            this.tca2 = tca2;
      }
}