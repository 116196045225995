import React from 'react'
import {Row, Col} from 'react-bootstrap';
import PatientName from '../../common/inputs/PatientName';
import SurgeonName from '../../common/inputs/SurgeonName';
import EyeLR from '../../common/inputs/EyeLR';
import NumInput from '../../common/inputs/NumInput';

import {FaRegQuestionCircle} from 'react-icons/fa'

import orderedAxisHint from '../../img/hint3.png'

export default function PatientData({
    lensType,
    F21Val, setF21Val, setF21St,
    F22Val, setF22Val, setF22St,
    F23Val, setF23Val, setF23St,
    F24VS, setF24VS,
    }) {

    return (
        <Row>
            <Row className='hCenter patientDataForm'>
                <Row className='xs-nogutter'>
                    <Col xs={12} sm={6}>
                        <PatientName Val={F21Val} setVal={setF21Val} setSt={setF21St}/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <SurgeonName Val={F22Val} setVal={setF22Val} setSt={setF22St}/>
                    </Col>
                </Row>
                <Row className='xs-nogutter'>
                    <Col className='eyeCol'>
                        <EyeLR Val={F23Val} setVal={setF23Val} setSt={setF23St}/>
                    </Col>
                    <Col xs={12} sm={6}
                    style={{display: (lensType!=="2")? 'none' : null}}
                    className='noPadding'>
                            <Row style={{position: 'relative'}}>
                            <NumInput VS={F24VS} setVS={setF24VS}
                            label="Ordered IOL Axis:"
                            placeholder="13~40"
                            min={0}
                            max={180}
                            step={0.1}
                            readonly={false}
                            />
                            <span className='hint3 vCenter d-none d-sm-flex'>
                                <FaRegQuestionCircle/>
                                <span className='hintText hintText3 noPadding'>
                                    <img className='hint3Img' src={orderedAxisHint}></img>
                                </span>
                            </span>
                        </Row>
                    </Col>
                </Row>
            </Row>
        </Row>
    )
}
