import React, {useState, useEffect} from 'react';
import {Form} from 'react-bootstrap';

export default function Manufacturer({VS, setVS, options, placeholder, label}) {

    const [Class, setClass] = useState('formControl controlNeutral');

    useEffect(() => {
        //Check validity and set class.
        if(VS[0] === placeholder || VS[0] === ""){
            setVS([VS[0],0]);
            setClass('formControl controlNeutral');
        }else{
            setVS([VS[0],1]);
            setClass('formControl controlValid eyeInput');
        }
    }, [VS[0], Class]);

    return (
        <Form onSubmit={e => { e.preventDefault(); }}>
            <Form.Group>
                <div className='inputDiv'>
                    <span className='controlLabel' 
                    style={{display: (label==='')?'none': null}}>
                        {label}&nbsp;
                    </span>
                    <Form.Select placeholder={'Manufacturer'}
                    className={Class + " noPadding"} value={VS[0]}
                    onChange={(e) => 
                        {      
                            setVS([e.target.value, VS[1]]);
                        }}
                    >
                        <option key={placeholder} value={placeholder} 
                        style={{display: 'none'}}>
                            {placeholder}
                        </option>
                        {options.map((option) => <option key={option} value={option}>{option}</option>)}
                    </Form.Select>
                </div>  
            </Form.Group>
        </Form>
    )
}
