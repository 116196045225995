import React, {useEffect, useState} from 'react'
import {getMeridionalRatio} from '../../services/ToricCalculation';

import patienticon from '../../img/patient-border.png';
import NumInput from '../../common/inputs/NumInput';
import Manufacturer from '../../common/inputs/IOLManufacturer';
import IOLCyl from '../../common/inputs/IOLCyl';
import Model from '../../common/inputs/IOLModel';
import {Container, Row, Col} from 'react-bootstrap';

export default function PostopDataA({
    F15VS, F31VS, F32VS, F41VS,
    F42VS, F44VS,

    F61VS, setF61VS,
    F62VS, setF62VS,
    F63VS, setF63VS,
    F64VS, setF64VS,

    F71VS, setF71VS,
    F72VS, setF72VS,
    F73VS, setF73VS,
    F74VS, setF74VS,
}){
    //Automatic refresh for Corneal plane F54 input
    useEffect(() => {
        
        try {
            let meridionalRatio = getMeridionalRatio(
            parseFloat(F31VS[0]), parseFloat(F32VS[0]),
            parseFloat(F41VS[0]), parseFloat(F42VS[0]), 
            parseFloat(F44VS[0]), parseFloat(F74VS[0])
            );
            var cornealPlane = parseFloat(F63VS[0]) / meridionalRatio;
            setF64VS([ cornealPlane.toFixed(2) , 1 ]);
        }catch(error){}

    }, [F31VS[0], F32VS[0], F32VS[0], 
    F41VS[0], F42VS[0], F44VS[0],
    F63VS[0], F74VS[0]]);

    return (
            <Row className='styledBox bigBlock3'>

                <Col className='formIconDiv' id='box-icon' xs={12} sm={3}>
                    <Row style={{marginBottom: '0.4vh'}} id='box-icon-title'>
                      Post Op <br></br> Patient Data
                    </Row>
                    <Row>
                      <img src={patienticon} className='formIcon patient'/>
                    </Row>
                </Col>

                <Col xs={12} sm={9} className='formCol vCenter hCenter xs-nomargin'>

                    <Row>
                        <Row className='xs-nogutter' id='pb-postop-ref'>
                            <Row className='title2 noPadding' style={{margin: '1vh 0 0.2vh 0vh'}}>
                                Postoperative Refraction
                            </Row>
                            <Row className='postopRefRow xs-nomargin'>
                                <Col xs={12} sm={6} className='xs-nopadding'>
                                    <NumInput VS={F71VS} setVS={setF71VS}
                                        label="Sphere:"
                                        placeholder="Sphere"
                                        min={-15}
                                        max={10}
                                        step={0.1}
                                        readonly={false}
                                    />
                                </Col>
                                <Col xs={12} sm={6} className='xs-nopadding'>
                                    <NumInput VS={F72VS} setVS={setF72VS}
                                        label="Cylinder:"
                                        placeholder="Cylinder"
                                        min={-15}
                                        max={10}
                                        step={0.1}
                                        readonly={false}
                                    />
                                </Col>
                            </Row>
                            <Row className='postopRefRow xs-nomargin'>
                                <Col xs={12} sm={6} className='xs-nopadding'>
                                    <NumInput VS={F73VS} setVS={setF73VS}
                                        label="Axis:"
                                        placeholder="Axis"
                                        min={0}
                                        max={180}
                                        step={0.1}
                                        readonly={false}
                                    />
                                </Col>
                                <Col xs={12} sm={6} className='xs-nopadding'>
                                    <NumInput VS={F74VS} setVS={setF74VS}
                                        label="ACD:"
                                        placeholder="Epi to IOL or ACD - Vaulting"
                                        min={-15}
                                        max={10}
                                        step={0.1}
                                        readonly={false}
                                    />
                                </Col>
                            </Row>
                        </Row>
                    </Row>

                    <Row className='postopARow xs-nogutter' style={{margin: '0.6vh 0 0.15vw 0'}}>
                        <Row className='title2' style={{marginBottom: '0.15vw'}}>
                            <Col className='noPadding' xs={6}>
                                <span>Implanted IOL Cylinder</span>
                            </Col>
                        </Row>
                        <Row className='xs-nogutter'>
                            <Col xs={12} sm={6}>
                                <IOLCyl VS={F63VS} setVS={setF63VS}
                                    label="Cylinder Power:"
                                    placeholder="I.e: 1.75"
                                    min={0}
                                    max={10}
                                    step={0.1}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <NumInput VS={F64VS} setVS={setF64VS}
                                    label="Spectacle Plane:"
                                    placeholder="Spectacle plane"
                                    min={0}
                                    max={1000}
                                    step={0.1}
                                    readonly={true}
                                />
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
    )
}
