import {server_url} from '../Settings'
import axios from 'axios';
import { getViewport } from './StyleUtilites';
const requestConfig = {
    withCredentials: true,
}

// // REGISTER FUNCTIONS // // 

function validateMail(mail){
    return String(mail)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

const securePasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!%*#?&]{8,}$/

async function postNewAccount(email, password, country){
    var jsonData = {
        "email": email,
        "password": password,
        "country": country
    };

    axios.post(server_url + "/users/register", jsonData);
}

export async function registerNewAccount(email, password, password2, country, setErrorMsg){
    document.getElementById("register").style.cursor = 'progress';
    document.getElementById("registerBtn").style.cursor = 'progress';
    document.getElementById("registerBtn").disabled = true;

    //Validate info
    if( !validateMail(email) ){
        setErrorMsg("Please, enter a valid email.")
    }else if( country === "Country"){
        setErrorMsg("Please, enter a valid country.")
    }else if( !securePasswordRegex.test(password) ){
        if(getViewport[0] > 768){
            setErrorMsg("Please, choose a secure password. It must have a minimum of eight characters, " 
            + "at least one uppercase letter, one lowercase letter, one number and one special character (@$!%#*?&).")
        }else{
            window.alert("Please, choose a secure password. It must have a minimum of eight characters, " 
            + "at least one uppercase letter, one lowercase letter, one number and one special character (@$!%#*?&).")
        }
    }else if (password  !== password2){
        setErrorMsg("Warning! Password and password confirmation does not match.")
    }else{
        //Check email availability
        const res = await axios.get(server_url + '/users/available?email=' + email);
        if (res.data){
            postNewAccount(email, password, country);
            document.getElementById("register").style.cursor = 'auto';
            document.getElementById("registerBtn").style.cursor = 'pointer';
            setErrorMsg("");
            return true;
        }else{
            setErrorMsg("There is already an account registered with that email address.")
        }
    }

    document.getElementById("register").style.cursor = 'auto';
    document.getElementById("registerBtn").style.cursor = 'pointer';
    document.getElementById("registerBtn").disabled = false;
    return false;
}

export async function activateUser(token, id){
    return new Promise(resolve => {
        axios.get(server_url + '/users/activate?token=' + token + "&id=" + id)
        .then(resp => {
            resolve(resp.data);
        });
    })
}


// LOGIN FUNCTIONS //

export async function sendLoginRequest(email, password){
    return new Promise(resolve => {
        const jsonData = {
            "email": email,
            "password": password
        };
        axios.post(server_url + "/users/login", jsonData, {withCredentials: true})
        .then(resp => {
            resolve(resp.data);
        })
        .catch(function (error){
            resolve("Bad Credentials");
        });
    });
}

// CHANGE PASSWORD //

export async function requestPasswordReset(email){
    document.getElementById("resetPopup").style.cursor = 'progress';
    document.getElementById("resetPwdButton").style.cursor = 'progress';
    document.getElementById("resetPwdButton").disabled = true;

    function setCursorNormal(){
        document.getElementById("resetPopup").style.cursor = 'auto';
        document.getElementById("resetPwdButton").style.cursor = 'pointer';
        document.getElementById("resetPwdButton").disabled = false;
    }

    if(!validateMail(email)){
        setCursorNormal();
        return false;
    }
    return new Promise(resolve => {
        axios.get(server_url + "/users/passwordReset?email=" + email)
        .then(resp => {
            setCursorNormal();
            resolve(resp.data);
        })
        .catch(function (error){
            setCursorNormal();
            resolve(false);
        });
    });
}

export async function setNewPassword(id, token, password){
    document.getElementById("resetPassword").style.cursor = 'progress';
    document.getElementById("changePasswordBtn").style.cursor = 'progress';
    document.getElementById("changePasswordBtn").disabled = true;

    function setCursorNormal(){
        document.getElementById("resetPassword").style.cursor = 'auto';
        document.getElementById("changePasswordBtn").style.cursor = 'pointer';
        document.getElementById("changePasswordBtn").disabled = false;
    }

    var jsonData = {
        "id": id,
        "token": token,
        "newPassword": password
    };

    return new Promise(resolve => {
        axios.post(server_url + "/users/setNewPassword", jsonData, requestConfig)
        .then(resp => {
            setCursorNormal();
            resolve(resp.data);
        })
        .catch(function (error){
            setCursorNormal();
            resolve(false);
        });
    });
}