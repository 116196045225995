import React, {useState} from 'react'
import './resetPassword.css'
import {Container, Row, Col, Form} from 'react-bootstrap';
import BarHeader from './common/BarHeader'
import {GiKeyLock} from 'react-icons/gi';
import {FiEdit2} from 'react-icons/fi'
import { setNewPassword } from './services/userServices';

export default function ResetPassword() {

    const securePasswordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

    const queryParams = new URLSearchParams(window.location.search);
    const activationToken = queryParams.get('token');
    const userId = queryParams.get('id');

    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [errorMsg, setErrorMsg] = useState("")

    async function changePassword(){
        if(password !== password2){
            setErrorMsg("Error: Password and password confirmation does not match.")
            return;
        }else if( !securePasswordRegex.test(password) ){
            setErrorMsg("Please, choose a secure password. It must have a minimum of eight characters, at least one uppercase letter," 
            + " one lowercase letter, one number and one special character.")
            return;
        }

        let result = await setNewPassword(userId, activationToken, password);
        if(result){
            window.location.replace("/login");
        }else{
            setErrorMsg("Password reset link is invalid.")
        }
    }

    return (
        <div>
            <BarHeader/>
            <div className='resetPassword' id='resetPassword'>
                <Container className='loginContainer styledBox'>
                    <Row className='noMargin'>
                        <Col xs={3} className='registerIconCol'>
                            <Row style={{marginBottom: '0.7vh'}}>
                                <span className='registerIconText textCenter'>
                                    Reset Password
                                </span>
                            </Row>
                            <Row>
                                <GiKeyLock className='changePasswordIcon'/>
                            </Row>
                        </Col>
                        <Col xs={9} className='loginFormCol vCenter'>
                            <Form onSubmit={e => { e.preventDefault(); }}>
                                <Form.Group className='loginFormGroup'>
                                    <Row>
                                        <span style={{fontSize: '1.2vw'}}>
                                            Enter new password:
                                        </span>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            <Form.Control type="text" value={password} 
                                            placeholder={'New Password'} className='loginInput'
                                            onChange={(e) => 
                                                {      
                                                    setPassword(e.target.value);
                                                }}
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Control type="text" value={password2} 
                                            placeholder={'Confirm new Password'} className='loginInput'
                                            onChange={(e) => 
                                                {      
                                                    setPassword2(e.target.value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Row className='registerButtonCol vCenter'>
                                    <div className='registerBtn' id='changePasswordBtn'
                                    onClick={() => changePassword()}>
                                        <span className='hCenterFlex'>
                                            <div className='vCenter'>
                                                <FiEdit2 className='registerBtnIcon'/>
                                            </div>
                                            <span style={{marginTop: '0.2vh'}}>Change Password</span>
                                        </span>
                                    </div>
                                    <span className='pwdErrorText'>{errorMsg}</span>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
