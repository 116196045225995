import React, { useEffect } from 'react'
import './preopdata.css'
import patienticon from '../img/patient-border.png';
import PatientData from './forms/PatientData'
import PreopMeasures from './forms/PreopMeasures'
import ContinueBtn from '../common/ContinueBtn';
import {Container, Row, Col} from 'react-bootstrap';

import {IoRefreshCircle} from 'react-icons/io5'

export default function PreopData({
    stage, setStage, validPreop, setValidPreOct,

    F11Val, setF11Val, setF11St,
    F12Val, setF12Val, setF12St,
    F13Val, setF13Val, setF13St,
    F15VS, setF15VS,

    F21VS, setF21VS,
    F22VS, setF22VS,
    F23VS, setF23VS,
    F24VS, setF24VS,

    F31VS, setF31VS,
    F32VS, setF32VS,
    F33VS, setF33VS,
    F34VS, setF34VS,
    F35VS, setF35VS,
    F36VS, setF36VS,
    F37Val, setF37Val,
    F38Val, setF38Val,

    F41VS, setF41VS,
    F42VS, setF42VS,
    F43VS, setF43VS,
    F44VS, setF44VS,
    F45VS, setF45VS,
    F46VS, setF46VS,
    F47Val, setF47Val,
    F48Val, setF48Val
  }) {
  
  return (
    <Container fluid className='preopData w-75'>

        <Row className='title'>
          <h1 className='h1vCenter'>Preoperative Data</h1>
        </Row>

        {/*<div className='refreshBtn styledBox' onClick={() => {
          document.location.reload()
        }}>
            <IoRefreshCircle/>
        /div>*/}

        <Row className='formRow'>

            <Col className='vCenter xs-nopadding' sm={9} xs={12}>

              <Row className='styledBox bigBlock' id='st-box1'>

                <Col className='formIconDiv block1' id='box-icon' xs={12}>
                  <Row style={{marginBottom: '0.7vh'}} id='box-icon-title'>
                    Patient Data
                  </Row>
                  <Row>
                    <img src={patienticon} className='formIcon patient'/>
                  </Row>
                </Col>

                <Col className='vCenter block2' id='st-block1-inputs'>
                  <PatientData 
                    F11Val={F11Val} setF11Val={setF11Val} setF11St={setF11St}
                    F12Val={F12Val} setF12Val={setF12Val} setF12St={setF12St}
                    F13Val={F13Val} setF13Val={setF13Val} setF13St={setF13St}
                    F15VS={F15VS} setF15VS={setF15VS}
                  />
                </Col>

              </Row>

            </Col>

            <Col className='calculateCol block3 d-none d-sm-flex' xs={3}>
              <ContinueBtn 
              Stage={stage} setStage={setStage}
              validPreop={validPreop}
              />
            </Col>
    
        </Row>

        
        <Row className='vCenter formRow xs-nogutter xs-nopadding'>
          <PreopMeasures
            setValidPreOct={setValidPreOct}

            F21VS={F21VS} setF21VS={setF21VS}
            F22VS={F22VS} setF22VS={setF22VS}
            F23VS={F23VS} setF23VS={setF23VS}
            F24VS={F24VS} setF24VS={setF24VS}

            F31VS={F31VS} setF31VS={setF31VS}
            F32VS={F32VS} setF32VS={setF32VS}
            F33VS={F33VS} setF33VS={setF33VS}
            F34VS={F34VS} setF34VS={setF34VS}
            F35VS={F35VS} setF35VS={setF35VS}
            F36VS={F36VS} setF36VS={setF36VS}
            F37Val={F37Val} setF37Val={setF37Val}
            F38Val={F38Val} setF38Val={setF38Val}

            F41VS={F41VS} setF41VS={setF41VS}
            F42VS={F42VS} setF42VS={setF42VS}
            F43VS={F43VS} setF43VS={setF43VS}
            F44VS={F44VS} setF44VS={setF44VS}
            F45VS={F45VS} setF45VS={setF45VS}
            F46VS={F46VS} setF46VS={setF46VS}
            F47Val={F47Val} setF47Val={setF47Val}
            F48Val={F48Val} setF48Val={setF48Val}
          />
        </Row>

        <Row className='d-sm-none xs-nogutter'>
            <Col className='calculateCol block3' xs={12}>
              <ContinueBtn 
              Stage={stage} setStage={setStage}
              validPreop={validPreop}
              />
            </Col>
        </Row>
    </Container>
  )
}
