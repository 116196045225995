import React, {useEffect} from 'react'
import {ImUserPlus} from 'react-icons/im'

export default function CalculateBtn({validInput, onClick, addedPatients}) {

  useEffect( () => {

    let addPatientBtn = document.getElementById("addPatientBtn")

      if(validInput){
        addPatientBtn.disabled = false;
      }else{
        addPatientBtn.disabled = true;
      }

  }, [validInput])

  return (
    <span id='addPatientBtn' onClick={(validInput)?onClick:null}
    className={(validInput)? 'sia-add-patient-btn enabled':'sia-add-patient-btn disabled'}>
        <b><ImUserPlus/> Add Patient</b>
    </span>
  )
}

