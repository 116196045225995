import React, {useEffect} from 'react'
import {IoCalculator} from 'react-icons/io5'

export default function CalculateBtn({onClick, addedPatients}) {

  useEffect( () => {

    let calculateBtn = document.getElementById("calculateBtn")

      if(addedPatients > 0){
        calculateBtn.disabled = false;
      }else{
        calculateBtn.disabled = true;
      }

  }, [addedPatients])

  return (
    <button className={(addedPatients > 0)? 'sia-calculateBtn-enabled styledBox title2' : 'sia-calculateBtn-disabled styledBox'} type="button" 
    id='calculateBtn' onClick={(addedPatients > 0)?onClick:null}>
        Calculate SIA<br></br>
        <IoCalculator className='calculatorIcon'/><br></br>
        <span id='sia-addedpatients' style={{fontWeight: '200', fontSize: '1.1vw'}}>({addedPatients} patients added)</span>
    </button>
  )
}

