import React, {useState, useEffect} from 'react';
import {Form} from 'react-bootstrap';

export default function Manufacturer({VS, setVS}) {
    const [Class, setClass] = useState('formControl controlNeutral');

    useEffect(() => {
        //Check validity and set class.
        if(VS[0] === "Manufacturer" || VS[0] == undefined){
            setVS([VS[0],0]);
            setClass('formControl colorgrey eyeInput');
        }else{
            setVS([VS[0],1]);
            setClass('formControl controlValid eyeInput');
        }
    }, [VS[0], Class]);

    return (
        <Form onSubmit={e => { e.preventDefault(); }}>
            <Form.Group>
                <div className='inputDiv'>
                    <Form.Select placeholder={'Manufacturer'}
                    id='st-iol-mfact'
                    className={Class} value={VS[0]}
                    onChange={(e) => 
                        {
                            setVS([e.target.value, VS[1]]);
                        }}
                    >
                        <option key='mfac' value="Manufacturer" style={{display: 'none'}}>
                            Manufacturer
                        </option>
                        <option key="Alcon" value="Alcon">Alcon</option>
                        <option key="B+L" value="B+L">Bausch+Lomb</option>
                        <option key="J&J" value="J&J">J&amp;J</option>
                        <option key="Physiol" value="Physiol">Physiol</option>
                        <option key="Rayner" value="Rayner">Rayner</option>
                        <option key="Other" value="Other">Other</option>
                    </Form.Select>
                </div>  
            </Form.Group>
        </Form>
    )
}
