import React from 'react'
import './tooltip.css'
import {AiOutlineCloseCircle} from 'react-icons/ai';
import { useState } from 'react';

export default function Tooltip({message, show, id}) {

    useState( () => {
        if(show){
            document.getElementById("tooltip").className = "tooltip-box fadeIn";
        }
    },[show])

    return (
        <div id={id} className='tooltip-box transparent'>
            {message}
            <AiOutlineCloseCircle id='tooltip-close-btn'
            onClick={() => {document.getElementById("tooltip").className="tooltip-box transparent"
                            document.getElementById("xs-tooltip").className="tooltip-box transparent"
                            document.getElementById("xs-tooltip").style.display = "none"}}/>
        </div>
    )
    
}
