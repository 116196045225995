import React, {useState, useRef, useEffect} from 'react'
import './result.css'

import {averageOf3, getTanDeg} from '../services/ToricCalculation';

import { Container, Row, Col, Form } from 'react-bootstrap'

import LoadingDots from '../common/LoadingDots';

import {AiOutlineCloseCircle, AiOutlineFilePdf} from 'react-icons/ai';
import {FaRegQuestionCircle, FaRegFlag, FaUserMd} from 'react-icons/fa'
import {BsArrowRight,BsArrowLeft} from 'react-icons/bs'
import {IoEyeOutline} from 'react-icons/io5';
import {RiArrowGoBackLine} from 'react-icons/ri';

import graphicCircle from '../img/graphicCircle.png'
import graphicIOL from  '../img/graphicIOL.png'
import graphicSuggested from '../img/graphicSuggested.png'

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import Downloading from '../common/Downloading';
import SaveResult from '../common/SaveResult';

import { StandardCalculation } from './StandardCalculation';
import { sendNewResult } from '../services/UserResults';

export default function Result({
    showResult, setShowResult,
    fixedResult,

    Patient, Surgeon,
    Eye, AxialLength,
    K1, K2, SteepMeridian,
    AvgMagnitude1, AvgAxis1,
    AvgMagnitude2, AvgAxis2,
    IOLManufacturer, IOLModel,
    IOLPlane, IOLCornealPlane,
    Sphere, Cylinder, Axis,
    AvgMagnitude3, avgAxis3,
    AvgMagnitude4, avgAxis4,

    PostopRefSphere, PostopRefCylinder, PostopRefAxis,

    TCA1Axis1, TCA1Axis2, TCA1Axis3,
    TCA1Magn1, TCA1Magn2, TCA1Magn3,
    TCA2Axis1, TCA2Axis2, TCA2Axis3,
    TCA2Magn1, TCA2Magn2, TCA2Magn3,
    TCA3Axis1, TCA3Axis2, TCA3Axis3,
    TCA3Magn1, TCA3Magn2, TCA3Magn3,
    TCA4Axis1, TCA4Axis2, TCA4Axis3,
    TCA4Magn1, TCA4Magn2, TCA4Magn3
}) {

    //Result loading div
    const [loading, setLoading] = useState(true);
    //State to hide elements and show downloading popup when downloading results
    const [showDownloading, setShowDownloading] = useState(false);
    const [hideToDownload, setHideToDownload] = useState(false);
    
    //Show save result popup
    const [saveResultWindow, showSaveResultWindow] = useState(false);
    const [resultJSON,setResultJSON] = useState({});
    //PRR auxiliary data: Stored on a saved result for prr recalculation.
    const [PRRRecalculationAux, setPRRRecalculationAux] = useState([]);

    const [orientationValue, setOrientationValue] = useState(null);

    //Result States
    //Meridional Analysis
    //IOL/Cornea Cyl. Ratio:
    const [Result1, setResult1] = useState(0);
    //Required Cylinder at IOL Plane
    const [Result2, setResult2] = useState(0);

    //Implanted IOL cylinder
    //at IOL Plane:
    const [Result3, setResult3] = useState(0);
    //at Corneal Plane:
    const [Result4, setResult4] = useState(0);

    //Induced Corneal Astigmatism
    //TCA1
    //Cylinder
    const [Result5, setResult5] = useState(0);
    //Axis
    const [Result6, setResult6] = useState(0);
    //TCA2
    //Cylinder
    const [Result7, setResult7] = useState(0);
    //Axis
    const [Result8, setResult8] = useState(0);

    //IOL Alignement 
    //According to postop1
    //Predicted Residual refraction
    //SuggestedAxis
    const [AvgAxis3, setAvgAxis3] = useState(avgAxis3);
    //Sphere
    const [Result101, setResult101] = useState(0);
    //Cylinder
    const [Result102, setResult102] = useState(0);
    //Axis
    const [Result103, setResult103] = useState(0);

    //According to postop2
    //SuggestedAxis
    const [AvgAxis4, setAvgAxis4] = useState(avgAxis4);
    //Sphere
    const [Result121, setResult121] = useState(0);
    //Cylinder
    const [Result122, setResult122] = useState(0);
    //Axis
    const [Result123, setResult123] = useState(0);
    

    function getViewport() {

        var viewPortWidth;
        var viewPortHeight;
       
        // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
        if (typeof window.innerWidth != 'undefined') {
          viewPortWidth = window.innerWidth
          viewPortHeight = window.innerHeight
        }
       // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
        else if (typeof document.documentElement !== 'undefined'
        && typeof document.documentElement.clientWidth !==
        'undefined' && document.documentElement.clientWidth !== 0) {
           viewPortWidth = document.documentElement.clientWidth
           viewPortHeight = document.documentElement.clientHeight
        }
        // older versions of IE
        else {
          viewPortWidth = document.getElementsByTagName('body')[0].clientWidth
          viewPortHeight = document.getElementsByTagName('body')[0].clientHeight
        }
        return [viewPortWidth, viewPortHeight];
    }

    function downloadPdf(){
        
        var viewport = getViewport()
        if(viewport[0] > 576){

            setShowDownloading(true);
            setHideToDownload(true);

            setTimeout(() => {
                var input = document.getElementById('divToPrint');
                setHideToDownload(false);
                
                html2canvas(input)
                .then((canvas) => {
                const data = canvas.toDataURL('image/png');

                var orientation = 'l';
                if(viewport[0] < viewport[1]){
                    orientation = 'p'
                }

                const pdf = new jsPDF(orientation, 'px', [viewport[0], viewport[1]]);
                const imgProperties = pdf.getImageProperties(data);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width;


                pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('ToricAligner_' + Patient + '_' + Surgeon + '.pdf');

                setShowDownloading(false);
                })
            }, 1000)
        }else{
            window.alert("Downloading is not available in mobile devices. If you want" +
            " to save your result as a .pdf file, please visit the website from a computer.")
        }
    }

    function modifyInput(result){
        if(!result){
            setShowResult(false);
        }else{
            let calculationObject = new StandardCalculation(
                result.patientID, 'surgeonID', result.preopInput[0], result.preopInput[1], 
                result.preopInput[2], result.preopInput[3], result.preopInput[4],
                result.tca1, result.tca2,
                result.iolModel.split(" ")[0], result.iolModel.split(" ")[1],
                result.iolToricity, result.postopInput[1], result.postopInput[2], 
                result.postopInput[3], result.tca3, result.tca4,
            );
            localStorage.setItem('calculationObject', JSON.stringify(calculationObject));
            window.location.replace('/standard')
        }
    }

    async function saveResult(show){
        let resultDTO;
        if(!fixedResult){
            let prrRecalculationAux = PRRRecalculationAux;
            prrRecalculationAux.push(IOLCornealPlane, PostopRefCylinder, PostopRefSphere);
            resultDTO = ({
                "visible": show,
                "preopInput": [Eye, AxialLength.toString(), K1.toString(),
                K2.toString(), SteepMeridian.toString(), AvgMagnitude1.toString(),
                AvgAxis1.toString(), AvgMagnitude2.toString(), AvgAxis2.toString()],
                "postopInput": [IOLManufacturer + " " + IOLModel, Sphere.toString(),
                Cylinder.toString(), Axis.toString(), AvgMagnitude3.toString(),
                AvgAxis3.toString(), AvgMagnitude4.toString(), AvgAxis4.toString()],
                "tca1": [TCA1Magn1.toString(), TCA1Axis1.toString(),
                        TCA1Magn2.toString(), TCA1Axis2.toString(),
                        TCA1Magn3.toString(), TCA1Axis3.toString()],
                "tca2": [TCA2Magn1.toString(), TCA2Axis1.toString(),
                        TCA2Magn2.toString(), TCA2Axis2.toString(),
                        TCA2Magn3.toString(), TCA2Axis3.toString()],
                "tca3": [TCA3Magn1.toString(), TCA3Axis1.toString(),
                        TCA3Magn2.toString(), TCA3Axis2.toString(),
                        TCA3Magn3.toString(), TCA3Axis3.toString()],
                "tca4": [TCA4Magn1.toString(), TCA4Axis1.toString(),
                        TCA4Magn2.toString(), TCA4Axis2.toString(),
                        TCA4Magn3.toString(), TCA4Axis3.toString()],
                "iolType": "Standard",
                "iolModel": IOLManufacturer + " " + IOLModel,
                "iolToricity": IOLPlane,
                "tca": [AvgMagnitude1.toString(), AvgAxis1.toString()],
                "sia": [Result5.toString(), Result6.toString()],

                "meridionalAnalysis": [Result1, Result2],
                "implantedIolCylinder": [IOLPlane, IOLCornealPlane],
                "sica1": [Result5, Result6],
                "sica2": [Result7, Result8],
                "alignment1": [AvgAxis3, Result101, Result102, Result103],
                "alignment2": [AvgAxis4, Result121, Result122, Result123],
                "prrrecalculationAux": prrRecalculationAux
                });
            setResultJSON(resultDTO);
            if(show){
                showSaveResultWindow(true);
            }else{
                var result = await sendNewResult(resultDTO);
            }
        }
    }

    //Load result if this component is opened from an user stored result.
    function loadFixedResult(result){
        renderInputData(result);
        setOrientationValue(result.alignment1[0]);
        setResult1(result.meridionalAnalysis[0].toFixed(2));
        setResult2(result.meridionalAnalysis[1].toFixed(2));
        setResult3(result.implantedIolCylinder[0].toFixed(2));
        setResult4(result.implantedIolCylinder[1].toFixed(2));
        setResult5(result.sica1[0].toFixed(2));
        setResult6(result.sica1[1]);
        if(result.sica2[0] !== "NaN"){
            setResult7(result.sica2[0].toFixed(2));
            setResult8(result.sica2[1]);
        }
        setAvgAxis3(result.alignment1[0]);
        setResult101(result.alignment1[1].toFixed(2));
        setResult102(result.alignment1[2].toFixed(2));
        setResult103(result.alignment1[3]);
        if(result.alignment2[1] !== "NaN"){
            setAvgAxis4(result.alignment2[0]);
            setResult121(result.alignment2[1].toFixed(2));
            setResult122(result.alignment2[2].toFixed(2));
            setResult123(result.alignment2[3]);
        }else{
            setResult121("NaN");
        }
    }

    //Renders conditionally the "input" information in window title.
    const [inputData, setInputData] = useState(<Col></Col>)
    function renderInputData(result){
        var inputDataColumn;
        if(!result){
            inputDataColumn = 
                (<Col>
                    <b>PREOP:</b>&nbsp;&nbsp;&nbsp;
                    <b>Eye:</b> {Eye} &nbsp;<b>AXL:</b> {AxialLength} 
                    &nbsp;&nbsp;<b>K1:</b> {K1} &nbsp;<b>K2:</b> {K2}
                    &nbsp;&nbsp;<b>Steep Meridian:</b>&nbsp;{SteepMeridian}
                    <span style={{display: (parseFloat(AvgMagnitude1) == 0)? 'none' : null}}>
                    &nbsp;&nbsp;<b>Avg. Mag. 1:</b>&nbsp;{AvgMagnitude1}
                    &nbsp;&nbsp;<b>Avg. Axis 1:</b>&nbsp;{AvgAxis1}
                    </span>
                    <span style={{display: (parseFloat(AvgMagnitude2) === 0)? 'none' : null}}>
                    &nbsp;&nbsp;<b>Avg. Mag. 2:</b>&nbsp;{AvgMagnitude2}
                    &nbsp;&nbsp;<b>Avg. Axis 2:</b>&nbsp;{AvgAxis2}
                    </span>
                    <br></br>
                    <b>POSTOP:</b>&nbsp;&nbsp;&nbsp;
                    <b>IOL:</b> &nbsp;{IOLManufacturer}&nbsp;{IOLModel}
                    &nbsp;&nbsp;<b>Sphere:</b>&nbsp;{Sphere}
                    &nbsp;&nbsp;<b>Cylinder:</b>&nbsp;{Cylinder}
                    &nbsp;&nbsp;<b>Axis:</b>&nbsp;{Axis}
                    &nbsp;&nbsp;<b>Avg. Mag. 1:</b>&nbsp;{AvgMagnitude3}
                    &nbsp;&nbsp;<b>Avg. Axis 1:</b>&nbsp;{AvgAxis3}
                    <span style={{display: (parseFloat(AvgMagnitude4) === 0 || isNaN(AvgMagnitude4))? 'none' : null}}>
                    &nbsp;&nbsp;<b>Avg. Mag. 2:</b>&nbsp;{AvgMagnitude4}
                    &nbsp;&nbsp;<b>Avg. Axis 2:</b>&nbsp;{AvgAxis4}
                    </span>
                </Col>)
        }else{
            inputDataColumn = 
                (<Col>
                    <b>PREOP:</b>&nbsp;&nbsp;&nbsp;
                    <b>Eye:</b> {result.preopInput[0]} &nbsp;<b>AXL:</b> {result.preopInput[1]} 
                    &nbsp;&nbsp;<b>K1:</b> {result.preopInput[2]} &nbsp;<b>K2:</b> {result.preopInput[3]}
                    &nbsp;&nbsp;<b>Steep Meridian:</b>&nbsp;{result.preopInput[4]}
                    <span style={{display: (!result.preopInput[5])? 'none' : null}}>
                    &nbsp;&nbsp;<b>Avg. Mag. 1:</b>&nbsp;{result.preopInput[5]}
                    &nbsp;&nbsp;<b>Avg. Axis 1:</b>&nbsp;{result.preopInput[6]}
                    </span>
                    <span style={{display: (!result.preopInput[7])? 'none' : null}}>
                    &nbsp;&nbsp;<b>Avg. Mag. 2:</b>&nbsp;{result.preopInput[7]}
                    &nbsp;&nbsp;<b>Avg. Axis 2:</b>&nbsp;{result.preopInput[8]}
                    </span>
                    <br></br>
                    <b>POSTOP:</b>&nbsp;&nbsp;&nbsp;
                    <b>IOL:</b> &nbsp;{result.postopInput[0]}
                    &nbsp;&nbsp;<b>Sphere:</b>&nbsp;{result.postopInput[1]}
                    &nbsp;&nbsp;<b>Cylinder:</b>&nbsp;{result.postopInput[2]}
                    &nbsp;&nbsp;<b>Axis:</b>&nbsp;{result.postopInput[3]}
                    &nbsp;&nbsp;<b>Avg. Mag. 1:</b>&nbsp;{result.postopInput[4]}
                    &nbsp;&nbsp;<b>Avg. Axis 1:</b>&nbsp;{result.postopInput[5]}
                    <span style={{display: (!result.postopInput[6])? 'none' : null}}>
                    &nbsp;&nbsp;<b>Avg. Mag. 2:</b>&nbsp;{result.postopInput[6]}
                    &nbsp;&nbsp;<b>Avg. Axis 2:</b>&nbsp;{result.postopInput[7]}
                    </span>
                </Col>)
        }
        setInputData(inputDataColumn);
    }
    
    //Recalculation for Postoperative Refraction when Cylinder<0
    var postopRefSph = PostopRefSphere;
    var postopRefCyl = PostopRefCylinder;
    var postopRefAxis = PostopRefAxis;
    function PostopRefractionRecalculation(){
        postopRefSph = PostopRefSphere+PostopRefCylinder;
        postopRefCyl = (PostopRefCylinder*-1)
        if(postopRefAxis<=90){
            postopRefAxis = (parseFloat(PostopRefAxis)+90);
        }else{
            postopRefAxis = (parseFloat(PostopRefAxis)-90);
        }
    }

    function limitOrientation(){
        //Validation: Limit orientationValue
        if(orientationValue < 0){
            setOrientationValue(0);
        }else if(orientationValue > 180){
            setOrientationValue(180)
        }
    }

    //Calculates and displays results.
    function calculateResults(){
        let prrRecalculationAux = []
        limitOrientation();

        //Result 3 and 4:
        setResult3(IOLPlane);
        setResult4(IOLCornealPlane);

        //// OCT SUBSTITUTION LOGIC ////
        // AXIS //
        //Avg. Axis 3:
        if(!Number.isNaN(avgAxis3)){
            //OCT 3 is filled
            setAvgAxis3(avgAxis3);
        }else if(!Number.isNaN(AvgAxis1)){
            //OCT 1 is filled
            setAvgAxis3(AvgAxis1)
        }else{
            //Use keratometric astigmatism
            setAvgAxis3(SteepMeridian)
        }
        
        //Avg. axis 4:
        if(!Number.isNaN(avgAxis4)){
            //OCT 4 is filled
            setAvgAxis4(avgAxis4);
        }else if(!Number.isNaN(AvgAxis2)){
            //OCT 2 is filled
            setAvgAxis4(AvgAxis2)
        }

        // TCA //
        var tca1Axis1;
        var tca1Magn1;
        var tca1Axis2;
        var tca1Magn2;
        var tca1Axis3;
        var tca1Magn3;
        if(Number.isNaN(TCA1Axis1)){
            /*
            if OCT1 has been not filled by the user:
            Use keratomatric astigmatism steep meridian as 
            OCT1 axis and Magnitude as Magnitude */
            tca1Axis1 = SteepMeridian;
            tca1Magn1 = K1-K2;
        }else{
            //OCT1 Has been filled
            tca1Axis1 = TCA1Axis1;
            tca1Magn1 = TCA1Magn1;
            tca1Axis2 = TCA1Axis2;
            tca1Magn2 = TCA1Magn2;
            tca1Axis3 = TCA1Axis3;
            tca1Magn3 = TCA1Magn3;
        }

        var tca2Axis1;
        var tca2Magn1;
        var tca2Axis2;
        var tca2Magn2;
        var tca2Axis3;
        var tca2Magn3;
        if(Number.isNaN(TCA2Axis1)){
            //TCA2 not filled
            //If TCA4 was filled, Use TCA4, else there is no result
            if(!Number.isNaN(TCA4Axis1)){
                tca2Axis1 = TCA4Axis1;
                tca2Magn1 = TCA4Magn1;
                tca2Axis2 = TCA4Axis2;
                tca2Magn2 = TCA4Magn2;
                tca2Axis3 = TCA4Axis3;
                tca2Magn3 = TCA4Magn3;
            }
        }else{
            //TCA 2 was filled
            tca2Axis1 = TCA2Axis1;
            tca2Magn1 = TCA2Magn1;
            tca2Axis2 = TCA2Axis2;
            tca2Magn2 = TCA2Magn2;
            tca2Axis3 = TCA2Axis3;
            tca2Magn3 = TCA2Magn3;
        }

        var tca3Axis1;
        var tca3Magn1;
        var tca3Axis2;
        var tca3Magn2;
        var tca3Axis3;
        var tca3Magn3;
        if(Number.isNaN(TCA3Axis1)){
            //TCA3 not filled
            //Use TCA1 (can be actual TCA1 or Keratometric astigmatism)
            tca3Axis1 = tca1Axis1;
            tca3Magn1 = tca1Magn1;
            tca3Axis2 = tca1Axis2;
            tca3Magn2 = tca1Magn2;
            tca3Axis3 = tca1Axis3;
            tca3Magn3 = tca1Magn3;
        }else{
            //TCA3 was filled
            tca3Axis1 = TCA3Axis1;
            tca3Magn1 = TCA3Magn1;
            tca3Axis2 = TCA3Axis2;
            tca3Magn2 = TCA3Magn2;
            tca3Axis3 = TCA3Axis3;
            tca3Magn3 = TCA3Magn3;
        }

        var tca4Axis1;
        var tca4Magn1;
        var tca4Axis2;
        var tca4Magn2;
        var tca4Axis3;
        var tca4Magn3;
        if(Number.isNaN(TCA4Axis1)){
            //TCA4 not filled
            //If filled, Use TCA2, else there is no result
            if(!Number.isNaN(TCA2Magn1)){
                tca4Axis1 = TCA2Axis1;
                tca4Magn1 = TCA2Magn1;
                tca4Axis2 = TCA2Axis2;
                tca4Magn2 = TCA2Magn2;
                tca4Axis3 = TCA2Axis3;
                tca4Magn3 = TCA2Magn3;
            }
        }else{
            //TCA 4 was filled
            tca4Axis1 = TCA4Axis1;
            tca4Magn1 = TCA4Magn1;
            tca4Axis2 = TCA4Axis2;
            tca4Magn2 = TCA4Magn2;
            tca4Axis3 = TCA4Axis3;
            tca4Magn3 = TCA4Magn3;
        }

        //// CALCULATION FOR MERIDIONAL ANALYSIS AND IMPLANTED IOL CYL. ////
        //HOFFER Q = HofferIOLPwB - HofferIOLPwA
        //Corrected Axial Length
        let CorrAXL;
        if(AxialLength<18.5){
            CorrAXL = 18.5;
        }else{
            if(AxialLength<=31){
                CorrAXL = AxialLength;
            }else{
                CorrAXL = 31;
            }
        }
        //G
        let G;
        if(CorrAXL<23){
            G=28;
        }else{
            G=23.5;
        }
        //M
        let M;
        if(CorrAXL<23){
            M=1;
        }else{
            M=-1;
        }
        //Vertex
        let vertex = 12;
        //Desired RX
        let RX = 0;
        //Average K
        let AvgK = (parseFloat(K1)+parseFloat(K2))/2;
        //Personalized ACD = Hoffer Q
        let PerACD = 5.89;
        //Predicted ACD
        let PredACD = 
        PerACD + 0.3 * (CorrAXL-23.5) + Math.pow(Math.tan(AvgK*Math.PI/180),2) +
        (0.1 * M * Math.pow(23.5-CorrAXL,2) * 
        (Math.tan(0.1 * Math.pow(G-CorrAXL,2) * Math.PI / 180))) - 0.99166;
        
        
        
        //Calculate HofferIOLPwB: (K86)
        //K for HofferIOLPwB
        let KB = parseFloat(K2);
        //HofferIOLPwB
        let HofferIOLPwB =
        (1336/(AxialLength-PredACD-0.05)) - 
        (1.336/((1.336/(KB+RX/(1-0.001*vertex*RX))) - ((PredACD+0.05)/1000)))
        //Calculate HofferIOLPwA: (K85)
        //K for HofferIOLPwA
        let KA2 = parseFloat(K1);
        //HofferIOLPwA
        let HofferIOLPwA =
        (1336/(AxialLength-PredACD-0.05)) - 
        (1.336/((1.336/(KA2+RX/(1-0.001*vertex*RX))) - ((PredACD+0.05)/1000)));

        //Hoffer Q Final Result
        let HofferQ = HofferIOLPwB - HofferIOLPwA;

    

        //  HOLLADAY 1 = J92-J91  //
        let HolladayConstant = 2.11;
        //R
        let R1 = 337.5/AvgK;
        let R2 = 337.5/K1;
        let R3 = 337.5/K2;
        //AG
        let AG1;
        if(12.5*parseFloat(AxialLength)/23.45<13.5){
            AG1 = 12.5*parseFloat(AxialLength)/23.45;
        }else{
            AG1 = 13.5;
        }
        //ACD
        let ACD = 0.56+R1-Math.sqrt(Math.pow(R1,2)-Math.pow(AG1,2)/4);
        //Alm
        let Alm = parseFloat(AxialLength)+0.2;

        //HolladayIOLPw
        let HolladayIOLPwA = 1336*(1.336*R2-1/3*Alm-0.001*RX*(vertex*(1.336*R2-1/3*Alm)+Alm*R2))/
        ((Alm-ACD-HolladayConstant)*(1.336*R2-1/3*(ACD+HolladayConstant)-
        0.001*RX*(vertex*(1.336*R2-1/3*(ACD+HolladayConstant))+(ACD+HolladayConstant)*R2)));
        let HolladayIOLPwB = 1336*(1.336*R3-1/3*Alm-0.001*RX*(vertex*(1.336*R3-1/3*Alm)+Alm*R3))/
        ((Alm-ACD-HolladayConstant)*(1.336*R3-1/3*(ACD+HolladayConstant)-
        0.001*RX*(vertex*(1.336*R3-1/3*(ACD+HolladayConstant))+(ACD+HolladayConstant)*R3)));

        //Holladay 1
        let Holladay1 = HolladayIOLPwB-HolladayIOLPwA;



        //SRK/T = Q98-Q97
        //Radius
        let radius1 = R1;
        let radius2 = R2;
        let radius3 = R3;
        //LCOR
        let LCOR;
        if(AxialLength>24.2){
            LCOR = -3.446+1.716*parseFloat(AxialLength)-0.0237*Math.pow(parseFloat(AxialLength),2)
        }else{
            LCOR = parseFloat(AxialLength);
        }
        //Cw
        let Cw1 = -5.41+0.58412*LCOR+0.098*AvgK;
        //H
        let H1 = radius1-Math.sqrt(Math.pow(radius1,2)-Math.pow(Cw1,2)/4);
        //A-Constant
        let AConstant = 119.4;
        //ACD Const
        let ACDConst = 0.62467*AConstant-68.747;
        //ACDest
        let ACDest = H1+ACDConst-3.336;
        //na
        let na = 1.336;
        //ncm1
        let ncm1 = 0.333;
        //Rethick
        let Rethick = 0.65696-0.02029*parseFloat(AxialLength)
        //LOPT
        let LOPT = parseFloat(AxialLength)+Rethick;

        //IOL emme
        let IOLemmeA = (1000*na*(na*radius2-ncm1*LOPT))/((LOPT-ACDest)*(na*radius2-ncm1*ACDest));
        let IOLemmeB = (1000*na*(na*radius3-ncm1*LOPT))/((LOPT-ACDest)*(na*radius3-ncm1*ACDest))

        //SRK/T
        let SRKT = IOLemmeB-IOLemmeA;



        //MEAN = RESULT 1 = IOL/CORNEA CYLINDER RATIO
        //IMPLANTED IOL CYLINDER -> RESULT2
        let mean = (HofferQ+Holladay1+SRKT)/3
        let KA = K1-K2;
        let result1 = mean/KA;
        setResult1(result1.toFixed(2));
        setResult2(mean.toFixed(2));

        /*RESULT 4
        let result4 = Result3/result1;
        setResult4(result4.toFixed(2));
        Result 4 is calculated in input stage*/


        
        //// CALCULATION FOR INDUCED CORNEAL ASTIGMATISM ////
        // PENTACAM //
        //rad
        let radA1 = Math.PI/180*tca1Axis1;
        let radA2 = Math.PI/180*tca1Axis2;
        let radA3 = Math.PI/180*tca1Axis3;
        let radB1 = Math.PI/180*tca3Axis1;
        let radB2 = Math.PI/180*tca3Axis2;
        let radB3 = Math.PI/180*tca3Axis3;
        
        //KP(Φ) 
        let KPA1 = tca1Magn1 * Math.cos(2*radA1);
        let KPA2 = tca1Magn2 * Math.cos(2*radA2);
        let KPA3 = tca1Magn3 * Math.cos(2*radA3);
        let KPB1 = tca3Magn1 * Math.cos(2*radB1);
        let KPB2 = tca3Magn2 * Math.cos(2*radB2);
        let KPB3 = tca3Magn3 * Math.cos(2*radB3);
        //KP(Φ+45) 
        let KPA451 = tca1Magn1 * Math.sin(2*radA1);
        let KPA452 = tca1Magn2 * Math.sin(2*radA2);
        let KPA453 = tca1Magn3 * Math.sin(2*radA3);
        let KPB451 = tca3Magn1 * Math.sin(2*radB1);
        let KPB452 = tca3Magn2 * Math.sin(2*radB2);
        let KPB453 = tca3Magn3 * Math.sin(2*radB3);

        //Average KP(Φ)
        let AvgKPA = averageOf3(KPA1,KPA2,KPA3);
        let AvgKPB = averageOf3(KPB1,KPB2,KPB3);
        //Average KP(Φ+45)
        let AvgKP45A = averageOf3(KPA451,KPA452,KPA453);
        let AvgKP45B = averageOf3(KPB451,KPB452,KPB453);
        //For storing this data on a saved result.
        prrRecalculationAux.push(AvgKPB,AvgKP45B);

        //SICA
        let SICAA = AvgKPB - AvgKPA;
        let SICAB = AvgKP45B - AvgKP45A;

        //RESULT 5-6 Induced Corneal Astigmatism TCA1
        //TCA1Cylinder = T22 = sqrt(Q22^2+R22^2)
        let TCA1Cylinder = Math.sqrt(Math.pow(SICAA,2)+Math.pow(SICAB,2))
        //Axis (column N)
        let AxisN = getTanDeg(Math.atan((TCA1Cylinder-SICAA)/SICAB));
        //V22
        let V22;
        if(AxisN<0){
            V22 = 180;
        }else{
            V22 = 0;
        }
        let result6 = AxisN+V22;
        
        let nanAxis = false; 
        setResult5(TCA1Cylinder.toFixed(2));
        if (!Number.isNaN(result6)){
            setResult6(Math.round(result6));
        }else{
            nanAxis = true;    
        }
        
        if (Result5 === "0.00" && nanAxis){
            setResult6(0)
        }



        //// PREDICTED RESIDUAL REFRACTION (corneal plane)////
        // PO = Post Operative
        // Scheimpflug OCT1 //
        //IOLPos = IOL Position
        let IOLPosAxis1;
        if(orientationValue>89){
            IOLPosAxis1 = 90;
        }else{
            IOLPosAxis1 = -90;
        }
        let IOLPosAxis2 = orientationValue-IOLPosAxis1;
        let IOLPosAxis3 = Math.PI/180*IOLPosAxis2;
        //POTCAIOL = Postoperative TCA + IOL
        //KP(Φ) 
        let POTCAIOLKP = AvgKPB + IOLCornealPlane*Math.cos(2*IOLPosAxis3);
        //KP(Φ+45) 
        let POTCAIOLKP45 = AvgKP45B + IOLCornealPlane*Math.sin(2*IOLPosAxis3);

        //PRR = Predicted Residual Refractive Astigmatism 
        //Cylinder
        let PRRCyl = Math.sqrt( POTCAIOLKP*POTCAIOLKP + POTCAIOLKP45*POTCAIOLKP45 );
        //Difference between cyl
        let DifBtwnCyl = postopRefCyl - PRRCyl;
        //Change in sphere
        let ChgInSphere = DifBtwnCyl/2;
        //Change in Sphere
        let PRRSphere = postopRefSph + ChgInSphere;
        //Axis
        let a;
        if(Math.round(PRRCyl)===0){
            a = 0;
        }else{
            a = Math.round(0.5*Math.atan2(POTCAIOLKP45,POTCAIOLKP)*180/Math.PI);
        }
        let b;
        if(a<0){
            b=180;
        }else{
            b=0;
        }
        let PRRAxis = a + b;

        //IOL ALIGNEMENT RESULTS 1
        setResult101(PRRSphere.toFixed(2));
        setResult102(PRRCyl.toFixed(2));
        setResult103(Math.round(PRRAxis));

        
        
        //// CALCULATION FOR INDUCED CORNEAL ASTIGMATISM ////
        // SIRIUS //
        //rad
        radA1 = Math.PI/180*tca2Axis1;
        radA2 = Math.PI/180*tca2Axis2;
        radA3 = Math.PI/180*tca2Axis3;
        radB1 = Math.PI/180*tca4Axis1;
        radB2 = Math.PI/180*tca4Axis2;
        radB3 = Math.PI/180*tca4Axis3;
        
        //KP(Φ) 
        KPA1 = tca2Magn1 * Math.cos(2*radA1);
        KPA2 = tca2Magn2 * Math.cos(2*radA2);
        KPA3 = tca2Magn3 * Math.cos(2*radA3);
        KPB1 = tca4Magn1 * Math.cos(2*radB1);
        KPB2 = tca4Magn2 * Math.cos(2*radB2);
        KPB3 = tca4Magn3 * Math.cos(2*radB3);
        //KP(Φ+45) 
        KPA451 = tca2Magn1 * Math.sin(2*radA1);
        KPA452 = tca2Magn2 * Math.sin(2*radA2);
        KPA453 = tca2Magn3 * Math.sin(2*radA3);
        KPB451 = tca4Magn1 * Math.sin(2*radB1);
        KPB452 = tca4Magn2 * Math.sin(2*radB2);
        KPB453 = tca4Magn3 * Math.sin(2*radB3);

        //Average KP(Φ)
        AvgKPA = averageOf3(KPA1,KPA2,KPA3);
        AvgKPB = averageOf3(KPB1,KPB2,KPB3);
        //Average KP(Φ+45)
        AvgKP45A = averageOf3(KPA451,KPA452,KPA453);
        AvgKP45B = averageOf3(KPB451,KPB452,KPB453);
        //For storing this data on a saved result.
        prrRecalculationAux.push(AvgKPB,AvgKP45B);

        //SICA
        SICAA = AvgKPB - AvgKPA;
        SICAB = AvgKP45B - AvgKP45A;

        //RESULT 5-6 Induced Corneal Astigmatism TCA1
        //TCA1Cylinder = T22 = sqrt(Q22^2+R22^2)
        let TCA2Cylinder = Math.sqrt(Math.pow(SICAA,2)+Math.pow(SICAB,2))
        //Axis (column N)
        AxisN = getTanDeg(Math.atan((TCA2Cylinder-SICAA)/SICAB));
        //V22
        if(AxisN<0){
            V22 = 180;
        }else{
            V22 = 0;
        }
        let result8 = AxisN+V22;
        
        nanAxis = false;
        setResult7(TCA2Cylinder.toFixed(2));
        if (!Number.isNaN(result8)){
            setResult8(Math.round(result8));
        }else{
            nanAxis = true;
        }

        if (Result7 === "0.00" && nanAxis){
            setResult8(0)
        }
        
        //// PREDICTED RESIDUAL REFRACTION (corneal plane)////
        // PO = Post Operative
        // Scheimpflug OCT2 (SIRIUS) //
        //POTCAIOL = Postoperative TCA + IOL
        //KP(Φ) 
        POTCAIOLKP = AvgKPB + IOLCornealPlane*Math.cos(2*IOLPosAxis3)
        //KP(Φ+45) 
        POTCAIOLKP45 = AvgKP45B + IOLCornealPlane*Math.sin(2*IOLPosAxis3)
        
        //PRR = Predicted Residual Refraction 
        //Cylinder
        PRRCyl = Math.sqrt(Math.pow(POTCAIOLKP,2)+Math.pow(POTCAIOLKP45,2))
        //Difference between cyl
        DifBtwnCyl = postopRefCyl - PRRCyl;
        //Change in sphere
        ChgInSphere = DifBtwnCyl/2;
        //Change in Sphere
        PRRSphere = postopRefSph + ChgInSphere;
        //Axis
        if(Math.round(PRRCyl)===0){
            a = 0;
        }else{
            a = Math.round(0.5*Math.atan2(POTCAIOLKP45,POTCAIOLKP)*180/Math.PI);
        }
        if(a<0){
            b=180;
        }else{
            b=0;
        }
        PRRAxis = a + b;
        
        //IOL ALIGNEMENT RESULTS 2
        setResult121(PRRSphere.toFixed(2));
        setResult122(PRRCyl.toFixed(2));
        setResult123(Math.round(PRRAxis));
        
        setPRRRecalculationAux(prrRecalculationAux);
        
        //DEBUG HERE
        //debugger;
    }

    function recalculateIOLAlignment(recalculationAux){

        let AvgKPB, AvgKP45B;
        //change for fixedresult input:::
        let iolCornealPlane = recalculationAux[4];
        let postopRefCyl = recalculationAux[5];
        let postopRefSph = recalculationAux[6];
        

        //// PREDICTED RESIDUAL REFRACTION (corneal plane)////
        // PO = Post Operative
        // Scheimpflug OCT1 //
        //Retrieve AvgKP from function parameters
        AvgKPB = recalculationAux[0];
        AvgKP45B = recalculationAux[1];
        //IOLPos = IOL Position
        let IOLPosAxis1;
        if(orientationValue>89){
            IOLPosAxis1 = 90;
        }else{
            IOLPosAxis1 = -90;
        }
        let IOLPosAxis2 = orientationValue-IOLPosAxis1;
        let IOLPosAxis3 = Math.PI/180*IOLPosAxis2;
        //POTCAIOL = Postoperative TCA + IOL
        //KP(Φ) 
        let POTCAIOLKP = AvgKPB + iolCornealPlane*Math.cos(2*IOLPosAxis3);
        //KP(Φ+45) 
        let POTCAIOLKP45 = AvgKP45B + iolCornealPlane*Math.sin(2*IOLPosAxis3);

        //PRR = Predicted Residual Refractive Astigmatism 
        //Cylinder
        let PRRCyl = Math.sqrt( POTCAIOLKP*POTCAIOLKP + POTCAIOLKP45*POTCAIOLKP45 );
        //Difference between cyl
        let DifBtwnCyl = postopRefCyl - PRRCyl;
        //Change in sphere
        let ChgInSphere = DifBtwnCyl/2;
        //Change in Sphere
        let PRRSphere = postopRefSph + ChgInSphere;
        //Axis
        let a;
        if(Math.round(PRRCyl)===0){
            a = 0;
        }else{
            a = Math.round(0.5*Math.atan2(POTCAIOLKP45,POTCAIOLKP)*180/Math.PI);
        }
        let b;
        if(a<0){
            b=180;
        }else{
            b=0;
        }
        let PRRAxis = a + b;

        //IOL ALIGNEMENT RESULTS 1
        setResult101(PRRSphere.toFixed(2));
        setResult102(PRRCyl.toFixed(2));
        setResult103(Math.round(PRRAxis));


        if(!fixedResult.alignment2[2] === "NaN"){
        //// PREDICTED RESIDUAL REFRACTION (corneal plane)////
        // PO = Post Operative
        // Scheimpflug OCT2 (SIRIUS) //
        //Retrieve AvgKP from function parameters
        AvgKPB = recalculationAux[2];
        AvgKP45B = recalculationAux[3];
        //POTCAIOL = Postoperative TCA + IOL
        //KP(Φ) 
        POTCAIOLKP = AvgKPB + iolCornealPlane*Math.cos(2*IOLPosAxis3)
        //KP(Φ+45) 
        POTCAIOLKP45 = AvgKP45B + iolCornealPlane*Math.sin(2*IOLPosAxis3)

        //PRR = Predicted Residual Refraction 
        //Cylinder
        PRRCyl = Math.sqrt(Math.pow(POTCAIOLKP,2)+Math.pow(POTCAIOLKP45,2))
        //Difference between cyl
        DifBtwnCyl = postopRefCyl - PRRCyl;
        //Change in sphere
        ChgInSphere = DifBtwnCyl/2;
        //Change in Sphere
        PRRSphere = postopRefSph + ChgInSphere;
        //Axis
        if(Math.round(PRRCyl)===0){
            a = 0;
        }else{
            a = Math.round(0.5*Math.atan2(POTCAIOLKP45,POTCAIOLKP)*180/Math.PI);
        }
        if(a<0){
            b=180;
        }else{
            b=0;
        }
        PRRAxis = a + b;

        //IOL ALIGNEMENT RESULTS 2
        setResult121(PRRSphere.toFixed(2));
        setResult122(PRRCyl.toFixed(2));
        setResult123(Math.round(PRRAxis));
        }

    }

    //Trigger Calculation function when result window is opened.
    useEffect( () => {
        if(showResult){
            setLoading(true);
            setTimeout(() => setLoading(false),2000)
            setHideToDownload(false);
            renderInputData(fixedResult);

            if(!fixedResult){
                if(PostopRefCylinder<0){
                    PostopRefractionRecalculation();
                }
                calculateResults();
                if(!Number.isNaN(TCA3Axis1)){
                    //OCT 3 is filled
                    setOrientationValue(avgAxis3);
                }else if(!Number.isNaN(TCA1Axis1)){
                    //OCT 1 is filled
                    setOrientationValue(AvgAxis1)
                }else{
                    //Use keratometric astigmatism
                    setOrientationValue(SteepMeridian)
                }
                saveResult(false);
            }else{
                //Result component is called from user Page results table
                loadFixedResult(fixedResult);
            }
        }
    }, [showResult])

    useEffect( () => {
        if(!fixedResult){
            calculateResults();
        }else{
            if(fixedResult.length !== 1 && orientationValue !== fixedResult.alignment1[0]){
                recalculateIOLAlignment(fixedResult.prrrecalculationAux);
            }
        }
    }, [orientationValue])


    window.onkeypress = function(event) {
        //Reset orientation value on enter/space
        if (event.keyCode === 13 || event.keyCode === 32) {
            setOrientationValue(parseInt(AvgAxis3));
        }
    }

    window.onkeydown = function(event){
        //Change orientation value on arrows press
        if(document.getElementById("orientationRange") !== document.activeElement){
            if (event.keyCode === 39) {
                setOrientationValue(parseInt(orientationValue)+1);
            }
            if (event.keyCode === 37) {
                setOrientationValue(parseInt(orientationValue)-1);
            }
        }
    }
        

    if(showResult){
        return (
        <div>

            <SaveResult show={saveResultWindow} setShow={showSaveResultWindow}
            resultJSON={resultJSON}/>
            <Downloading showDownloading={showDownloading}/>

            <div id="divToPrint" className='result'>

                <div className='overlay' onClick={() => setShowResult(false)}>
                    Result
                </div>

                <Container className='resultContent styledBox'>

                    <div className='resultLoadingDiv'
                    style={{display: loading? null : 'none'}}>
                        <LoadingDots/>
                    </div>
                    
                    <Row style={{opacity: loading? '0': null}}>
                        <Col className='resultTitle resultCol resumeShadow'>
                            <Row>
                                <Col>
                                    <span><IoEyeOutline/> Result</span>
                                </Col>
                            </Row>
                            <Row className='resultResume'>
                                {inputData}
                            </Row>
                        </Col>
                    </Row>

                    <Row className='separator' style={{opacity: loading? '0': null}}/>

                    <Row style={{opacity: loading? '0': null}} id='result-first'>
                        <Col xs={12} sm className='resumeShadow resultCol text-start'>
                            <Row className="spansRow">
                                <span className='resultSubtitle'>Meridional Analysis</span>
                                <span>
                                    IOL/Cornea Cyl. Ratio: <i>{Result1}</i>
                                    <br></br>  
                                    <span>Required Cyl. at IOL Plane: <i>{Result2}</i></span>
                                </span>
                            </Row>
                        </Col>
                        <Col xs={12} sm className='resumeShadow resultCol text-start'>
                            <Row className="spansRow">
                                <span className='resultSubtitle'>Implanted IOL Cylinder</span>
                                <span>
                                    At IOL Plane: <i>{parseFloat(Result3).toFixed(2)}</i>
                                    <br></br>  
                                    At Corneal Plane: <i>{parseFloat(Result4).toFixed(2)}</i>
                                </span>
                            </Row>
                        </Col>
                        <Col xs={12} sm className='resumeShadow resultCol text-start'>
                            <Row className="spansRow">
                                <span className='resultSubtitle'>Induced Corneal Astigmatism</span>
                                <span>
                                    <b>TCA 1:&nbsp;</b>
                                    <span style={{display: (Result5 === "NaN" || !Result5)? 'none' : null}}>
                                        &nbsp;Cyl: <i>{Result5}</i> &nbsp; Axis: <i>{Result6}°</i>
                                    </span>
                                    <i className='noMeasurements' style={{display: (Result5 !== "NaN" && Result5)? 'none' : null}}>
                                        No Measurements
                                    </i> 
                                    <br></br>
                                    <b>TCA 2:&nbsp;</b>
                                    <span style={{display: ((Result7 === "NaN") || !Result7)? 'none' : null}}>
                                        &nbsp;Cyl: <i>{Result7}</i> &nbsp; Axis: <i>{Result8}°</i>
                                    </span>
                                    <i className='noMeasurements' style={{display: (Result7 !== "NaN" && Result7)? 'none' : null}}>
                                        No Measurements
                                    </i> 
                                </span>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='separator' style={{opacity: loading? '0': null}}/>

                    <Row style={{opacity: loading? '0': null}}>
                        <Col  xs={12} sm className='resumeShadow resultCol vCenter'>
                            <Row className="spansRow">
                                <span className='resultSubtitle'>IOL Orientation</span>
                            </Row>
                            <Row>
                                <div className='eyeGraphic'>
                                    <img src={graphicCircle} id='graphicCircle' alt='graphicCircle'></img>
                                    <img src={graphicIOL} id='graphicIOL' alt='graphicIOL'
                                    style={{transform: 'rotate('+ (90-orientationValue) +'deg)'}}></img>
                                    <img src={graphicSuggested} id='graphicSuggested' alt='graphicSuggested'
                                    style={{transform: 'rotate('+ (90-AvgAxis3) +'deg)'}}></img>
                                </div>
                            </Row>
                            <Row className='resultOrientationRow'>
                                <span id='orientationSpan'>{orientationValue}°</span>
                                <span className='hint2 d-none d-sm-inline'><FaRegQuestionCircle/>
                                    <span className='hintText hintText2'>
                                        Use <b>keyboard arrows</b> to modify IOL axis precisely.
                                        <br></br>
                                        Reset pressing <b>Intro/Space Bar</b>.
                                    </span>
                                </span>

                                <div className='rangeDiv noPadding'
                                style={{display: (hideToDownload)? 'none' : null}}>
                                    <Form.Range value={orientationValue}
                                    id="orientationRange"
                                    min="0" max="180" 
                                    onChange={(e) => {
                                        setOrientationValue(e.target.value);
                                    }}>    
                                    </Form.Range>
                                    <BsArrowLeft id='rangeArrowL' onClick={() => setOrientationValue(parseInt(orientationValue)-1)}/>
                                    <BsArrowRight id='rangeArrowR' onClick={() => setOrientationValue(parseInt(orientationValue)+1)}/>
                                </div>

                            </Row>
                            <Row className="spansRow"
                            style={{display: (hideToDownload)? 'none' : null}}>
                                <span className='orientationSubtitle'>(Change IOL axis to see predicted refraction changes)</span>
                            </Row>
                        </Col>
                        <Col xs={12} sm className='xs-nopadding'>
                            <Row className='resumeShadow resultCol'>
                                <Row className="spansRow text-start xs-nopadding">
                                    <span className='resultSubtitle text-center'>IOL Alignment</span>
                                    <b className='span2' style={{position: 'relative'}}>According to Corneal Measurements 1:
                                        <span className='hint d-none d-lg-inline'><FaRegQuestionCircle/>
                                            <span className='hintText'>Change IOL orientation to see expected refraction.</span>
                                        </span>
                                    </b>
                                    <span>
                                        <span>Suggested Axis:&nbsp;<i>{AvgAxis3}°</i></span>
                                        <br></br>
                                        <span className='resultPRR'>Predicted residual refraction:</span> <i>{Result101}</i><i>{Result102}</i><i>{Result103}°</i>
                                    </span>
                                </Row>
                                <Row className="spansRow xs-nopadding text-start">
                                    <b className='span2'>According to Corneal Measurements 2:</b>
                                    <span>
                                        <span>Suggested Axis:&nbsp; 
                                        <i style={{display: (AvgAxis4 === "" || Number.isNaN(parseFloat(AvgAxis4)))? 'none' : null}}>{AvgAxis4}°</i>
                                        <i className='noMeasurements' style={{display: (AvgAxis4 !== "" && !Number.isNaN(parseFloat(AvgAxis4)))? 'none' : null}}>
                                            No Measurements
                                        </i>
                                        </span>
                                        <br></br>  
                                        <span className='resultPRR'>Predicted residual refraction:</span>
                                        <span style={{display: (Result121 === "NaN")? 'none' : null}}>
                                            <i>{Result121}</i><i>{Result122}</i><i>{Result123}°</i>&nbsp;
                                        </span>
                                        <i className='noMeasurements' style={{display: (Result121 !== "NaN")? 'none' : null}}>
                                            No Measurements
                                        </i>
                                    </span>
                                </Row>
                            </Row>
                            <Row>
                                <div className='optionsDiv'
                                style={{display: (hideToDownload)? 'none' : null}}>
                                    <span onClick={() => downloadPdf()}><AiOutlineFilePdf/> <br className='d-sm-none'></br>Download</span>
                                    <span onClick={() => saveResult(true)}><FaUserMd/> <br className='d-sm-none'></br>Save Result</span>
                                    <span onClick={() => modifyInput(fixedResult)}><RiArrowGoBackLine/> <br className='d-sm-none'></br>Modify Input</span>
                                    {/*<span><FaRegFlag/> Report Error</span>*/}
                                </div>
                                <div className='identifiersDiv'
                                style={{display: (!hideToDownload)? 'none' : null}}>
                                    <span>Patient ID: {Patient}</span>
                                    <span>Surgeon ID: {Surgeon}</span>
                                </div>
                            </Row>
                        </Col>
                    </Row>

                    <div className='resumeCloseBtn d-none d-sm-block' 
                    onClick={() => setShowResult(false)}
                    style={{opacity: loading? '0': null}}>
                        <AiOutlineCloseCircle/>
                    </div>

                </Container>
            </div>
        </div>
        )
    }
}
