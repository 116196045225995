import React, { useState } from 'react'

export default function OptionsPopup({showPopUp, setShowPopup, OnAccept, OnCancel, Icon, Title, Message}) {

    if(showPopUp){
        return(
            <div className='resetPopup' id='resetPopup'>
                <div className='overlay'></div>
                
                <div className='popupContent styledBox'>
                    <div className='popupTitle'>
                        {Icon}
                        &nbsp;{Title}
                    </div>
                    <div className='popupMessage'>
                        <span>
                            {Message}
                        </span>
                    </div>
                    <div className='resetButtons'>
                        <button className='resetPopupBtn popupBtn'
                        onClick={(OnCancel === undefined)?() => setShowPopup(false) : OnCancel}>
                            Cancel
                        </button>
                        <button className='resetPopupBtn popupBtn' id='acceptBtn'
                        onClick={OnAccept}>
                            Accept
                        </button>
                    </div>                    
                </div>
            </div>
        )
    }
}
