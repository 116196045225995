import React, {useEffect, useState, useContext} from 'react'
import './saveResult.css'
import './popup.css'

import { UserContext } from '../UserContext'
import { sendNewResult } from '../services/UserResults'
import { setCursorLoading, setCursorNormal } from '../services/StyleUtilites'

import LoadingDots from './LoadingDots'
import {AiOutlineSave} from 'react-icons/ai';
import { Form } from 'react-bootstrap'
import Popup from './Popup'

export default function SaveResult({show, setShow, resultJSON}) {

  const [showPopUp, setShowPopUp] = useState(false);

  const [stage, setStage] = useState(-1);
  const user = useContext(UserContext)[1];

  //Inputs for saving
  const [patientID, setPatientID] = useState("")
  const [patientGender, setPatientGender] = useState("")
  const [patientIncisionSize, setPatientIncisionSize] = useState("")
  const [patientIncisionLocation, setPatientIncisionLocation] = useState("")
  const [patientIncisionAxis, setPatientIncisionAxis] = useState("")

  async function saveNewResult(){
    setCursorLoading('saveResult', 'saveResultBtn');
    if(patientID.length < 20 && patientGender !== ''){
      
      resultJSON["patientID"] = patientID;
      resultJSON["gender"] = patientGender;
      resultJSON["visible"] = true;
      if(resultJSON.iolToricity !== -1){
        resultJSON["incision"] = [patientIncisionSize, 
          patientIncisionLocation, patientIncisionAxis];
      }

      if(resultJSON.iolToricity !== -1){
        if(patientIncisionSize !== "" && 
          patientIncisionLocation !== ""){
            var result = await sendNewResult(resultJSON);
            if(result){
              setShowPopUp(true);
            }
          }else{
            window.alert('Please enter correct information.')
          }
      }else{
          var result = await sendNewResult(resultJSON);
          if(result){
            setShowPopUp(true);
          }
      }
    }else{
      window.alert('Please enter correct information.')
    }
    setCursorNormal('saveResult', 'saveResultBtn');
  }

  //Trigger when window is popped out
  useEffect( () => {
    if(show){
      if(user === ""){
        setStage(0);
      }else{
        setStage(1);
      }
    }
  },[show]) 

  if(show){
    return (
      <div className='overlay popup' id='saveResult'
      style={{zIndex: '3'}}>
          <div className='popupContent styledBox'>

            <div className='saveTitle'>
              <AiOutlineSave style={{marginTop: '1vh'}}/>
              &nbsp;Save Result
            </div>

            <div className='hCenterFlex'
            style= {{display: (stage === -1)? null : 'none'}}>
              <LoadingDots/>
            </div>

            <div className='saveMsg'
            style= {{display: (stage === 0)? null : 'none'}}>
              <span>
                Error: You must be logged in to save results.<br></br>
                Join Toric Aligner and refresh this window to be able to store data in your account!
              </span>
                <div className='resetButtons'>
                    <button className='resetPopupBtn popupBtn'
                    onClick={() => setShow(false)}>
                        Cancel
                    </button>
                    <button className='resetPopupBtn popupBtn greenBtn'
                    onClick={() => window.open('/login', '_blank')}>
                        <b>Log In</b>
                    </button>
                </div>  
            </div>

            <div className='saveMsg'
            style= {{display: (stage === 1)? null : 'none'}}>
              <span>
                This result will be saved into your account ({user}).
                <div className='saveForm'>
                  <span>Enter the information and click save to confirm.</span>
                  <Form.Control
                  type="text" value={patientID} 
                  placeholder={'Patient Initials (or any ficticious identifier)'} className='resetPasswordInput'
                  onChange={(e) => 
                      {      
                          setPatientID(e.target.value);
                      }}
                  />
                  <Form.Select value={patientGender} 
                  className='saveResultInput'
                  onChange={(e) => 
                      {      
                          setPatientGender(e.target.value);
                      }}
                  >
                    <option value='' style={{display: 'none', color: '#062e5b'}}>Patient Gender</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                  </Form.Select>
                  <Form.Control
                  type="number" value={patientIncisionSize} 
                  placeholder={'Incision Size'} className='saveResultInput'
                  style={{display: (resultJSON.iolToricity !== -1)? null : 'none'}}
                  onChange={(e) => 
                      {      
                          setPatientIncisionSize(e.target.value);
                      }}
                  />
                  <Form.Select value={patientIncisionLocation}
                  style={{display: (resultJSON.iolToricity !== -1)? null : 'none'}}
                  className='saveResultInput'
                  onChange={(e) => 
                      {      
                          setPatientIncisionLocation(e.target.value);
                      }}
                  >
                    <option value='' style={{display: 'none', color: '#062e5b'}}>Incision Location</option>
                    <option value='Superior'>Superior</option>
                    <option value='Temporal'>Temporal</option>
                    <option value='Other'>Other</option>
                  </Form.Select>
                  <Form.Control
                  type="number" value={patientIncisionAxis} 
                  placeholder={'Incision Axis'} className='saveResultInput'
                  style={{display: (patientIncisionLocation === "Other" && resultJSON.iolToricity !== -1)? null : 'none'}}
                  onChange={(e) => 
                      {      
                          setPatientIncisionAxis(e.target.value);
                      }}
                  />
                </div>
              </span>
                <div className='resetButtons'>
                    <div className='vCenter resetPopupBtn popupBtn'
                    onClick={() => setShow(false)}>
                        Cancel
                    </div>
                    <div className='resetPopupBtn popupBtn greenBtn' id='saveResultBtn'
                    onClick={() => saveNewResult()}>
                        <b>Save Result</b>
                    </div>
                </div>  
            </div>
          </div>

          <Popup 
            showPopUp={showPopUp}
            onClick={() => {
              setShowPopUp(false);
              setShow(false);
            }}
            Icon={<AiOutlineSave style={{marginBottom: '0.65vh'}}/>}
            Title={"Success"}
            Body={
                <span> 
                    Your result has been stored successfully!<br></br>
                    You can watch your stored results in your user page.
                </span>}
            />
      </div>
    )
  }
}
