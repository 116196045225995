import { useState } from "react";

export function setCursorLoading(mainDiv, btnDiv){
    document.getElementById(mainDiv).style.cursor = 'progress';
    document.getElementById(btnDiv).style.cursor = 'none';
    document.getElementById(btnDiv).style.cursor = 'progress';
    document.getElementById(btnDiv).disabled = true;
}

export function setCursorNormal(mainDiv, btnDiv){
    document.getElementById(mainDiv).style.cursor = 'auto';
    document.getElementById(btnDiv).style.cursor = 'pointer';
    document.getElementById(btnDiv).disabled = false;
}

export function deleteById(divId){
    document.getElementById(divId).style.display = "none";
}

export function getViewport() {
    var viewPortWidth;
    var viewPortHeight;
   
    // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
    if (typeof window.innerWidth != 'undefined') {
      viewPortWidth = window.innerWidth
      viewPortHeight = window.innerHeight
    }
   // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
    else if (typeof document.documentElement !== 'undefined'
    && typeof document.documentElement.clientWidth !==
    'undefined' && document.documentElement.clientWidth !== 0) {
       viewPortWidth = document.documentElement.clientWidth
       viewPortHeight = document.documentElement.clientHeight
    }
    // older versions of IE
    else {
      viewPortWidth = document.getElementsByTagName('body')[0].clientWidth
      viewPortHeight = document.getElementsByTagName('body')[0].clientHeight
    }
    return [viewPortWidth, viewPortHeight];
}