export class SICACalculation{
    constructor(patient, age, gender, IOL, preopTCA, postopTCA, incSize, incLocation, incAxis, tca1, tca2) {
        this.patient = patient;
        this.age = age;
        this.gender = gender;
        this.IOL = IOL;
        this.preopTCA = preopTCA;
        this.postopTCA = postopTCA;
        this.incSize = incSize;
        this.incLocation = incLocation;
        this.incAxis = incAxis;
        this.tca1 = tca1;
        this.tca2 = tca2;
        this.SICACylinder = undefined;
        this.SICAAxis = undefined;
      }
}