import React, {useState, useEffect, useContext} from 'react'
import './resultsTable.css';
import {Table, Container, Row} from 'react-bootstrap';

import { UserContext } from '../UserContext'

import { VscOpenPreview, VscTrash } from 'react-icons/vsc'

export default function ResultsTable({setShowStandardResult, setShowPiggybackResult, setShowSIAResult,
    setFixedResult, setShowDeletePopup, setDeleteID, resultEntries}){

    function openResult(result){
        setFixedResult(result);
        if (result.iolType === 'Standard'){
            setShowStandardResult(true);
        }else if(result.iolModel === '-'){
            setShowSIAResult(true);
        }else{
            setShowPiggybackResult(true);
        }
    }

     return (
        <Container className='tableContainer'>
            <Row className='tableFixed'>
                <Table striped hover className='userResultsTable'>
                    <thead className='tableHead'>
                        <tr>
                            <th>Patient</th>
                            <th>Gender</th>
                            <th>Date</th>
                            <th>IOL Type</th>
                            <th>IOL Model</th>
                            <th>IOL Toricity</th>
                            <th>TCA</th>
                            <th>SIA</th>
                            <th>Incision</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {resultEntries.map((result) => (
                                <tr>
                                    <td>{result.patientID}</td>
                                    <td>{result.gender}</td>
                                    <td>{result.date}</td>
                                    <td>{result.iolType}</td>
                                    <td>{result.iolModel}</td>
                                    <td>{(result.iolToricity !== -1)?result.iolToricity:"-"}</td>
                                    <td>
                                        {(result.tca[0] !== "" && result.tca[1] !== "NaN" && result.tca[0] !== "-1")?
                                        parseFloat(result.tca[0]).toFixed(2)+" @ "+Math.round(result.tca[1])+"°":
                                        "no record"}
                                    </td>
                                    <td>
                                        {result.sia[0]+" @ "+result.sia[1]+"°"} 
                                    </td>
                                    <td>
                                        {(result.incision === null)?'-':result.incision[1]}
                                    </td>
                                    <td className='textCenter'>
                                        <VscOpenPreview className='openResultIcon'
                                        onClick={() => openResult(result)}/>
                                        &nbsp;&nbsp;
                                        <VscTrash className='openResultIcon'
                                        onClick={() => {
                                            setShowDeletePopup(true);
                                            setDeleteID(result.id);
                                        }}/>
                                    </td>
                                </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
        </Container>
    )
}
