import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import octicon from '../img/oct-sia.png';

import { SICACalculation } from './SICACalculation'
import { TCA } from '../common/TCA'

import CalculateBtn from '../sia_calculator/CalculateBtn'
import AddPatientBtn from '../sia_calculator/AddPatientBtn'
import TextInput from '../common/inputs/TextInput';
import NumInput from '../common/inputs/NumInput';
import SelectInput from '../common/inputs/SelectInput';
import TCAMeasures from '../common/inputs/TCAMeasures';

export default function Input({
    showResult, setShowResult,
    SIACalculations, setSIACalculations,

    F11VS, setF11VS,
    F12VS, setF12VS,
    F13VS, setF13VS,
    F14VS, setF14VS,
    F21VS, setF21VS,
    F22VS, setF22VS,
    F23VS, setF23VS,
    F24VS, setF24VS,
    F25VS, setF25VS,
    F26VS, setF26VS,
    F31VS, setF31VS,
    F32VS, setF32VS,
    F33VS, setF33VS,
    F34VS, setF34VS,
    F35VS, setF35VS,
    F36VS, setF36VS,
    F41VS, setF41VS,
    F42VS, setF42VS,
    F43VS, setF43VS,
}) {
    const [validInput, setValidInput] = useState(false);
    const [validPreopTCA, setValidPreopTCA] = useState(false);
    const [validPostopTCA, setValidPostopTCA] = useState(false);
    useEffect(() => {  
        setValidInput(false);
        if(
            F11VS[1] === 1 &&
            F12VS[1] === 1 &&
            F13VS[1] === 1 &&
            F14VS[1] === 1 &&
            validPreopTCA &&
            validPostopTCA &&
            F41VS[1] === 1 &&
            F42VS[1] === 1
        ){  
            if(F42VS[0] === 'Other'){
                if(F43VS[1] === 1){
                    setValidInput(true);
                }else{
                    setValidInput(false);
                }
            }else{
                setValidInput(true);
            }
        }

    }, [F11VS[1], F12VS[1], F13VS[1], validPreopTCA, validPostopTCA, F41VS[1], F42VS, F43VS[1]])
    
    function resetForm(){
        setF11VS(["",-1]);
        setF12VS(["",-1]);
        setF13VS(["Select Gender",-1]);
        setF14VS(["Select implanted lens",-1]);

        setF21VS(["",-1]);
        setF22VS(["",-1]);
        setF23VS(["",-1]);
        setF24VS(["",-1]);
        setF25VS(["",-1]);
        setF26VS(["",-1]);

        setF31VS(["",-1]);
        setF32VS(["",-1]);
        setF33VS(["",-1]);
        setF34VS(["",-1]);
        setF35VS(["",-1]);
        setF36VS(["",-1]);

        setF41VS(["",-1]);
        setF42VS(["Select incision location",-1]);
        setF43VS(["",-1]);
    }

    function addNewPatient(){
        if(SIACalculations.length < 5){
            resetForm();
            let preopTCA = new TCA(F21VS[0], F22VS[0], F23VS[0], F24VS[0], F25VS[0], F26VS[0]);
            let postopTCA = new TCA(F31VS[0], F32VS[0], F33VS[0], F34VS[0], F35VS[0], F36VS[0]);
            let SicaCalculation = new SICACalculation(
                F11VS[0], F12VS[0], F13VS[0], F14VS[0],
                preopTCA, postopTCA,
                F41VS[0], F42VS[0], F43VS[0]
            );
            let SiaCalculations = SIACalculations;
            SiaCalculations.push(SicaCalculation);
            setSIACalculations(SiaCalculations);
        }else{
            window.alert("Error: You can add up to 5 patients per calculation.")
        }
    }

    return (
    <div className='input' id='sia-input'>
        <Row className='title vCenter' id='sia-title'>
            <h1>SIA Calculator</h1>
        </Row>
        <Container className='hCenter w-75' id='sia-container'>
            <Row>
                <Col xs={12} sm={9}>
                    <Row className='styledBox'>
                        <Col xs={12} sm={3} className='formIconDiv hCenterFlex' id='box-icon' 
                        style={{marginLeft: 0}}>
                            <Row style={{marginBottom: '0.4vh'}} id='box-icon-title'>
                            Patient <br></br>Measurements
                            </Row>
                            <Row>
                                <img alt='octicon' src={octicon} className='formIcon patient'/>
                            </Row>
                        </Col>
                        <Col xs={12} sm={9} className='hCenter' id='sia-data'>
                            <Row className='sia-input-section'>
                                <Col className='xs-nopadding'>
                                    <Row><b className='sm-nopadding title2'>Patient Data:</b></Row>
                                    <Row className='formInputRow xs-nogutter'>
                                        <Col xs={7} className='noPadding'>
                                            <TextInput label={"Name:"} VS={F11VS} setVS={setF11VS}/>
                                        </Col>
                                        <Col xs={5} className='noPadding'>
                                            <NumInput VS={F12VS} setVS={setF12VS}
                                                label="&nbsp;Age:"
                                                placeholder="Enter Age"
                                                min={1}
                                                max={120}
                                                readonly={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: '1vh'}} className='xs-nogutter'>
                                        <Col xs={12} sm={4} className='noPadding'>
                                            <SelectInput VS={F13VS} setVS={setF13VS}
                                                label="Gender:"
                                                placeholder={"Select Gender"}
                                                options={["Male","Female"]}
                                            />
                                        </Col>
                                        <Col xs={12} sm={8} className='noPadding'>
                                            <SelectInput VS={F14VS} setVS={setF14VS}
                                                label="&nbsp;Implanted IOL:"
                                                placeholder={"Select implanted lens"}
                                                options={["Standard Toric","Piggyback Toric","STAAR ICL",
                                                "Eyecryl Phakic IOL", "Artiflex", "Artisan"]}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className='separator'></div>
                            <Row className='sia-input-section'>
                                <Col className='xs-nogutter'>
                                    <Row id='sia-tca-row'>
                                        <Col xs={12} sm={6}>
                                            <TCAMeasures
                                                states={[F21VS, F22VS, F23VS, F24VS, F25VS, F26VS]}
                                                setStates={
                                                [setF21VS, setF22VS, setF23VS, setF24VS, setF25VS, setF26VS]}
                                                hint
                                                title={"Preoperative Measured TCA"}
                                                setValid={setValidPreopTCA}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <TCAMeasures
                                                states={[F31VS, F32VS, F33VS, F34VS, F35VS, F36VS]}
                                                setStates={
                                                [setF31VS, setF32VS, setF33VS, setF34VS, setF35VS, setF36VS]}
                                                title={"Postoperative Measured TCA"}
                                                setValid={setValidPostopTCA}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className='separator'></div>
                            <Row className='sia-input-section'>
                                <Col xs={12}>
                                    <Row><b className='noPadding title2'>Incision Data:</b></Row>
                                    <Row>
                                        <Col xs={12} sm={3} className='noPadding'>
                                            <NumInput VS={F41VS} setVS={setF41VS}
                                                label="Size:"
                                                placeholder="Size"
                                                min={1.2}
                                                max={7}
                                                readonly={false}
                                            />
                                        </Col>
                                        <Col className='xs-nopadding' style={{paddingRight: '0px'}}>
                                            <SelectInput
                                                VS={F42VS} setVS={setF42VS}
                                                label="Location:"
                                                placeholder={"Select incision location"}
                                                options={["Superior","Temporal","Other"]}
                                            />
                                        </Col>
                                        <Col xs={12} sm={2} className='noPadding'
                                        style={{display: (F42VS[0] === 'Other')?null:'none'}}>
                                            <NumInput VS={F43VS} setVS={setF43VS}
                                                label="@"
                                                placeholder="Axis"
                                                min={0}
                                                max={180}
                                                readonly={false}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={3} className='vCenter' id='sia-calculate-col'>
                    <Row>
                        <AddPatientBtn
                        onClick={() => addNewPatient()}
                        addedPatients={SIACalculations.length}
                        validInput={validInput}/>
                    </Row>
                    <Row>
                        <CalculateBtn
                        onClick={() => {
                            setShowResult(true);
                        }}
                        showResult={showResult}
                        addedPatients={SIACalculations.length}/>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
    )
}
