import React, {useState, useEffect} from 'react'
import {Form, Row, Col} from 'react-bootstrap';

export default function TextInput({VS, setVS, label}) {

    const [changed, setChanged] = useState(false);
    const [Class, setClass] = useState('formControl nameInput controlNeutral')

    useEffect(() => {
        //Check validity and set class.
        if(VS[0] === ""){
            setClass('formControl nameInput controlNeutral')
        }
        else if(VS[0].length < 1){
            setVS([VS[0], 0]);
            setClass('formControl controlInvalid nameInput');
        }else{
            setVS([VS[0], 1]);
            setClass('formControl controlValid nameInput');
        }
    },[VS[0]]);

    return (
        <Form onSubmit={e => { e.preventDefault(); }}>
            <Form.Group className='inputDiv patientNameDiv'>
                    <span className='controlLabel'>{(label)?label:"Patient:"}&nbsp;&nbsp;</span>
                    <Form.Control 
                        type="text" placeholder='Enter patient name / ID'
                        className={Class} value={VS[0]}
                        onChange={
                            (e) => 
                                {
                                setChanged(true);
                                //Update value State
                                setVS([e.target.value, 0]);
                            }
                        }
                    />
            </Form.Group>
        </Form>
    )
}
