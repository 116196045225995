import React, {useState, useEffect} from 'react'
import {Col, Row} from 'react-bootstrap'
import './tcaMeasures.css'

import NumInput from './NumInput';

import { FaRegQuestionCircle } from 'react-icons/fa';
import {IoAddCircleOutline, IoCloseCircleOutline} from 'react-icons/io5';

export default function TCAMeasures({states, setStates, title, hint, setValid}) {

    //Switch for measure numbers
    //const [octSwitchClass, setOctSwitchClass] = useState('octSwitchOff vCenter');
    const [measure2Switch, setMeasure2Switch] = useState(false);
    const [measure3Switch, setMeasure3Switch] = useState(false);

    /*function handleClick(){
        setOctSwitchClass('octSwitchOff vCenter');
        setTimeout( () => deleteById('octSwitch2') , 200 );
    }*/

    //Average calculation is missing

    useEffect(() => {
        setValid(false);

        if(!measure2Switch){
            if (states[0][1] == 1 && states[1][1] == 1){
                setValid(true);
            }
        }else{
            if(!measure3Switch){
                if(
                states[0][1] == 1 && states[1][1] == 1 &&
                states[2][1] == 1 && states[3][1] == 1 ){
                    setValid(true);
                }
            }else{
                if(
                states[0][1] == 1 && states[1][1] == 1 &&
                states[2][1] == 1 && states[3][1] == 1 &&
                states[4][1] == 1 && states[5][1] == 1 ){
                    setValid(true);
                }
            }
        }
    }, [states, measure2Switch, measure3Switch])

    function addMoreMeasures(){
        if(!measure2Switch){
            setMeasure2Switch(true);
        }else{
            setMeasure3Switch(true);
        }
    }

    function deleteMeasure(){
        if(measure3Switch){
            setMeasure3Switch(false);
            setStates[4](["",-1])
            setStates[5](["",-1])
        }else if(measure2Switch){
            setMeasure2Switch(false);
            setStates[2](["",-1])
            setStates[3](["",-1])
        }{/*else{
            document.getElementById("octSwitch2").style.display = null;
            setOctSwitchClass('octSwitchOn vCenter');
            setStates[0](["",-1])
            setStates[1](["",-1])
        }*/}
    }

    //OCT Displaying for user loaded results
    function displayMeasurements(){
        if(states[4][0] !== "" && states[4][0] !== "NaN"){
            setMeasure2Switch(true);
            setMeasure3Switch(true);
        }else if(states[2][0] !== "" && states[2][0] !== "NaN"){
            setMeasure2Switch(true);
            setMeasure3Switch(false);
        }
    }
    useEffect(() => {
        if(states[0][0] !== ""){
            displayMeasurements();
        }
    },[states[0][0]])

    return (
        <div className='vCenter formCol'>

                {/*<div id='octSwitch2' className={octSwitchClass} 
                    onClick={() => handleClick()}
                    >
                        <span className='hCenter'>
                            <IoAddCircleOutline style={{fontSize: '3vw'}}/>
                            <br></br>
                            Add more measurements
                            <div style={{marginTop: '-0.5vh', fontSize: '0.8vw'}}>(Optional, for SICA calculation)</div>
                        </span>
                </div>*/}

                <Row className='tca-title'>
                    <Col className='noPadding'>
                        <span className='title2'>{title}</span>

                        <span className='tca-hint d-none d-sm-inline' style={{display: (hint)? null : 'none'}}>
                            &nbsp;<FaRegQuestionCircle/>
                            <span className='tca-hint-text'>
                            Enter up to 3 corneal measurements. 
                            <br></br>Suggested devices: IOL master 700 (TK),
                            <br></br>Scheimpflug or OCT.
                            <br></br>(TCA = Total Corneal Astigmatism)
                            </span>
                        </span>
                    </Col>
                </Row>

                <Row className='hCenterRow'>
                    <Col xs={3} className='vCenter measureCol noPadding'>
                        TCA 1:&nbsp;
                    </Col>
                    <Col xs={5} className='noPadding'>
                        <NumInput VS={states[0]} setVS={setStates[0]}
                            label=""
                            placeholder="Magnitude"
                            min={0}
                            max={20}
                            step={0.1}
                            readonly={false}
                            onlyMinus={false}
                        />
                    </Col>
                    <Col xs={3} className='noPadding'>
                        <NumInput VS={states[1]} setVS={setStates[1]}
                            label=""
                            placeholder="Axis"
                            min={1}
                            max={180}
                            step={0.1}
                            readonly={false}
                            onlyMinus={false}
                        />
                    </Col>

                    {/*<span className='deleteMeasureSpan4'
                    style={{display: (!measure2Switch)? null : 'none'}}>
                        <IoCloseCircleOutline id='deleteButton' onClick={() => deleteMeasure()}/>
                    </span>*/}
                </Row>

                <Row className='hCenterRow' style={{display: measure2Switch ? null : 'none'}}>
                    <Col xs={3} className='vCenter measureCol noPadding'>
                        TCA 2:&nbsp;
                    </Col>
                    <Col xs={5} className='noPadding'>
                        <NumInput VS={states[2]} setVS={setStates[2]}
                            label=""
                            placeholder="Magnitude"
                            min={0}
                            max={20}
                            step={0.1}
                            readonly={false}
                            onlyMinus={false}
                        />
                    </Col>
                    <Col xs={3} className='noPadding'>
                        <NumInput VS={states[3]} setVS={setStates[3]}
                            label=""
                            placeholder="Axis"
                            min={1}
                            max={180}
                            step={0.1}
                            readonly={false}
                            onlyMinus={false}
                        />
                    </Col>

                    <span className='tca-delete-measure-span'
                    style={{display: (measure2Switch&&!measure3Switch)? null : 'none'}}>
                        <IoCloseCircleOutline id='deleteButton' onClick={() => deleteMeasure()}/>
                    </span>
                </Row>

                <Row className='hCenterRow' style={{display: measure3Switch ? null : 'none'}}>
                    <Col xs={3} className='vCenter measureCol noPadding'>
                        TCA 3:&nbsp;
                    </Col>
                    <Col xs={5} className='noPadding'>
                        <NumInput VS={states[4]} setVS={setStates[4]}
                            label=""
                            placeholder="Magnitude"
                            min={0}
                            max={20}
                            step={0.1}
                            readonly={false}
                            onlyMinus={false}
                        />
                    </Col>
                    <Col xs={3} className='noPadding'>
                        <NumInput VS={states[5]} setVS={setStates[5]}
                            label=""
                            placeholder="Axis"
                            min={1}
                            max={180}
                            step={0.1}
                            readonly={false}
                            onlyMinus={false}
                        />
                    </Col>

                    <span className='tca-delete-measure-span'
                    style={{display: (measure3Switch)? null : 'none'}}>
                        <IoCloseCircleOutline id='deleteButton' onClick={() => deleteMeasure()}/>
                    </span>
                </Row>

                <Row className='addMeasureRow' style={{display: measure3Switch? 'none' : null}}>
                    <span onClick={() => addMoreMeasures()}>
                        Add more&nbsp;<IoAddCircleOutline/>
                    </span>
                </Row>

                {/*<Row xs={12} className='averagesCol' style={{display: measure2Switch? null : 'none'}}>
                    <span className='averagesCol'>
                        Average Magnitude: {F87Val}
                        &nbsp;&nbsp;
                        Average Axis: {F88Val}        
                    </span>           
                </Row>*/}

            </div>
    )
}
