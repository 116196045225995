// ENABLE USERS //
export const USERS_ENABLED = true;
// SERVER ADDRESS //
let local = false;
export let server_url;
if(local){
    server_url = "http://localhost:8080";
}else{
    server_url = "https://toricaligner.com:8443";
}
