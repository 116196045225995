import axios from 'axios';
import {server_url} from '../Settings'
import { setCursorLoading, setCursorNormal } from './StyleUtilites';
axios.defaults.withCredentials = true;

const fetchOptions = {
    withCredentials: true,
    headers: {
        "Access-Control-Allow-Origin": true,
    }
}

export function sendNewResult(resultJSON){
    return new Promise( resolve => {
        axios.post(server_url + "/results/store", resultJSON)
        .then(resp => {
            resolve(true);
        })
        .catch(function (error){
            resolve(false);
            window.alert(error)
        })
    })
}

export function getResults(userID){
    return new Promise( resolve => {
        axios.get(server_url + "/results/get?userID=" + userID)
        .then(resp => {
            resolve(resp.data);
        })
        .catch(function (error){
            resolve(false);
        })
    })
}

export function deleteResult(resultID){
    return new Promise( resolve => {
        axios.delete(server_url + "/results/delete?id=" + resultID)
        .then(resp => {
            resolve(resp.data);
        })
        .catch(function (error){
            resolve(false);
        })
    })
}