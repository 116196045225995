import React from 'react'
import toricLogo from './img/toric-logo.png'
import { FaWrench } from 'react-icons/fa'

export default function Maintenance() {
  return (
    <div className='disclaimerOn vCenter'>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <img
                src={toricLogo}
                alt='toric-logo'
                id='toricLogo'
                style={{width: '20vw'}}
            ></img>
            <span style={{fontSize: '1.4vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px'}}>
                
                <span style={{fontSize: '1.4vw', display: 'flex', alignItems: 'center'}}>
                    <FaWrench/>&nbsp;This site is currently undergoing maintenance.
                </span>
                <span>
                    We are working to improve your experience.
                    Come back in a few minutes <br></br>for high accuracy IOL alignment.
                </span>
            </span>
        </div>
    </div>
  )
}
