import React, {useState, useEffect, useContext} from 'react'
import { Container, Row, Col } from 'react-bootstrap';

import { sendNewResult } from '../services/UserResults';
import { averageOf3, getTanDeg, getOctAverages} from '../services/ToricCalculation';
import { getViewport, setCursorLoading, setCursorNormal } from '../services/StyleUtilites';
import { UserContext } from '../UserContext'

import Popup from '../common/Popup';
import OptionsPopup from '../common/OptionsPopup'
import SaveResult from '../common/SaveResult';
import Downloading from '../common/Downloading';
import LoadingDots from '../common/LoadingDots';

import {AiOutlineCloseCircle, AiOutlineFilePdf} from 'react-icons/ai';
import {IoEyeOutline} from 'react-icons/io5';
import {FaUserMd} from 'react-icons/fa'
import {RiArrowGoBackLine} from 'react-icons/ri';
import { BiErrorCircle } from 'react-icons/bi';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { SICACalculation } from './SICACalculation';

export default function SIAResult(
    {showResult, setShowResult,
    fixedResult, SIACalculations,
    patientName,
    }
    ) {
    
    const user = useContext(UserContext)[1];
    const [popup, renderPopup] = useState(<div></div>)

    const [resultRender, setResultRender] = useState(<div></div>);
    const [Averages, setAverages] = useState([0, 0]);

    const [saveResultWindow, showSaveResultWindow] = useState(false);
    const [resultJSON, setResultJSON] = useState({});

    //const [SICACylinder, setSICACylinder] = useState("");
    //const [SICAAxis, setSICAAxis] = useState("");

    const [loading, setLoading] = useState(false);

    const [showDownloading, setShowDownloading] = useState(false);
    function downloadPdf(){

        var viewport = getViewport()
        if(viewport[0] > 576){
            setShowDownloading(true);
            //setHideToDownload(true);

            setTimeout(() => {
                var input = document.getElementById('divToPrint');
                //setHideToDownload(false);
                
                html2canvas(input)
                .then((canvas) => {
                const data = canvas.toDataURL('image/png');

                var orientation = 'l';
                var viewport = getViewport()
                if(viewport[0] < viewport[1]){
                    orientation = 'p'
                }

                const pdf = new jsPDF(orientation, 'px', [viewport[0], viewport[1]]);
                const imgProperties = pdf.getImageProperties(data);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width;


                pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('ToricAligner_' + "SICA_" + patientName + '.pdf');

                setShowDownloading(false);
                })
            }, 1000)
        }else{
            window.alert("Downloading is not available in mobile devices. If you want" +
            " to save your result as a .pdf file, please visit the website from a computer.")
        }

    }

    function modifyInput(result){
        if(!result){
            setShowResult(false);
        }else{
            let calculationObject = new SICACalculation(
                fixedResult.preopInput[0], fixedResult.preopInput[1], fixedResult.preopInput[2],
                fixedResult.preopInput[3], fixedResult.tca1, fixedResult.tca2,
                fixedResult.postopInput[0], fixedResult.postopInput[1], fixedResult.postopInput[2],
            );
            localStorage.setItem('calculationObject', JSON.stringify(calculationObject));
            window.location.replace('/SIA')
        }
    }

    function getCalculationJSON(calculation, visible){
        let tca = calculation.preopTCA;
        let calculationJSON = ({
            "visible": visible,
            "preopInput": [calculation.patient, calculation.age, 
            calculation.gender, calculation.IOL],
            "postopInput": [calculation.incSize, calculation.incLocation, 
            calculation.incAxis],
            "iolType": calculation.IOL,
            "iolModel": "-",
            "iolToricity": -1,
            "tca": getOctAverages(tca.magn1, tca.magn2, tca.magn3, tca.axis1, tca.axis2, tca.axis3),
            "sia": [calculation.SICACylinder.toString(), 
            calculation.SICAAxis.toString()],

            "meridionalAnalysis": null,
            "implantedIolCylinder": null,
            "sica1": [calculation.SICACylinder, calculation.SICAAxis],
            "sica2": null,
            "alignment1": null,
            "alignment2": null,
            "prrrecalculationAux": null
            });
            calculationJSON["patientID"] = calculation.patient;
            calculationJSON["gender"] = calculation.gender;
        return calculationJSON;
    }

    async function saveCalculations(calculations, visible){

        if(visible)setCursorLoading("siaResult", "acceptBtn");
        var status;
        for (let i = 0 ; i < calculations.length ; i++){
            let calculationJSON = getCalculationJSON(calculations[i], visible);
            status = await sendNewResult(calculationJSON);
        }
        if(status && visible){
            renderPopup(
                <Popup 
                showPopUp={true}
                onClick={() => {
                    renderPopup(<div></div>)
                }}
                Icon={<FaUserMd style={{marginBottom: '0.65vh'}}/>}
                Title={"Success!"}
                Body={
                    <span> 
                        Success, all the patients have been stored into your account.<br></br>
                        You can watch your stored results in your user page.
                    </span>
                }
                />
            )
        }
        if(visible)setCursorNormal("siaResult", "acceptBtn");
    }

    async function saveResult(show){
        let resultDTO;
        if(!fixedResult){
            if(SIACalculations.length === 1){
                let calculation = SIACalculations[0];
                let preopTCA = SIACalculations[0].preopTCA;
                let postopTCA = SIACalculations[0].postopTCA;
                resultDTO = ({
                    "visible": show,
                    "incision": [calculation.incSize, calculation.incLocation, calculation.incAxis],
                    "preopInput": [calculation.patient, calculation.age, 
                    calculation.gender, calculation.IOL],
                    "postopInput": [calculation.incSize, calculation.incLocation, 
                    calculation.incAxis],
                    "iolType": calculation.IOL,
                    "iolModel": "-",
                    "iolToricity": -1,
                    "tca": getOctAverages(preopTCA.magn1, preopTCA.magn2, preopTCA.magn3, preopTCA.axis1, preopTCA.axis2, preopTCA.axis3),
                    "tca1": [preopTCA.magn1,preopTCA.axis1, preopTCA.magn2,preopTCA.axis2, preopTCA.magn3,preopTCA.axis3],
                    "tca2": [postopTCA.magn1,postopTCA.axis1, postopTCA.magn2,postopTCA.axis2, postopTCA.magn3,postopTCA.axis3],
                    "sia": [calculation.SICACylinder.toString(), 
                    calculation.SICAAxis.toString()],
        
                    "meridionalAnalysis": null,
                    "implantedIolCylinder": null,
                    "sica1": [calculation.SICACylinder, calculation.SICAAxis],
                    "sica2": null,
                    "alignment1": null,
                    "alignment2": null,
                    "prrrecalculationAux": null
                    });
                setResultJSON(resultDTO);
                if(show){
                    showSaveResultWindow(true);
                }else{
                    var result = await sendNewResult(resultDTO);
                }
            }else{
                if(show){
                    if (user === ""){
                        renderPopup(
                                <Popup 
                                showPopUp={true}
                                onClick={() => {
                                    renderPopup(<div></div>)
                                }}
                                Icon={<BiErrorCircle style={{marginBottom: '0.65vh'}}/>}
                                Title={"User Error"}
                                Body={
                                    <span> 
                                        Error: You must be logged in to save results.<br></br>
                                        Join Toric Aligner and refresh this window to be able
                                        to store data in your account!
                                    </span>
                                }
                                />
                        )
                    }else{
                        if(show){
                            renderPopup(
                                <OptionsPopup
                                Title={"Save Results"}
                                Icon={<FaUserMd/>}
                                OnAccept={() => {
                                    saveCalculations(SIACalculations, true);
                                }}
                                OnCancel={() => renderPopup(<div></div>)}
                                Message={
                                <span>
                                    Are you sure you want to store this {SIACalculations.length}
                                    &nbsp;patients in @{user}´s profile?
                                </span>}
                                showPopUp={true}/>
                            )
                        }
                    }
                }else{
                    saveCalculations(SIACalculations, false);
                }
            }
        }
    }

    function loadFixedResult(result){
        setResultRender(
            <div className='sia-results'>
                <Row>
                    <span id='results'>Cylinder:<i>{parseFloat(fixedResult.sia[0]).toFixed(2)}</i>
                    &nbsp;&nbsp;Axis:<i>{parseInt(fixedResult.sia[1])}°</i></span>
                </Row>
            </div>
        )
    }

    function calculateSICA(SICACalculation){
        //rad
        let radA1 = Math.PI/180*SICACalculation.preopTCA.axis1;
        let radA2 = Math.PI/180*SICACalculation.preopTCA.axis2;
        let radA3 = Math.PI/180*SICACalculation.preopTCA.axis3;
        let radB1 = Math.PI/180*SICACalculation.postopTCA.axis1;
        let radB2 = Math.PI/180*SICACalculation.postopTCA.axis2;
        let radB3 = Math.PI/180*SICACalculation.postopTCA.axis3;
        
        //KP(Φ) 
        let KPA1 = SICACalculation.preopTCA.magn1 * Math.cos(2*radA1);
        let KPA2 = SICACalculation.preopTCA.magn2 * Math.cos(2*radA2);
        let KPA3 = SICACalculation.preopTCA.magn3 * Math.cos(2*radA3);
        let KPB1 = SICACalculation.postopTCA.magn1 * Math.cos(2*radB1);
        let KPB2 = SICACalculation.postopTCA.magn2 * Math.cos(2*radB2);
        let KPB3 = SICACalculation.postopTCA.magn3 * Math.cos(2*radB3);
        //KP(Φ+45) 
        let KPA451 = SICACalculation.preopTCA.magn1 * Math.sin(2*radA1);
        let KPA452 = SICACalculation.preopTCA.magn2 * Math.sin(2*radA2);
        let KPA453 = SICACalculation.preopTCA.magn3 * Math.sin(2*radA3);
        let KPB451 = SICACalculation.postopTCA.magn1 * Math.sin(2*radB1);
        let KPB452 = SICACalculation.postopTCA.magn2 * Math.sin(2*radB2);
        let KPB453 = SICACalculation.postopTCA.magn3 * Math.sin(2*radB3);

        //Average KP(Φ)
        let AvgKPA = averageOf3(KPA1,KPA2,KPA3);
        let AvgKPB = averageOf3(KPB1,KPB2,KPB3);
        //Average KP(Φ+45)
        let AvgKP45A = averageOf3(KPA451,KPA452,KPA453);
        let AvgKP45B = averageOf3(KPB451,KPB452,KPB453);

        //SICA
        let SICAA = AvgKPB - AvgKPA;
        let SICAB = AvgKP45B - AvgKP45A;

        //RESULT 5-6 Induced Corneal Astigmatism TCA1
        //TCA1Cylinder = T22 = sqrt(Q22^2+R22^2)
        let TCA1Cylinder = Math.sqrt(Math.pow(SICAA,2)+Math.pow(SICAB,2))
        //Axis (column N)
        let AxisN = getTanDeg(Math.atan((TCA1Cylinder-SICAA)/SICAB));
        //V22
        let V22;
        if(AxisN<0){
            V22 = 180;
        }else{
            V22 = 0;
        }
        let result6 = AxisN+V22;
        
        let nanAxis = false; 
        SICACalculation.SICACylinder = TCA1Cylinder.toFixed(2);
        if (!Number.isNaN(result6)){
            SICACalculation.SICAAxis = Math.round(result6);
        }else{
            nanAxis = true;    
        }
        
        if (SICACalculation.SICACylinder === "0.00" && nanAxis){
            SICACalculation.SICAAxis = 0;
        }
    }

    function renderResult(calculations){
        if(calculations.length === 1){
            setResultRender(
                <div className='sia-results'>
                    <Row>
                        <span id='results'>Cylinder:<i>{parseFloat(SIACalculations[0].SICACylinder).toFixed(2)}</i>
                        &nbsp;&nbsp;Axis:<i>{parseInt(SIACalculations[0].SICAAxis)}°</i></span>
                    </Row>
                </div>
            )
        }else{
            setResultRender(
                <Container>
                    <table className='sia-results-table'>
                        <thead>
                            <tr>
                                <th>Patient</th>
                                <th>Cylinder</th>
                                <th>Axis</th>
                            </tr>
                        </thead>
                        <tbody>
                            {SIACalculations.map((calculation) => (
                                <tr>
                                    <td>{calculation.patient}</td>
                                    <td>{calculation.SICACylinder}</td>
                                    <td>{calculation.SICAAxis}°</td>
                                </tr>
                            ))}
                            <tr>
                                <td><b>Average</b></td>
                                <td><b>{Averages[0]}</b></td>
                                <td><b>{Averages[1]}°</b></td>
                            </tr>
                        </tbody>
                    </table>
                </Container>
            )
        }
    }

    useEffect(() => {
        if(showResult){
            setLoading(true);
            setTimeout(() => setLoading(false),2000)
            
            if(!fixedResult){
                let averages = [0,0]

                SIACalculations.forEach(function(calculation) {
                    calculateSICA(calculation);
                    averages =
                    [ averages[0]+ parseFloat(calculation.SICACylinder), 
                     averages[1]+ parseFloat(calculation.SICAAxis) ]
                });

                averages = [averages[0]/SIACalculations.length, averages[1]/SIACalculations.length];
                setAverages([averages[0].toFixed(2), Math.round(averages[1])]);

                saveResult(false);
            }else{
                loadFixedResult(fixedResult);
            }
        }
    }, [showResult])
    useEffect( () => renderResult(SIACalculations) , [Averages])    

    if(showResult){
        return (
            <div id='siaResult'>

                <SaveResult show={saveResultWindow} setShow={showSaveResultWindow}
                resultJSON={resultJSON}/>
                <Downloading showDownloading={showDownloading}/>
                {popup}

                <div id="divToPrint" className='sia-result'>

                    <div className='sia-overlay'>
                        Result
                    </div>

                    <Container className='sia-result-content styledBox'>

                        <div className='resultLoadingDiv'
                        style={{display: loading? null : 'none'}}>
                            <LoadingDots/>
                        </div>
                        
                        <Row style={{opacity: loading? '0': null}}>
                            <Col className='sia-results-box resumeShadow'>
                                <Row>
                                    <Col className='sia-result-title'
                                    style={{paddingBottom: '0px'}}>
                                        <span><IoEyeOutline className='btnIcon'/> Surgically Induced Corneal Astigmatism:</span>
                                    </Col>
                                </Row>
                                {resultRender}
                            </Col>
                            <Row>
                                <div className='sia-result-options'
                                /*style={{display: (hideToDownload)? 'none' : null}}*/>
                                    <span onClick={() => downloadPdf()}><AiOutlineFilePdf/> Download</span>
                                    <span onClick={() => saveResult(true)}><FaUserMd/> Save Result</span>
                                    <span onClick={() => modifyInput(fixedResult)}><RiArrowGoBackLine/> Modify Input</span>
                                    {/*<span><FaRegFlag/> Report Error</span>*/}
                                </div>
                            </Row>
                        </Row>

                        <Row style={{opacity: loading? '0': null}}>
                            
                        </Row>

                        <div className='resumeCloseBtn' 
                        onClick={() => setShowResult(false)}
                        style={{opacity: loading? '0': null}}>
                            <AiOutlineCloseCircle/>
                        </div>

                    </Container>
                </div>
            </div>
          )
    }
}
