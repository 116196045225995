import React, {useState, useEffect} from 'react'
import {Form} from 'react-bootstrap';

export default function EyeLR({Val, setVal, setSt}) {

    const [changed, setChanged] = useState(false);
    const [Class, setClass] = useState('formControl colorgrey eyeInput')

    useEffect(() => {
        //Check validity and set class.
        if(Val === "Select Eye" || Val === ""){
            setSt(0);
            setClass('formControl colorgrey eyeInput');
        }else{
            setSt(1);
            setClass('formControl controlValid eyeInput');
        }
    },[Val, Class]);

    return (
        <Form onSubmit={e => { e.preventDefault(); }}>
            <Form.Group>
                <div className='inputDiv'>
                    <span className='controlLabel'>Eye:&nbsp;&nbsp;</span>
                    <Form.Select 
                        className={Class} value={Val}
                        onChange={
                            (e) => {
                                setVal(e.target.value);
                            }
                        }>
                        <option value="Select Eye" style={{display: 'none'}}>
                            Select Eye
                        </option>
                        <option value="Left">Left Eye</option>
                        <option value="Right">Right Eye</option>
                    </Form.Select>
                </div>  
            </Form.Group>
        </Form>
    )
}
