import {Measurement} from '../common/Measurement';

export function getTanDeg(rad) {
    var deg = rad * 180 / Math.PI;
    return deg;
  }    


export function averageOf3(a,b,c){
    //Note: be careful when trying to change !=, it may lead to a bug.
    if(!Number.isNaN(c) && c != ""){
        return((a+b+c)/3)
    }else if(!Number.isNaN(b) && b != ""){
        return((a+b)/2)
    }else{
        return(a)
    }
}

export function getOctAverages(Magn1, Magn2, Magn3, Axis1, Axis2, Axis3){

        // PENTACAM //
        //rad
        let rad1 = Math.PI/180*Axis1;
        let rad2 = Math.PI/180*Axis2;
        let rad3 = Math.PI/180*Axis3;

        //KP(Φ) 
        let KP1 = Magn1 * Math.cos(2*rad1);
        let KP2 = Magn2 * Math.cos(2*rad2);
        let KP3 = Magn3 * Math.cos(2*rad3);
        //KP(Φ+45) 
        let KP451 = Magn1 * Math.sin(2*rad1);
        let KP452 = Magn2 * Math.sin(2*rad2);
        let KP453 = Magn3 * Math.sin(2*rad3);

        //Average KP(Φ)
        let AvgKP = averageOf3(KP1,KP2,KP3);
        //Average KP(Φ+45)
        let AvgKP45 = averageOf3(KP451,KP452,KP453);

        //Cyl (T10)
        let cyl = Math.sqrt(AvgKP*AvgKP+AvgKP45*AvgKP45)
        //OCT Average Magnitude
        let octAvgMagnitude = cyl;

        //axis(1) (U10)
        let ax = getTanDeg(Math.atan((cyl-AvgKP)/AvgKP45))
        //axis(1) addition (V10)
        let axAddition;
        if(ax < 0){
            axAddition = 180;
        }else{
            axAddition = 0;
        }
        //Axis(°) (W10)
        let octAvgAxis = Math.round(ax+axAddition);

        return [octAvgMagnitude, octAvgAxis];
}

export function getSICAStats(SICAs){
    let AVERAGE;
    let MAX = new Measurement(null, null);
    let MIN = new Measurement(null, null);

    let SICANumber = SICAs.length;
    let axisSum = 0;
    let totalKP = 0;
    let totalKP45 = 0;
    //For every value
    for(let i = 0 ; i < SICANumber ; i++){
        let value = SICAs[i]
        let magnitude = parseFloat(value.magnitude);
        let axis = parseFloat(value.axis);
        if(magnitude !== ''){
            //Calculate rad
            let rad = Math.PI/180*axis;
            //Calculate KP
            let KP = magnitude * Math.cos(2*rad);
            //Calculate KP45
            let KP45 = magnitude * Math.sin(2*rad);
            //Add to var
            totalKP = totalKP + KP;
            totalKP45 = totalKP45 + KP45;
            axisSum = axisSum + axis;

            //Check if new MAX or MIN
            if(i === 0){
                MAX.magnitude = magnitude;
                MAX.axis = axis;
                MIN.magnitude = magnitude;
                MIN.axis = axis;
            }else if(MAX.magnitude < magnitude){
                MAX.magnitude = magnitude;
                MAX.axis = axis;
            }else if(MIN.magnitude > magnitude){
                MIN.magnitude = magnitude;
                MIN.axis = axis;
            }
        }
    }
    //Averages
    //Average KP(Φ)
    let AvgKP = totalKP / SICANumber;
    //Average KP(Φ+45)
    let AvgKP45 = totalKP45 / SICANumber;
    
    //Calculate AVG Magnitude
    let cyl = Math.sqrt(AvgKP*AvgKP+AvgKP45*AvgKP45);
    let avgMagnitude = cyl;

    //Calculate AVG Axis
    //axis(1) (U10)
    let ax = getTanDeg(Math.atan((cyl-AvgKP)/AvgKP45))
    //axis(1) addition (V10)
    let axAddition;
    if(ax < 0){
        axAddition = 180;
    }else{
        axAddition = 0;
    }
    //Axis(°) (W10)
    let avgAxis = Math.round(ax+axAddition);
    if(Number.isNaN(avgAxis)){
        avgAxis = 0.00;
    }

    AVERAGE = new Measurement(avgMagnitude, avgAxis);
    
    return [AVERAGE, MAX, MIN];
}


export function getMeanRatio(AxialLength, K1, K2){

        //// CALCULATION FOR MERIDIONAL ANALYSIS AND IMPLANTED IOL CYL. ////
        //HOFFER Q = HofferIOLPwB - HofferIOLPwA
        //Corrected Axial Length
        let CorrAXL;
        if(AxialLength<18.5){
            CorrAXL = 18.5;
        }else{
            if(AxialLength<=31){
                CorrAXL = AxialLength;
            }else{
                CorrAXL = 31;
            }
        }
        //G
        let G;
        if(CorrAXL<23){
            G=28;
        }else{
            G=23.5;
        }
        //M
        let M;
        if(CorrAXL<23){
            M=1;
        }else{
            M=-1;
        }
        //Vertex
        let vertex = 12;
        //Desired RX
        let RX = 0;
        //Average K
        let AvgK = (parseFloat(K1)+parseFloat(K2))/2;
        //Personalized ACD = Hoffer Q
        let PerACD = 5.89;
        //Predicted ACD
        let PredACD = 
        PerACD + 0.3 * (CorrAXL-23.5) + Math.pow(Math.tan(AvgK*Math.PI/180),2) +
        (0.1 * M * Math.pow(23.5-CorrAXL,2) * 
        (Math.tan(0.1 * Math.pow(G-CorrAXL,2) * Math.PI / 180))) - 0.99166;
        
        
        
        //Calculate HofferIOLPwB: (K86)
        //K for HofferIOLPwB
        let KB = parseFloat(K2);
        //HofferIOLPwB
        let HofferIOLPwB =
        (1336/(AxialLength-PredACD-0.05)) - 
        (1.336/((1.336/(KB+RX/(1-0.001*vertex*RX))) - ((PredACD+0.05)/1000)))
        //Calculate HofferIOLPwA: (K85)
        //K for HofferIOLPwA
        let KA2 = parseFloat(K1);
        //HofferIOLPwA
        let HofferIOLPwA =
        (1336/(AxialLength-PredACD-0.05)) - 
        (1.336/((1.336/(KA2+RX/(1-0.001*vertex*RX))) - ((PredACD+0.05)/1000)));

        //Hoffer Q Final Result
        let HofferQ = HofferIOLPwB - HofferIOLPwA;

    

        //  HOLLADAY 1 = J92-J91  //
        let HolladayConstant = 2.11;
        //R
        let R1 = 337.5/AvgK;
        let R2 = 337.5/K1;
        let R3 = 337.5/K2;
        //AG
        let AG1;
        if(12.5*parseFloat(AxialLength)/23.45<13.5){
            AG1 = 12.5*parseFloat(AxialLength)/23.45;
        }else{
            AG1 = 13.5;
        }
        //ACD
        let ACD = 0.56+R1-Math.sqrt(Math.pow(R1,2)-Math.pow(AG1,2)/4);
        //Alm
        let Alm = parseFloat(AxialLength)+0.2;

        //HolladayIOLPw
        let HolladayIOLPwA = 1336*(1.336*R2-1/3*Alm-0.001*RX*(vertex*(1.336*R2-1/3*Alm)+Alm*R2))/
        ((Alm-ACD-HolladayConstant)*(1.336*R2-1/3*(ACD+HolladayConstant)-
        0.001*RX*(vertex*(1.336*R2-1/3*(ACD+HolladayConstant))+(ACD+HolladayConstant)*R2)));
        let HolladayIOLPwB = 1336*(1.336*R3-1/3*Alm-0.001*RX*(vertex*(1.336*R3-1/3*Alm)+Alm*R3))/
        ((Alm-ACD-HolladayConstant)*(1.336*R3-1/3*(ACD+HolladayConstant)-
        0.001*RX*(vertex*(1.336*R3-1/3*(ACD+HolladayConstant))+(ACD+HolladayConstant)*R3)));

        //Holladay 1
        let Holladay1 = HolladayIOLPwB-HolladayIOLPwA;



        //SRK/T = Q98-Q97
        //Radius
        let radius1 = R1;
        let radius2 = R2;
        let radius3 = R3;
        //LCOR
        let LCOR;
        if(AxialLength>24.2){
            LCOR = -3.446+1.716*parseFloat(AxialLength)-0.0237*Math.pow(parseFloat(AxialLength),2)
        }else{
            LCOR = parseFloat(AxialLength);
        }
        //Cw
        let Cw1 = -5.41+0.58412*LCOR+0.098*AvgK;
        //H
        let H1 = radius1-Math.sqrt(Math.pow(radius1,2)-Math.pow(Cw1,2)/4);
        //A-Constant
        let AConstant = 119.4;
        //ACD Const
        let ACDConst = 0.62467*AConstant-68.747;
        //ACDest
        let ACDest = H1+ACDConst-3.336;
        //na
        let na = 1.336;
        //ncm1
        let ncm1 = 0.333;
        //Rethick
        let Rethick = 0.65696-0.02029*parseFloat(AxialLength)
        //LOPT
        let LOPT = parseFloat(AxialLength)+Rethick;

        //IOL emme
        let IOLemmeA = (1000*na*(na*radius2-ncm1*LOPT))/((LOPT-ACDest)*(na*radius2-ncm1*ACDest));
        let IOLemmeB = (1000*na*(na*radius3-ncm1*LOPT))/((LOPT-ACDest)*(na*radius3-ncm1*ACDest))

        //SRK/T
        let SRKT = IOLemmeB-IOLemmeA;


        //MEAN = RESULT 1 = IOL/CORNEA CYLINDER RATIO
        //IMPLANTED IOL CYLINDER -> RESULT2
        let mean = (HofferQ+Holladay1+SRKT)/3
        let KA = K1-K2;
        let meanRatio = mean/KA;

        return meanRatio;
}

//Returns meridional ratio for piggyback calculator
//!Holladayflat J7
export function getMeridionalRatio(K1, K2, Sphere, Cylinder, Vertex, ACD){
        //Holladayflat and Holladaysteep pre rx//
        //(each object of the array represents one row)
        
        // // FLAT // // 
        var sumak = K2 + K2;
        var a20 = 0.5 * sumak;
        var k = a20 * 0.98765431;
        var prerxFlat = []
        //pre rx = 0
        prerxFlat[0] = Sphere;
        //mil div prerx = 2
        prerxFlat[2] = 1000 / prerxFlat[0]
        // -v = 3
        prerxFlat[3] = prerxFlat[2] - Vertex;
        //div 1000 = 4
        prerxFlat[4] = 1000 / prerxFlat[3];
        //plus k = 5
        prerxFlat[5] = prerxFlat[4] + k;
        //1336div = 6
        prerxFlat[6] = 1336 / prerxFlat[5];
        // -elp = 7
        prerxFlat[7] = prerxFlat[6] - ACD;
        //1336div (2) = 8
        prerxFlat[8] = 1336 / prerxFlat[7]
        //DESIRED POST RX//
        var postrxFlat = []
        postrxFlat[0] = 0.001;
        postrxFlat[2] = 1000 / postrxFlat[0]
        postrxFlat[3] = postrxFlat[2] - Vertex;
        postrxFlat[4] = 1000 / postrxFlat[3];
        postrxFlat[5] = postrxFlat[4] + k;
        postrxFlat[6] = 1336 / postrxFlat[5];
        postrxFlat[7] = postrxFlat[6] - ACD;
        postrxFlat[8] = 1336 / postrxFlat[7]

        // // STEEP // // 
        sumak = K1 + K1;
        a20 = 0.5 * sumak;
        k = a20 * 0.98765431; 
        var prerxSteep = []
        //pre rx = 0
        prerxSteep[0] = Sphere + Cylinder;
        //mil div prerx = 2
        prerxSteep[2] = 1000 / prerxSteep[0]
        // -v = 3
        prerxSteep[3] = prerxSteep[2] - Vertex;
        //div 1000 = 4
        prerxSteep[4] = 1000 / prerxSteep[3];
        //plus k = 5
        prerxSteep[5] = prerxSteep[4] + k;
        //1336div = 6
        prerxSteep[6] = 1336 / prerxSteep[5];
        // -elp = 7
        prerxSteep[7] = prerxSteep[6] - ACD;
        //1336div (2) = 8
        prerxSteep[8] = 1336 / prerxSteep[7]
        //DESIRED POST RX//
        var postrxSteep = []
        postrxSteep[0] = 0.001;
        postrxSteep[2] = 1000 / postrxSteep[0]
        postrxSteep[3] = postrxSteep[2] - Vertex;
        postrxSteep[4] = 1000 / postrxSteep[3];
        postrxSteep[5] = postrxSteep[4] + k;
        postrxSteep[6] = 1336 / postrxSteep[5];
        postrxSteep[7] = postrxSteep[6] - ACD;
        postrxSteep[8] = 1336 / postrxSteep[7]
        
        //Holladayflat and Holladaysteep iol
        var iolFlat = prerxFlat[8] - postrxFlat[8];
        var iolSteep = prerxSteep[8] - postrxSteep[8];

        //MERIDIONAL
        var MERIDIONAL = iolSteep - iolFlat
        //meridional ratio
        var meridionalRatio = MERIDIONAL / Cylinder;
        
        return meridionalRatio;
}

