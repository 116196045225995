import React, {useState, useEffect} from 'react'

import {averageOf3, getTanDeg} from '../services/ToricCalculation';

import { Container, Row, Col, Form } from 'react-bootstrap'

import LoadingDots from '../common/LoadingDots';

import {AiOutlineCloseCircle, AiOutlineFilePdf} from 'react-icons/ai';
import {FaRegQuestionCircle, FaUserMd} from 'react-icons/fa'
import {BsArrowRight,BsArrowLeft} from 'react-icons/bs'
import {IoEyeOutline} from 'react-icons/io5';
import {RiArrowGoBackLine} from 'react-icons/ri';

import graphicCircle from '../img/graphicCircle.png'
//import graphicIOL from  '../img/graphicIOL.png'
import graphicSuggested from '../img/graphicSuggested.png'

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import {types} from '../helpers/enums/LensModels';

import { getMeridionalRatio } from '../services/ToricCalculation';

import './result.css'
import Downloading from '../common/Downloading';
import SaveResult from '../common/SaveResult';

import { PiggybackCalculation } from './PiggybackCalculation';
import { sendNewResult } from '../services/UserResults';

export default function Result({
    showResult, setShowResult,
    fixedResult,

    LensType2, Lens, LensType, IOLMarks,

    Patient, Surgeon,
    Eye, AxialLength, OrderedAxis,
    K1, K2, SteepMeridian,
    AvgMagnitude1, AvgAxis1,
    AvgMagnitude2, AvgAxis2,

    IOLPlane,
    Sphere, Cylinder, Axis, ACD, Vertex,
    PostopRefSphere, PostopRefCylinder, PostopRefAxis,

    TCA1Axis1, TCA1Axis2, TCA1Axis3,
    TCA1Magn1, TCA1Magn2, TCA1Magn3,
    TCA2Axis1, TCA2Axis2, TCA2Axis3,
    TCA2Magn1, TCA2Magn2, TCA2Magn3,
}) {

    //Show save result popup
    const [saveResultWindow, showSaveResultWindow] = useState(false);
    const [resultJSON, setResultJSON] = useState({});
    //PRR auxiliary data: Stored on a saved result for prr recalculation.
    const [PRRRecalculationAux, setPRRRecalculationAux] = useState([]);

    const [orientationValue, setOrientationValue] = useState();
    const [graphicIOL, setGraphicIOL] = useState("Standard");
    const [iolMarks, setIOLMarks] = useState(0);

    //Result States
    //Meridional Analysis
    //IOL/Spectacle Cyl. Ratio:
    const [Result1, setResult1] = useState(0);
    //Required Cylinder at IOL Plane
    const [Result2, setResult2] = useState(0);

    //Implanted IOL cylinder
    //at IOL Plane:
    const [Result3, setResult3] = useState();
    //at Corneal Plane:
    const [Result4, setResult4] = useState();

    //Induced Corneal Astigmatism
    //TCA1
    //Cylinder
    const [Result5, setResult5] = useState(0);
    //Axis
    const [Result6, setResult6] = useState(0);

    //IOL Alignement 
    //Used Lens
    const [lens, setLens] = useState();
    //Suggested Axis
    const [Result7, setResult7] = useState(0);
    //Predicted Residual refraction
    //Sphere
    const [Result8, setResult8] = useState(0);
    //Cylinder
    const [Result9, setResult9] = useState(0);
    //Axis
    const [Result10, setResult10] = useState(0);

    //Result loading div
    const [loading, setLoading] = useState(false);
    //State to hide elements and show downloading popup when downloading results
    const [showDownloading, setShowDownloading] = useState(false);
    const [hideToDownload, setHideToDownload] = useState(false);

    function getViewport() {

        var viewPortWidth;
        var viewPortHeight;
       
        // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
        if (typeof window.innerWidth != 'undefined') {
          viewPortWidth = window.innerWidth
          viewPortHeight = window.innerHeight
        }
       // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
        else if (typeof document.documentElement !== 'undefined'
        && typeof document.documentElement.clientWidth !==
        'undefined' && document.documentElement.clientWidth !== 0) {
           viewPortWidth = document.documentElement.clientWidth
           viewPortHeight = document.documentElement.clientHeight
        }
        // older versions of IE
        else {
          viewPortWidth = document.getElementsByTagName('body')[0].clientWidth
          viewPortHeight = document.getElementsByTagName('body')[0].clientHeight
        }
        return [viewPortWidth, viewPortHeight];
       }

    function downloadPdf(){

        var viewport = getViewport()
        if(viewport[0] > 576){
            setShowDownloading(true);
            setHideToDownload(true);

            setTimeout(() => {
                var input = document.getElementById('divToPrint');
                setHideToDownload(false);
                
                html2canvas(input)
                .then((canvas) => {
                const data = canvas.toDataURL('image/png');

                var orientation = 'l';
                var viewport = getViewport()
                if(viewport[0] < viewport[1]){
                    orientation = 'p'
                }

                const pdf = new jsPDF(orientation, 'px', [viewport[0], viewport[1]]);
                const imgProperties = pdf.getImageProperties(data);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width;


                pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('ToricAligner_' + Patient + '_' + Surgeon + '.pdf');

                setShowDownloading(false);
                })
            }, 1000)
        }else{
            window.alert("Downloading is not available in mobile devices. If you want" +
            " to save your result as a .pdf file, please visit the website from a computer.")
        }
    }

    function modifyInput(result){
        if(!result){
            setShowResult(false);
        }else{
            let calculationObject = new PiggybackCalculation(
                result.preopInput[8], result.iolModel,
                result.patientID, 'surgeonID', result.preopInput[0], result.prrrecalculationAux[5], 
                result.preopInput[1], result.preopInput[2], result.preopInput[3], result.preopInput[4],
                result.preopInput[5], result.preopInput[6], result.preopInput[7],
                result.tca1,

                result.postopInput[1], result.postopInput[2], result.postopInput[3], result.postopInput[4],
                result.iolToricity, result.tca2,
            );
            localStorage.setItem('calculationObject', JSON.stringify(calculationObject));
            window.location.replace('/piggyback')
        }
    }
    
    //Recalculation for Postoperative Refraction when Cylinder<0
    var postopRefSph = PostopRefSphere;
    var postopRefCyl = PostopRefCylinder;
    var postopRefAxis = PostopRefAxis;
    function PostopRefractionRecalculation(){
        postopRefSph = PostopRefSphere+PostopRefCylinder;
        postopRefCyl = (PostopRefCylinder*-1)
        if(postopRefAxis<=90){
            postopRefAxis = (parseFloat(PostopRefAxis)+90);
        }else{
            postopRefAxis = (parseFloat(PostopRefAxis)-90);
        }
    }

    function limitOrientation(){
        //Validation: Limit orientationValue
        if(orientationValue < 0){
            setOrientationValue(0);
        }else if(orientationValue > 180){
            setOrientationValue(180)
        }
    }

    //Calculates and displays results.
    function calculateResults(){

        setResult3(IOLPlane);

        limitOrientation();

        //Calculate precise IOL Corneal Plane
        var meridionalRatio = getMeridionalRatio(K1, K2, Sphere, Cylinder, Vertex, ACD);
        var cornealPlane = parseFloat(IOLPlane) / meridionalRatio;
        setResult4(cornealPlane.toFixed(2))

        /*Use keratomatric astigmatism steep meridian as 
        OCT1 axis and Magnitude as Magnitude if OCT1 has been not filled
        by the user.*/
        var tca1Axis1;
        var tca1Magn1;
        if(Number.isNaN(TCA1Axis1)){
            tca1Axis1 = parseFloat(SteepMeridian);
            tca1Magn1 = K1-K2;
        }else{
            tca1Axis1 = TCA1Axis1;
            tca1Magn1 = TCA1Magn1;
        }
        var tca2Axis1;
        var tca2Magn1;
        if(Number.isNaN(TCA2Axis1)){
            tca2Axis1 = tca1Axis1;
            tca2Magn1 = tca1Magn1;
        }else{
            tca2Axis1 = TCA2Axis1;
            tca2Magn1 = TCA2Magn1;
        }

        //Refr Ast Axis calculation (J5)
        var refrAxisConversion;
        if(Axis+90 > 179){
            refrAxisConversion = Axis - 90;
        }else if(Axis+90 < 180){
            refrAxisConversion = Axis + 90;
        }

        //Ordered axis definition:
        var orderedAxis;
        if(LensType === "1"){
            orderedAxis = "";
        }
        else if(Lens==="IPCL Toric"){
            //With IPCL lens, orderedAxis = refr ast axis conversion (J5)
            orderedAxis = refrAxisConversion;
        }else if(Lens==="Artiflex Toric 0°"){
            //With Artiflex Toric 0° orderedAxis== 90;
            orderedAxis = 90;
        }
        else{
            orderedAxis = parseFloat(OrderedAxis);
        }


        
        // // // RESULT 1 AND 2 // // //
        //IOL/SPECTACLE CYLINDER RATIO
        // //Holladayflat and Holladaysteep pre rx//
        //(each object of the array represents one row)

        // // FLAT // // 
        var sumak = K2 + K2;
        var a20 = 0.5 * sumak;
        var k = a20 * 0.98765431;
        var prerxFlat = []
        //pre rx = 0
        prerxFlat[0] = Sphere;
        //mil div prerx = 2
        prerxFlat[2] = 1000 / prerxFlat[0]
        // -v = 3
        prerxFlat[3] = prerxFlat[2] - Vertex;
        //div 1000 = 4
        prerxFlat[4] = 1000 / prerxFlat[3];
        //plus k = 5
        prerxFlat[5] = prerxFlat[4] + k;
        //1336div = 6
        prerxFlat[6] = 1336 / prerxFlat[5];
        // -elp = 7
        prerxFlat[7] = prerxFlat[6] - ACD;
        //1336div (2) = 8
        prerxFlat[8] = 1336 / prerxFlat[7]
        //DESIRED POST RX//
        var postrxFlat = []
        postrxFlat[0] = 0.001;
        postrxFlat[2] = 1000 / postrxFlat[0]
        postrxFlat[3] = postrxFlat[2] - Vertex;
        postrxFlat[4] = 1000 / postrxFlat[3];
        postrxFlat[5] = postrxFlat[4] + k;
        postrxFlat[6] = 1336 / postrxFlat[5];
        postrxFlat[7] = postrxFlat[6] - ACD;
        postrxFlat[8] = 1336 / postrxFlat[7]

        // // STEEP // // 
        sumak = K1 + K1;
        a20 = 0.5 * sumak;
        k = a20 * 0.98765431; 
        var prerxSteep = []
        //pre rx = 0
        prerxSteep[0] = Sphere + Cylinder;
        //mil div prerx = 2
        prerxSteep[2] = 1000 / prerxSteep[0]
        // -v = 3
        prerxSteep[3] = prerxSteep[2] - Vertex;
        //div 1000 = 4
        prerxSteep[4] = 1000 / prerxSteep[3];
        //plus k = 5
        prerxSteep[5] = prerxSteep[4] + k;
        //1336div = 6
        prerxSteep[6] = 1336 / prerxSteep[5];
        // -elp = 7
        prerxSteep[7] = prerxSteep[6] - ACD;
        //1336div (2) = 8
        prerxSteep[8] = 1336 / prerxSteep[7]
        //DESIRED POST RX//
        var postrxSteep = []
        postrxSteep[0] = 0.001;
        postrxSteep[2] = 1000 / postrxSteep[0]
        postrxSteep[3] = postrxSteep[2] - Vertex;
        postrxSteep[4] = 1000 / postrxSteep[3];
        postrxSteep[5] = postrxSteep[4] + k;
        postrxSteep[6] = 1336 / postrxSteep[5];
        postrxSteep[7] = postrxSteep[6] - ACD;
        postrxSteep[8] = 1336 / postrxSteep[7]
        
        //Holladayflat and Holladaysteep iol
        var iolFlat = prerxFlat[8] - postrxFlat[8];
        var iolSteep = prerxSteep[8] - postrxSteep[8];

        //MERIDIONAL
        var MERIDIONAL = iolSteep - iolFlat
        //meridional ratio
        var meridionalRatio2 = MERIDIONAL / Cylinder;
        //IOL/Spectacle Cylinder ratio
        var result1 = meridionalRatio2;
        setResult1(result1.toFixed(2));



        // // // RESULT 5 AND 6 // // //
        //SICA CYLINDER AND AXIS
        //rad
        let radA1 = Math.PI/180*tca1Axis1;
        let radA2 = Math.PI/180*TCA1Axis2;
        let radA3 = Math.PI/180*TCA1Axis3;
        let radB1 = Math.PI/180*tca2Axis1;
        let radB2 = Math.PI/180*TCA2Axis2;
        let radB3 = Math.PI/180*TCA2Axis3;
        
        //KP(Φ) 
        let KPA1 = tca1Magn1 * Math.cos(2*radA1);
        let KPA2 = TCA1Magn2 * Math.cos(2*radA2);
        let KPA3 = TCA1Magn3 * Math.cos(2*radA3);
        let KPB1 = tca2Magn1 * Math.cos(2*radB1);
        let KPB2 = TCA2Magn2 * Math.cos(2*radB2);
        let KPB3 = TCA2Magn3 * Math.cos(2*radB3);
        //KP(Φ+45) 
        let KPA451 = tca1Magn1 * Math.sin(2*radA1);
        let KPA452 = TCA1Magn2 * Math.sin(2*radA2);
        let KPA453 = TCA1Magn3 * Math.sin(2*radA3);
        let KPB451 = tca2Magn1 * Math.sin(2*radB1);
        let KPB452 = TCA2Magn2 * Math.sin(2*radB2);
        let KPB453 = TCA2Magn3 * Math.sin(2*radB3);

        //Average KP(Φ)
        let AvgKPA = averageOf3(KPA1,KPA2,KPA3);
        let AvgKPB = averageOf3(KPB1,KPB2,KPB3);
        //Average KP(Φ+45)
        let AvgKP45A = averageOf3(KPA451,KPA452,KPA453);
        let AvgKP45B = averageOf3(KPB451,KPB452,KPB453);

        //SICA
        let SICAA = AvgKPB - AvgKPA;
        let SICAB = AvgKP45B - AvgKP45A;

        //RESULT 5-6 Induced Corneal Astigmatism TCA1
        //TCA1Cylinder = T22 = sqrt(Q22^2+R22^2)
        let TCA1Cylinder = Math.sqrt(Math.pow(SICAA,2)+Math.pow(SICAB,2))
        //Axis (column N)
        let AxisN = getTanDeg(Math.atan((TCA1Cylinder-SICAA)/SICAB));
        //V22
        let V22;
        if(AxisN<0){
            V22 = 180;
        }else{
            V22 = 0;
        }
        let result6 = AxisN+V22;
        
        let nanAxis = false; 
        setResult5(TCA1Cylinder.toFixed(2));
        if (!Number.isNaN(result6)){
            setResult6(Math.round(result6));
        }else{
            nanAxis = true;    
        }
        
        if (Result5 === "0.00" && nanAxis){
            setResult6(0)
        }



        // // // IOL ALIGNMENT RESULTS 7, 8, 9, 10 // // //
        // // CALCULATION FOR RESULT7 SUGGESTED AXIS // //
        //Preop refr + SICA Vector Axis calculation
        //For Result 2 and Result 7 (suggested axis)
        //Preop Refactive sheet
        var k1 = Math.abs(Cylinder);
        var k2 = 0;
        var cyl = k1 - k2;
        var axisDeg = refrAxisConversion;
        var axisRad = Math.PI / 180*axisDeg;
        //TCA Sheet
        var refractiveSicaKP = cyl * Math.cos(2*axisRad) + SICAA;
        var refractiveSicaKP45 = cyl * Math.sin(2*axisRad) + SICAB;
        //Preop refr + SICA Vector (N17)
        var refractiveSicaCyl = Math.sqrt(Math.pow(refractiveSicaKP,2)+Math.pow(refractiveSicaKP45,2))
        var refrSicaAxis = Math.round(
            (getTanDeg(Math.atan((refractiveSicaCyl-refractiveSicaKP)/refractiveSicaKP45)))
        )
        if(parseFloat(refrSicaAxis)<0){
            refrSicaAxis = parseFloat(refrSicaAxis) + 180;
        }

        // //Required cylinder at IOL Plane// //
        // Result2 is calculated here because we need refractiveSicaCyl
        if(LensType === 2){
            var result2 = refractiveSicaCyl*result1;
            setResult2(result2.toFixed(2))
        }else{
            var result2 = Math.abs(Cylinder)*result1;
            setResult2(result2.toFixed(2));
        }
        

        //Result7 = IOL ORIENTATION
        var result7;
        if(LensType!=="1"){
            //substraction = excel K49 
            var substraction = parseFloat(refrSicaAxis) - orderedAxis;
            var suggestedIOLAxis = 180 + substraction; 
            if(suggestedIOLAxis > 179){
                result7 = suggestedIOLAxis - 180;
            }else if(suggestedIOLAxis<180){
                result7 = suggestedIOLAxis;
            }
        }else{
            result7 = refrSicaAxis;
        }
        setResult7(result7);


        // // CYLINDER // //
        //Calculate Corrected IOL Orientation from Marks Orientation
        //STAAR and IPCL lenses
        var IOLOrientation;
        if(LensType !== "1"){
            var a = orderedAxis - 90;
            var b = orientationValue - (90-a);
            if(b<=0){
                IOLOrientation = b + 180;
            }else if(b>0){
                IOLOrientation = b;
            }
        }else{
            IOLOrientation = orientationValue;
        }


        let IOLAxisSteep;
        if(IOLOrientation>89){
            IOLAxisSteep = parseFloat(IOLOrientation)-90;
        }else{
            IOLAxisSteep = parseFloat(IOLOrientation)+90;
        }
        let IOLAxisRad = Math.PI / 180 * IOLAxisSteep;
        let SiriusSicaKP_1 = refractiveSicaKP;
        let SiriusSicaKP45_1 = refractiveSicaKP45;
        let SiriusSicaKP_2 = cornealPlane * Math.cos(2*IOLAxisRad);
        let SiriusSicaKP45_2 = cornealPlane * Math.sin(2*IOLAxisRad);
        let SiriusSicaKP_3 = SiriusSicaKP_1 + SiriusSicaKP_2;
        let SiriusSicaKP45_3 = SiriusSicaKP45_1 + SiriusSicaKP45_2;
        let result9 = Math.sqrt(Math.pow(SiriusSicaKP_3,2)+Math.pow(SiriusSicaKP45_3,2))
        setResult9(result9.toFixed(2));

        // // SPHERE // //
        let result8 = (PostopRefCylinder - result9)/2 + postopRefSph;
        setResult8(result8.toFixed(2));
        
        // // AXIS // //
        let result10;
        let TcaRefrAxis;
        if(result9 === 0){
            TcaRefrAxis = 0;
        }else{
            TcaRefrAxis = 0.5 * Math.atan2(SiriusSicaKP45_3,SiriusSicaKP_3) * 180 / Math.PI;
        }
        result10 = TcaRefrAxis;
        if(TcaRefrAxis<0){
            result10 = result10 + 180;
        }
        setResult10(Math.round(result10))

        let prrRecalculationAux = [];
        prrRecalculationAux.push(
            Cylinder, refrAxisConversion, SICAA, SICAB, 
            LensType, orderedAxis, PostopRefCylinder, postopRefSph
        )
        setPRRRecalculationAux(prrRecalculationAux)

        //DEBUG HERE
        //debugger;

    }

    async function saveResult(show){
        let resultDTO;
        if(!fixedResult){
            resultDTO = ({
                "visible:": show,
                "preopInput": [Eye, K1.toString(), K2.toString(), 
                SteepMeridian.toString(), Sphere.toString(),
                Cylinder.toString(), Axis.toString(), Vertex.toString(), LensType2],
                "postopInput": [Lens, postopRefSph.toString(), PostopRefCylinder.toString(),
                postopRefAxis.toString(), ACD.toString(), Vertex.toString(), IOLMarks],
                "iolType": "Phakic/Piggyback",
                "iolModel": Lens,
                "iolToricity": IOLPlane,
                "tca": [AvgMagnitude1.toString(), AvgAxis1.toString()],
                "tca1": [TCA1Magn1.toString(), TCA1Axis1.toString(),
                    TCA1Magn2.toString(), TCA1Axis2.toString(),
                    TCA1Magn3.toString(), TCA1Axis3.toString()],
                "tca2": [TCA2Magn1.toString(), TCA2Axis1.toString(),
                    TCA2Magn2.toString(), TCA2Axis2.toString(),
                    TCA2Magn3.toString(), TCA2Axis3.toString()],
                "sia": [Result5.toString(), Result6.toString()],
    
                "meridionalAnalysis": [Result1, Result2],
                "implantedIolCylinder": [IOLPlane, Result4],
                "sica1": [Result5, Result6],
                "alignment1": [Result7, Result8, Result9, Result10],
                "prrrecalculationAux": PRRRecalculationAux
                });
            setResultJSON(resultDTO);
            if(show){
                showSaveResultWindow(true);
            }else{
                var result = await sendNewResult(resultDTO);
            }
        }
    }

    //Load result if this component is opened from an user stored result.
    function loadFixedResult(result){
        setResult1(result.meridionalAnalysis[0].toFixed(2));
        setResult2(result.meridionalAnalysis[1].toFixed(2));
        setResult3(result.implantedIolCylinder[0]);
        setResult4(result.implantedIolCylinder[1].toFixed(2));
        setResult5(result.sica1[0].toFixed(2));
        setResult6(result.sica1[1]);
        setResult7(result.alignment1[0]);
        setResult8(result.alignment1[1].toFixed(2));
        setResult9(result.alignment1[2].toFixed(2));
        setResult10(result.alignment1[3])
        setIOLMarks(parseFloat(result.postopInput[6]))
        setLens(result.postopInput[0]);
    }

    function recalculateIOLAlignment(recalculationAux){

        let refrAxisConversion = parseFloat(recalculationAux[1]);
        let SICAA = parseFloat(recalculationAux[2]);
        let SICAB = parseFloat(recalculationAux[3]);
        let LensType = recalculationAux[4];
        let orderedAxis = recalculationAux[5];
        let PostopRefCylinder = parseFloat(fixedResult.postopInput[2])
        let postopRefSph = parseFloat(fixedResult.postopInput[1]);

        //Calculate precise IOL Corneal Plane
        let K1 = parseFloat(fixedResult.preopInput[1]);
        let K2 = parseFloat(fixedResult.preopInput[2]);
        let Sphere = parseFloat(fixedResult.preopInput[4])
        let Cylinder = parseFloat(fixedResult.preopInput[5])
        let Vertex = parseFloat(fixedResult.preopInput[7])
        let ACD = parseFloat(fixedResult.postopInput[4])
        let IOLPlane = parseFloat(fixedResult.implantedIolCylinder[0]);
        var meridionalRatio = getMeridionalRatio(K1, K2, Sphere, Cylinder, Vertex, ACD);
        var cornealPlane = parseFloat(IOLPlane) / meridionalRatio;

        // // // IOL ALIGNMENT RESULTS 7, 8, 9, 10 // // //
        // // CALCULATION FOR RESULT7 SUGGESTED AXIS // //
        //Preop refr + SICA Vector Axis calculation
        //For Result 2 and Result 7 (suggested axis)
        //Preop Refactive sheet
        var k1 = Math.abs(Cylinder);
        var k2 = 0;
        var cyl = k1 - k2;
        var axisDeg = refrAxisConversion;
        var axisRad = Math.PI / 180*axisDeg;
        //TCA Sheet
        var refractiveSicaKP = cyl * Math.cos(2*axisRad) + SICAA;
        var refractiveSicaKP45 = cyl * Math.sin(2*axisRad) + SICAB;
        //Preop refr + SICA Vector (N17)
        var refractiveSicaCyl = Math.sqrt(Math.pow(refractiveSicaKP,2)+Math.pow(refractiveSicaKP45,2))
        var refrSicaAxis = Math.round(
            (getTanDeg(Math.atan((refractiveSicaCyl-refractiveSicaKP)/refractiveSicaKP45)))
        )
        if(parseFloat(refrSicaAxis)<0){
            refrSicaAxis = parseFloat(refrSicaAxis) + 180;
        }

        /*Result7 = IOL ORIENTATION
        var result7;
        if(LensType!=="1"){
            //substraction = excel K49 
            var substraction = parseFloat(refrSicaAxis) - orderedAxis;
            var suggestedIOLAxis = 180 + substraction; 
            if(suggestedIOLAxis > 179){
                result7 = suggestedIOLAxis - 180;
            }else if(suggestedIOLAxis<180){
                result7 = suggestedIOLAxis;
            }
        }else{
            result7 = refrSicaAxis;
        }
        setResult7(result7);*/


        // // CYLINDER // //
        //Calculate Corrected IOL Orientation from Marks Orientation
        //STAAR and IPCL lenses
        var IOLOrientation;
        if(LensType !== "1"){
            var a = orderedAxis - 90;
            var b = orientationValue - (90-a);
            if(b<=0){
                IOLOrientation = b + 180;
            }else if(b>0){
                IOLOrientation = b;
            }
        }else{
            IOLOrientation = orientationValue;
        }


        let IOLAxisSteep;
        if(IOLOrientation>89){
            IOLAxisSteep = parseFloat(IOLOrientation)-90;
        }else{
            IOLAxisSteep = parseFloat(IOLOrientation)+90;
        }
        let IOLAxisRad = Math.PI / 180 * IOLAxisSteep;
        let SiriusSicaKP_1 = refractiveSicaKP;
        let SiriusSicaKP45_1 = refractiveSicaKP45;
        let SiriusSicaKP_2 = cornealPlane * Math.cos(2*IOLAxisRad);
        let SiriusSicaKP45_2 = cornealPlane * Math.sin(2*IOLAxisRad);
        let SiriusSicaKP_3 = SiriusSicaKP_1 + SiriusSicaKP_2;
        let SiriusSicaKP45_3 = SiriusSicaKP45_1 + SiriusSicaKP45_2;
        let result9 = Math.sqrt(Math.pow(SiriusSicaKP_3,2)+Math.pow(SiriusSicaKP45_3,2))
        setResult9(result9.toFixed(2));

        // // SPHERE // //
        let result8 = parseFloat((PostopRefCylinder - result9)/2 + postopRefSph);
        setResult8(result8.toFixed(2));
        
        // // AXIS // //
        let result10;
        let TcaRefrAxis;
        if(result9 === 0){
            TcaRefrAxis = 0;
        }else{
            TcaRefrAxis = 0.5 * Math.atan2(SiriusSicaKP45_3,SiriusSicaKP_3) * 180 / Math.PI;
        }
        result10 = TcaRefrAxis;
        if(TcaRefrAxis<0){
            result10 = result10 + 180;
        }
        setResult10(Math.round(result10))

        //DEBUG HERE
        //debugger;
    }

    //Renders conditionally the "input" information in window title.
    const [inputData, setInputData] = useState(<Col></Col>)
    function renderInputData(result){
        var inputDataColumn;
        if(!fixedResult){
            inputDataColumn = 
                (<Col>
                    <b>PREOP:</b>&nbsp;&nbsp;&nbsp;
                    <b>Eye:</b> {Eye}
                    &nbsp;&nbsp;<b>K1:</b> {K1} &nbsp;<b>K2:</b> {K2}
                    &nbsp;&nbsp;<b>Steep Meridian:</b>&nbsp;{SteepMeridian}
                    &nbsp;&nbsp;<b>Sphere:</b> {Sphere} &nbsp;<b>Cylinder:</b> {Cylinder}
                    &nbsp;&nbsp;<b>Axis:</b> {Axis} &nbsp;<b>Vertex:</b> {Vertex}
                    <span style={{display: (AvgMagnitude1 === "0.00")? 'none' : null}}>
                    {/*&nbsp;&nbsp;<b>Avg. Mag.:</b>&nbsp;{AvgMagnitude1}
                    &nbsp;&nbsp;<b>Avg. Axis:</b>&nbsp;{AvgAxis1}*/}
                    </span>
                    <br></br>
                    <span>
                    <b>POSTOP:</b>&nbsp;&nbsp;&nbsp;
                    <b>IOL:</b> &nbsp;{Lens}
                    &nbsp;&nbsp;<b>Sphere:</b>&nbsp;{PostopRefSphere}
                    &nbsp;&nbsp;<b>Cylinder:</b>&nbsp;{PostopRefCylinder}
                    &nbsp;&nbsp;<b>Axis:</b>&nbsp;{PostopRefAxis}
                    &nbsp;&nbsp;<b>ACD:</b>&nbsp;{ACD}
                    &nbsp;&nbsp;<b>Vertex:</b>&nbsp;{Vertex}
                    {/*&nbsp;&nbsp;<b>Avg. Mag.:</b>&nbsp;{AvgMagnitude2}
                    &nbsp;&nbsp;<b>Avg. Axis:</b>&nbsp;{AvgAxis2}*/}
                    </span>
                </Col>)
        }else{
            inputDataColumn = 
                (<Col>
                    <b>PREOP:</b>&nbsp;&nbsp;&nbsp;
                    <b>Eye:</b> {result.preopInput[0]}
                    &nbsp;&nbsp;<b>K1:</b> {result.preopInput[1]} &nbsp;<b>K2:</b> {result.preopInput[2]}
                    &nbsp;&nbsp;<b>Steep Meridian:</b>&nbsp;{result.preopInput[3]}
                    &nbsp;&nbsp;<b>Sphere:</b> {result.preopInput[4]} &nbsp;<b>Cylinder:</b> {result.preopInput[5]}
                    &nbsp;&nbsp;<b>Axis:</b> {result.preopInput[6]} &nbsp;<b>Vertex:</b> {result.preopInput[7]}
                    <br></br>
                    <span>
                    <b>POSTOP:</b>&nbsp;&nbsp;&nbsp;
                    <b>IOL:</b> &nbsp;{result.postopInput[0]}
                    &nbsp;&nbsp;<b>Sphere:</b>&nbsp;{result.postopInput[1]}
                    &nbsp;&nbsp;<b>Cylinder:</b>&nbsp;{result.postopInput[2]}
                    &nbsp;&nbsp;<b>Axis:</b>&nbsp;{result.postopInput[3]}
                    &nbsp;&nbsp;<b>ACD:</b>&nbsp;{result.postopInput[4]}
                    &nbsp;&nbsp;<b>Vertex:</b>&nbsp;{result.postopInput[5]}
                    {/*&nbsp;&nbsp;<b>Avg. Mag.:</b>&nbsp;{AvgMagnitude2}
                    &nbsp;&nbsp;<b>Avg. Axis:</b>&nbsp;{AvgAxis2}*/}
                    </span>
                </Col>)
        }
        setInputData(inputDataColumn);
    }

    useEffect( () => {
        setOrientationValue(Result7);
    }, [Result7])

    //Trigger Calculation function when result window is opened.
    useEffect( () => {
        if(showResult){
            setLoading(true);
            setTimeout(() => setLoading(false),2000)
            setHideToDownload(false);
            renderInputData(fixedResult);
            
            if(PostopRefCylinder<0){
                PostopRefractionRecalculation();
            }

            if(!fixedResult){
                setIOLMarks(IOLMarks);
                setLens(Lens);
                calculateResults();
                var selectedLens = types.findIndex(x => x.name === Lens);
                setGraphicIOL(types[selectedLens].image);
                saveResult(false);
            }else{
                loadFixedResult(fixedResult);
                var selectedLens = types.findIndex(x => x.name === fixedResult.postopInput[0]);
                setGraphicIOL(types[selectedLens].image);
            }
            
            //setOrientationValue(Result7);
        }
    }, [showResult])

    useEffect( () => {
        if(!fixedResult){
            calculateResults();
        }else{
            if(fixedResult.length !== 1){
                recalculateIOLAlignment(fixedResult.prrrecalculationAux);
            }
        }
    }, [orientationValue])

    window.onkeypress = function(event) {
        //Reset orientation value on enter/space
        if (event.keyCode === 13 || event.keyCode === 32) {
            setOrientationValue(parseInt(Result7));
        }
    }

    window.onkeydown = function(event){
        //Change orientation value on arrows press
        if(document.getElementById("orientationRange") !== document.activeElement){
            if (event.keyCode === 39) {
                setOrientationValue(parseInt(orientationValue)+1);
            }
            if (event.keyCode === 37) {
                setOrientationValue(parseInt(orientationValue)-1);
            }
        }
    }
        

    if(showResult){
        return (
            <div>

                <SaveResult show={saveResultWindow} setShow={showSaveResultWindow}
                resultJSON={resultJSON}/>
                <Downloading showDownloading={showDownloading}/>

                <div id="divToPrint" className='result'>

                    <div className='overlay' onClick={() => setShowResult(false)}>
                        Result
                    </div>

                    <Container className='resultContent styledBox'>

                        <div className='resultLoadingDiv'
                        style={{display: loading? null : 'none'}}>
                            <LoadingDots/>
                        </div>
                        
                        <Row style={{opacity: loading? '0': null}}>
                            <Col className='resultTitle resultCol resumeShadow'>
                                <Row>
                                    <Col>
                                        <span><IoEyeOutline className='btnIcon'/> Result</span>
                                    </Col>
                                </Row>
                                <Row className='resultResume'>
                                    {inputData}
                                </Row>
                            </Col>
                        </Row>

                        <Row className='separator' style={{opacity: loading? '0': null}}/>

                        <Row style={{opacity: loading? '0': null}} id='result-first'>
                            <Col xs={12} sm className='resumeShadow resultCol text-start'>
                                <Row className="spansRow">
                                    <span className='resultSubtitle'>Meridional Analysis</span>
                                    <span>
                                        IOL/Spectacle Cyl. Ratio: <i>{Result1}</i>
                                        <br></br>  
                                        <span>Required Cyl. at IOL Plane: <i>{Result2}</i></span>
                                    </span>
                                </Row>
                            </Col>
                            <Col xs={12} sm className='resumeShadow resultCol text-start'>
                                <Row className="spansRow">
                                    <span className='resultSubtitle'>Implanted IOL Cylinder</span>
                                    <span>
                                        At IOL Plane: <i>{parseFloat(Result3).toFixed(2)}</i>
                                        <br></br>  
                                        At Spectacle Plane: <i>{parseFloat(Result4).toFixed(2)}</i>
                                    </span>
                                </Row>
                            </Col>
                            <Col xs={12} sm className='resumeShadow resultCol text-start'>
                                <Row className="spansRow">
                                    <span className='resultSubtitle'>SICA</span>
                                    <span>
                                        <span style={{display: (Result5 === "NaN")? 'none' : null}}>
                                            Cylinder: <i>{Result5}</i> &nbsp; Axis: <i>{Result6}°</i>
                                        </span>
                                        <i style={{display: (Result5 !== "NaN" && Result6 !== "NaN")? 'none' : null}}>
                                            No Measurements
                                        </i> 
                                    </span>
                                </Row>
                            </Col>
                        </Row>

                        <Row className='separator' style={{opacity: loading? '0': null}}/>

                        <Row style={{opacity: loading? '0': null}}>
                            <Col xs={12} sm className='resumeShadow resultCol vCenter'>
                                <Row className="spansRow">
                                    <span className='resultSubtitle'>IOL Orientation</span>
                                </Row>
                                <Row>
                                    <div className='eyeGraphic'>
                                        <img src={graphicCircle} id='graphicCircle' alt='graphicCircle'></img>
                                        <img src={require('./lensImages/graphic'+graphicIOL+".png")} id='graphicIOL' alt='graphicIOL'
                                        style={{transform: 'rotate('+ (90-orientationValue+iolMarks) +'deg)'}}></img>
                                        <img src={graphicSuggested} id='graphicSuggested' alt='graphicSuggested'
                                        style={{transform: 'rotate('+ (90-Result7) +'deg)'}}></img>
                                    </div>
                                </Row>
                                <Row className='resultOrientationRow'>
                                    <span id='orientationSpan'>{orientationValue}°</span>
                                    <span className='hint2 d-none d-sm-inline'><FaRegQuestionCircle/>
                                        <span className='hintText hintText2'>
                                            Use <b>keyboard arrows</b> to modify IOL axis precisely.
                                            <br></br>
                                            Reset pressing <b>Intro/Space Bar</b>.
                                        </span>
                                    </span>

                                    <div className='rangeDiv noPadding'
                                    style={{display: (hideToDownload)? 'none' : null}}>
                                        <Form.Range value={orientationValue}
                                        id="orientationRange"
                                        min="0" max="180" 
                                        onChange={(e) => {
                                            setOrientationValue(e.target.value);
                                            if (!fixedResult) calculateResults();
                                        }}>    
                                        </Form.Range>
                                        <BsArrowLeft id='rangeArrowL' onClick={() => setOrientationValue(parseInt(orientationValue)-1)}/>
                                        <BsArrowRight id='rangeArrowR' onClick={() => setOrientationValue(parseInt(orientationValue)+1)}/>
                                    </div>

                                </Row>
                                <Row className="spansRow"
                                style={{display: (hideToDownload)? 'none' : null}}>
                                    <span className='orientationSubtitle'>(Change IOL axis to see predicted refraction changes)</span>
                                </Row>
                            </Col>
                            <Col xs={12} sm className='xs-nopadding'>
                                <Row className='resumeShadow resultCol'>
                                    <Row className="spansRow text-start xs-nopadding">
                                        <span className='resultSubtitle text-center'>{lens}</span>
                                        <b style={{position: 'relative'}}>According to Preop Refraction + SICA
                                            <span className='hint d-none d-lg-inline'><FaRegQuestionCircle/>
                                                <span className='hintText'>Change IOL orientation to see expected refraction.</span>
                                            </span>
                                        </b>
                                        <span>
                                            <span>Suggested Axis:&nbsp;<i>{Result7}°</i></span>
                                            <br></br>
                                            <span className='resultPRR'>Predicted residual refraction:</span> <i>{Result8}</i><i>{Result9}</i><i>{Result10}°</i>
                                        </span>
                                    </Row>
                                </Row>
                                <Row>
                                    <div className='optionsDiv2'
                                    style={{display: (hideToDownload)? 'none' : null}}>
                                        <span onClick={() => downloadPdf()}><AiOutlineFilePdf/> Download</span>
                                        <span onClick={() => saveResult(true)}><FaUserMd/> Save Result</span>
                                        <span onClick={() => modifyInput(fixedResult)}><RiArrowGoBackLine/> Modify Input</span>
                                    </div>
                                    <div className='identifiersDiv'
                                    style={{display: (!hideToDownload)? 'none' : null}}>
                                        <span>Patient ID: {Patient}</span>
                                        <span>Surgeon ID: {Surgeon}</span>
                                    </div>
                                </Row>
                            </Col>
                        </Row>

                        <div className='resumeCloseBtn' 
                        onClick={() => setShowResult(false)}
                        style={{opacity: loading? '0': null}}>
                            <AiOutlineCloseCircle/>
                        </div>

                    </Container>
                </div>
            </div>
        )
    }
}

