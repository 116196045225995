import React, {useState, useEffect} from 'react';
import {Form} from 'react-bootstrap';

import { types } from '../../helpers/enums/IOLModels';

export default function Model({VS, setVS, Mfact}) {
    const [Class, setClass] = useState('formControl controlNeutral');
    const [options, setOptions] = useState([{name:'', manufacturer:['','']}]);

    useEffect(() => {
        if(VS[0] !== undefined && VS[0] !== 'Model'){
            setVS([VS[0],1]);
            setClass('formControl controlValid');
        }else{
            setVS([VS[0],-1]);
            setClass('formControl controlNeutral');
        }
    }
    ,[VS[0], Mfact, Class]);

    useEffect(() => {
        const recognizeOptions = () => {
            let tempVal = [];
            tempVal = types.filter((type) => type.manufacturer.includes(Mfact));
            setOptions(tempVal);
        };
        recognizeOptions();
    }, [Mfact])

    return (
        <Form onSubmit={e => { e.preventDefault(); }}>
            <Form.Group>
                <div className='inputDiv'>
                    <Form.Select id='st-iol-model'
                    readOnly={(Mfact === 'Manufacturer' || Mfact === 'Other')? true : false}
                    className={Class} value={VS[0]}
                    onChange={(e) => 
                        {
                            setVS([e.target.value, VS[1]]);
                        }}
                    >
                        {options.map((option) => (
                           <option key={option.name} value={option.name}>{option.name}</option>
                        ))}
                    </Form.Select>
                </div>
            </Form.Group>
        </Form>
    )
}